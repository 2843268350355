import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Spinner } from "reactstrap";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    increment,
    orderBy,
    query,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { db } from "../../util/firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { printLog, updateViews } from "../../util/helper";
import upwardIcon from "../../assets/images/viewTrakr/upward-icon.png";
import editIcon from "../../assets/images/viewTrakr/edit-playlist.png";
import heartIcon from "../../assets/images/viewTrakr/heart-icon.png";
import dotIcon from "../../assets/images/viewTrakr/dot-icon.png";
import YouTubeThumbnail from "../../components/YouTubeThumbnail";
import cloneIcon from "../../assets/images/viewTrakr/clone.svg";
import VimeoThumbnail from "../../components/VimeoThumbnail";

const PlaylistView = (props) => {
    const { pid } = useParams();
    const navigate = useNavigate();
    const [playlingIndex, setPlayingIndex] = useState(0);
    const [playlist, setPlaylist] = useState({});
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState("initial");
    const [embeddedLink, setEmbeddedLink] = useState("");
    const [videosLoaded, setVideosLoaded] = useState(false);
    const [playlistCloned, setPlaylistCloned] = useState(false);
    const [updatingViews, setUpdatingViews] = useState(false);
    const owner = playlist?.user_id === props?.signedInUser?.uid;

    useEffect(() => {
        getPlaylist().then((_playlist) => {
            console.log(_playlist, "playlist on view");
            setPlaylist(_playlist);
            if (_playlist?.clonedBy.includes(props.signedInUser.uid))
                setPlaylistCloned(true);
        });
        getVideos().then((_videos) => {
            console.log(_videos, "videos ");
            setVideos(_videos);
            setVideosLoaded(true);
            setLoading("ready");
        });
    }, []);
    useEffect(() => {
        if (videos.length > 0) {
            const videoLink = videos[playlingIndex]?.video_link;
            let link;
            if (
                videoLink.includes("youtube.com") ||
                videoLink.includes("youtu.be")
            ) {
                const videoId = extractVideoId(videoLink, "youtube");
                link = `https://www.youtube.com/embed/${videoId}`;
            } else if (videoLink.includes("vimeo.com")) {
                const videoId = extractVideoId(videoLink, "vimeo");
                link = `https://player.vimeo.com/video/${videoId}`;
            }
            setEmbeddedLink(link);
            setTimeout(() => {
                setLoading("ready");
            }, 500);
        }
        if (!owner && videos.length > 0) {
            setUpdatingViews(true);
            updateViews(videos[playlingIndex].id).then((res) => {
                console.log(res);
                const localVideos = [...videos];
                localVideos[playlingIndex].views = res;
                setVideos([...localVideos]);
                setLoading("ready");
                setUpdatingViews(false);
            });
            // setTimeout(() => {
            // const localVideos = [...videos];
            //     ++localVideos[playlingIndex].views;
            //     setVideos([...localVideos]);
            //     setLoading("ready");
            // }, 500)
        }
    }, [playlingIndex, videosLoaded]);

    const getPlaylist = async () => {
        try {
            const docRef = doc(db, "playlists", pid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { ...docSnap.data(), clone_playlist_id: docSnap.id };
            }
        } catch (error) {
            printLog(error, "Error in getting playlist");
        }
    };

    const getVideos = async () => {
        try {
            if (pid) {
                const collectionRef = collection(db, "videos");
                const _query = query(
                    collectionRef,
                    where("playlist_id", "==", pid),
                    orderBy("sequence")
                );
                let data = [];
                const querySnapshot = await getDocs(_query);
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                return data;
            }
        } catch (error) {
            printLog(error, "Error in getting playlist videos");
        }
    };
    const extractVideoId = (url, platform) => {
        let videoId;

        if (platform === "youtube") {
            if (url.includes("youtube.com")) {
                if (url.includes("youtube.com/shorts/")) {
                    videoId = url.split("youtube.com/shorts/")[1];
                } else {
                    const urlObj = new URL(url);
                    videoId = urlObj.searchParams.get("v");
                }
            } else if (url.includes("youtu.be")) {
                videoId = url.split("youtu.be/")[1];
            }
        } else if (platform === "vimeo" && url.includes("vimeo.com")) {
            videoId = url.split("vimeo.com/")[1];
        }

        // Removing any trailing slash or parameters
        if (videoId) {
            videoId = videoId.split("/")[0];
            videoId = videoId.split("?")[0];
        }

        return videoId;
    };
    const navigatePage = (route) => {
        if (route) navigate(`/viewTrakr/${route}`);
    };
    const clonePlaylist = async () => {
        console.log("cloning play list id:", pid);
        console.log("cloning play list document:", playlist);
        console.log("cloning play videos:", videos);
        console.log("current user", props.signedInUser);
        try {
            if (!playlistCloned) {
                setLoading("cloning");
                const playlistData = {
                    ...playlist,
                    user_id: props.signedInUser.uid,
                    user_name: props.signedInUser.name,
                    user_profile_photo: props.signedInUser.profile_photo_url,
                    sequence: props.signedInUser?.playlist_count + 1,
                };
                console.log(playlistData, "new playlist");
                addDoc(collection(db, "playlists"), playlistData).then(
                    async (playlistResponse) => {
                        const batch = writeBatch(db);
                        videos.forEach((_video) => {
                            let docRef;
                            docRef = doc(collection(db, "videos"));
                            batch.set(docRef, {
                                ..._video,
                                views: 0,
                                playlist_id: playlistResponse.id,
                            });
                        });
                        await batch.commit();
                        const playlistDocRef = doc(db, "playlists", pid);
                        const userList = [...playlist.clonedBy];
                        userList.push(props.signedInUser.uid);
                        await updateDoc(playlistDocRef, { clonedBy: userList });
                        const userDocRef = doc(
                            db,
                            "users",
                            props.signedInUser.uid
                        );
                        await updateDoc(userDocRef, {
                            playlist_count: increment(1),
                        });
                        props.setRefreshUser(!props.refreshUser);
                        setLoading("ready");
                        props.callAlert(
                            "success",
                            "Playlist added to My Pages"
                        );
                        setPlaylistCloned(true);
                    }
                );
            }
        } catch (error) {
            printLog(error, "Error in cloning playlist");
        }
    };
    const isUploader = (playlist) => {
        console.log("Checking user");
        console.log(props.signedInUser.uid);
        console.log(playlist.user_id);
        return props.signedInUser.uid === playlist.user_id;
    };

    return (
        <div
            className="preview-parent-container"
            style={{
                height: `${props.mainContentHeight}vh`,
                overflowY: "auto",
            }}
        >
            {loading === "initial" ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : videos?.length > 0 ? (
                <>
                    <Container>
                        <div className="d-flex justify-content-between p-2">
                            <div className="fs-12">
                                <div className="fw-bold text-center">VIDEO</div>
                                {/*<br/>*/}
                                <div className="text-center">
                                    <span className="fw-bold">
                                        {playlingIndex + 1}
                                    </span>{" "}
                                    OF
                                    <span className="muted-text-bold">
                                        {" "}
                                        {videos.length}
                                    </span>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="fs-10">
                                    <span className="muted-text-bold">
                                        {" "}
                                        CREATED BY{" "}
                                    </span>
                                    <span className="fw-bold">
                                        {playlist.user_name}
                                    </span>
                                </div>
                                <div className="text-center fw-bold">
                                    {playlist.title}
                                </div>
                            </div>
                            <div>
                                <Link
                                    to={`/${props.signedInUser.userNameInsensitive}`}
                                >
                                    <img
                                        src={playlist?.user_profile_photo}
                                        alt=""
                                        width={50}
                                        height={50}
                                        className="rounded-circle"
                                    />
                                </Link>
                            </div>
                        </div>
                    </Container>
                    {/*<div ref={playerRef} id="youtube-player"/>*/}
                    <div style={{ height: "35%" }}>
                        {loading === "newVideo" ? (
                            <div className="text-center">
                                <Spinner color={"success"} className="mt-5">
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <iframe
                                width="100%"
                                height="100%"
                                src={embeddedLink}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        )}
                    </div>
                    <div className="video-detail-card">
                        <div className="video-details">
                            <div>
                                <div className="muted-text-bold fs-10">
                                    VIDEO {playlingIndex + 1}
                                </div>
                                <div className="fw-semibold">
                                    {videos[playlingIndex]?.title}
                                </div>
                            </div>
                            {/*<div>*/}
                            {/*    <img src={upwardIcon} alt="" width={50} role="button"/>*/}
                            {/*</div>*/}
                        </div>
                        <div className="playlist-actions">
                            <Button
                                disabled={
                                    loading === "cloning" || playlistCloned
                                }
                                className={`${
                                    owner
                                        ? "edit-playlist-button"
                                        : "clone-playlist-button"
                                }`}
                                onClick={() =>
                                    owner
                                        ? navigatePage(`editPlaylist/${pid}`)
                                        : clonePlaylist()
                                }
                            >
                                <img
                                    src={owner ? editIcon : cloneIcon}
                                    alt=""
                                    width={20}
                                    className="me-3 mb-1"
                                />
                                <span>
                                    {loading === "cloning" ? (
                                        <Spinner size="sm">Loading...</Spinner>
                                    ) : owner ? (
                                        "EDIT THIS PLAYLIST"
                                    ) : !playlistCloned ? (
                                        "Clone This Playlist"
                                    ) : (
                                        "Playlist Cloned"
                                    )}
                                </span>
                            </Button>
                            <div className="play-count">
                                <span className="fs-12 fw-bolder">
                                    {videos[playlingIndex].views}
                                </span>{" "}
                                <br />{" "}
                                <span className="fs-10 muted-text">VIEWS</span>
                            </div>
                        </div>
                    </div>
                    <Container>
                        {videos.length > 0 &&
                            videos.map((_video, index) => (
                                <div
                                    className="video-item"
                                    key={index}
                                    onClick={() => {
                                        if (
                                            playlingIndex !== index &&
                                            updatingViews === false
                                        ) {
                                            setPlayingIndex(index);
                                            setLoading("newVideo");
                                        }
                                    }}
                                >
                                    {index === playlingIndex && (
                                        <img
                                            src={dotIcon}
                                            alt=""
                                            width={10}
                                            className="me-2"
                                        />
                                    )}
                                    <div>
                                        {_video.video_link.includes("youtu") ? (
                                            <YouTubeThumbnail
                                                url={_video.video_link}
                                                sequence={_video.sequence}
                                                edit={false}
                                            />
                                        ) : (
                                            <VimeoThumbnail
                                                url={_video.video_link}
                                                sequence={_video.sequence}
                                                edit={false}
                                            />
                                        )}
                                        {/*<YouTubeThumbnail url={_video.video_link} edit={false} sequence={null}/>*/}
                                    </div>
                                    <div className="video-item-details-container pe-3">
                                        <div className="video-item-description">
                                            <span className="fs-10 muted-text-bold text-uppercase">
                                                Video {_video.sequence}
                                            </span>
                                            <div className="fs-12 fw-semibold">
                                                {_video.title}
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={heartIcon}
                                                alt=""
                                                width={15}
                                            />
                                        </div>
                                    </div>
                                    {/*{_video.title}*/}
                                </div>
                            ))}
                        {updatingViews && (
                            <div className="overlay" style={{ top: "60%" }}>
                                <Spinner size="lg">Loading...</Spinner>
                            </div>
                        )}
                    </Container>
                </>
            ) : (
                <div className="text-center">No videos in playlist</div>
            )}
        </div>
    );
};

export default PlaylistView;
