export const logEnabled = true;

export const appVersion = "0.8"; // 22 February version to client

export const YOUTUBE_URL_VERIFY_REGEX =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
export const VIMEO_URL_VERIFY_REGEX =
    /^(https?:\/\/)?(www\.)?vimeo\.com\/\d{8,10}$/;
export const pointsConfig = {
    "sectors": {
        "0": {
            "levels": { "0": { activity_points: 15, reset_points: 100 } },
            "views": 25,
        },
        "1": {
            "levels": { "1": { activity_points: 10, reset_points: 50 } },
            "views": 50,
        },
        // "2": {"levels": {"2": {activity_points: 9, reset_points: 30}}, "views": 100},
        "2": {
            "levels": {
                "2": { activity_points: 9, reset_points: 30 },
                "3": { activity_points: 9, reset_points: 30 },
                "4": { activity_points: 9, reset_points: 30 },
            },
            "views": 75,
        },
        // "3": {"levels": {"3": {activity_points: 8, reset_points: 20}}, "views": 100},
        "3": {
            "levels": {
                "5": { activity_points: 8, reset_points: 20 },
                "6": { activity_points: 8, reset_points: 20 },
                "7": { activity_points: 8, reset_points: 20 },
            },
            "views": 100,
        },
        // "4": {
        //     "levels": {
        //         "4": {activity_points: 7, reset_points: 15},
        //         "5": {activity_points: 7, reset_points: 15},
        //         "6": {activity_points: 7, reset_points: 15},
        //         "7": {activity_points: 7, reset_points: 15},
        //         "8": {activity_points: 7, reset_points: 15},
        //         "9": {activity_points: 7, reset_points: 15},
        //         "10": {activity_points: 7, reset_points: 15}
        //     },
        //     "views": 200
        // },
        "4": {
            "levels": {
                "8": { activity_points: 7, reset_points: 15 },
                "9": { activity_points: 7, reset_points: 15 },
                "10": { activity_points: 7, reset_points: 15 },
            },
            "views": 200,
        },
        "5": {
            "levels": {
                "11": { activity_points: 6, reset_points: 10 },
                "12": { activity_points: 6, reset_points: 10 },
                "13": { activity_points: 6, reset_points: 10 },
                "14": { activity_points: 6, reset_points: 10 },
                "15": { activity_points: 6, reset_points: 10 },
                "16": { activity_points: 6, reset_points: 10 },
                "17": { activity_points: 6, reset_points: 10 },
                "18": { activity_points: 6, reset_points: 10 },
                "19": { activity_points: 6, reset_points: 10 },
                "20": { activity_points: 6, reset_points: 10 },
            },
            "views": 400,
        },
        "6": {
            "levels": {
                "21": { activity_points: 5, reset_points: 5 },
                "22": { activity_points: 5, reset_points: 5 },
                "23": { activity_points: 5, reset_points: 5 },
                "24": { activity_points: 5, reset_points: 5 },
                "25": { activity_points: 5, reset_points: 5 },
                "26": { activity_points: 5, reset_points: 5 },
                "27": { activity_points: 5, reset_points: 5 },
                "28": { activity_points: 5, reset_points: 5 },
                "29": { activity_points: 5, reset_points: 5 },
                "30": { activity_points: 5, reset_points: 5 },
            },
            "views": 600,
        },
        "7": {
            "levels": {
                "31": { activity_points: 4, reset_points: 4 },
                "32": { activity_points: 4, reset_points: 4 },
                "33": { activity_points: 4, reset_points: 4 },
                "34": { activity_points: 4, reset_points: 4 },
                "35": { activity_points: 4, reset_points: 4 },
                "36": { activity_points: 4, reset_points: 4 },
                "37": { activity_points: 4, reset_points: 4 },
                "38": { activity_points: 4, reset_points: 4 },
                "39": { activity_points: 4, reset_points: 4 },
                "40": { activity_points: 4, reset_points: 4 },
                "41": { activity_points: 4, reset_points: 4 },
                "42": { activity_points: 4, reset_points: 4 },
                "43": { activity_points: 4, reset_points: 4 },
                "44": { activity_points: 4, reset_points: 4 },
                "45": { activity_points: 4, reset_points: 4 },
                "46": { activity_points: 4, reset_points: 4 },
                "47": { activity_points: 4, reset_points: 4 },
                "48": { activity_points: 4, reset_points: 4 },
                "49": { activity_points: 4, reset_points: 4 },
                "50": { activity_points: 4, reset_points: 4 },
            },
            "views": 800,
        },
        "8": {
            "levels": {
                "51": { activity_points: 3, reset_points: 3 },
                "52": { activity_points: 3, reset_points: 3 },
                "53": { activity_points: 3, reset_points: 3 },
                "54": { activity_points: 3, reset_points: 3 },
                "55": { activity_points: 3, reset_points: 3 },
                "56": { activity_points: 3, reset_points: 3 },
                "57": { activity_points: 3, reset_points: 3 },
                "58": { activity_points: 3, reset_points: 3 },
                "59": { activity_points: 3, reset_points: 3 },
                "60": { activity_points: 3, reset_points: 3 },
                "61": { activity_points: 3, reset_points: 3 },
                "62": { activity_points: 3, reset_points: 3 },
                "63": { activity_points: 3, reset_points: 3 },
                "64": { activity_points: 3, reset_points: 3 },
                "65": { activity_points: 3, reset_points: 3 },
                "66": { activity_points: 3, reset_points: 3 },
                "67": { activity_points: 3, reset_points: 3 },
                "68": { activity_points: 3, reset_points: 3 },
                "69": { activity_points: 3, reset_points: 3 },
                "70": { activity_points: 3, reset_points: 3 },
                "71": { activity_points: 3, reset_points: 3 },
                "72": { activity_points: 3, reset_points: 3 },
                "73": { activity_points: 3, reset_points: 3 },
                "74": { activity_points: 3, reset_points: 3 },
                "75": { activity_points: 3, reset_points: 3 },
            },
            "views": 1000,
        },
        "9": {
            "levels": {
                "76": { activity_points: 2, reset_points: 2 },
                "77": { activity_points: 2, reset_points: 2 },
                "78": { activity_points: 2, reset_points: 2 },
                "79": { activity_points: 2, reset_points: 2 },
                "80": { activity_points: 2, reset_points: 2 },
                "81": { activity_points: 2, reset_points: 2 },
                "82": { activity_points: 2, reset_points: 2 },
                "83": { activity_points: 2, reset_points: 2 },
                "84": { activity_points: 2, reset_points: 2 },
                "85": { activity_points: 2, reset_points: 2 },
                "86": { activity_points: 2, reset_points: 2 },
                "87": { activity_points: 2, reset_points: 2 },
                "88": { activity_points: 2, reset_points: 2 },
                "89": { activity_points: 2, reset_points: 2 },
                "90": { activity_points: 2, reset_points: 2 },
                "91": { activity_points: 2, reset_points: 2 },
                "92": { activity_points: 2, reset_points: 2 },
                "93": { activity_points: 2, reset_points: 2 },
                "94": { activity_points: 2, reset_points: 2 },
                "95": { activity_points: 2, reset_points: 2 },
                "96": { activity_points: 2, reset_points: 2 },
                "97": { activity_points: 2, reset_points: 2 },
                "98": { activity_points: 2, reset_points: 2 },
                "99": { activity_points: 2, reset_points: 2 },
                "100": { activity_points: 2, reset_points: 2 },
            },
            "views": 2000,
        },
        // ... Add additional sectors and levels as per your table
    },
};
export const defaultViewsJump = 10;

export const SECTORS = [
    {
        name: "Sector 2",
        waveLevels: "2-4",
        sector: 2,
    },

    {
        name: "Sector 3",
        waveLevels: "5-7",
        sector: 3,
    },

    {
        name: "Sector 4",
        waveLevels: "8-10",
        sector: 4,
    },

    {
        name: "Sector 5",
        waveLevels: "11-20",
        sector: 5,
    },

    {
        name: "Sector 6",
        waveLevels: "21-30",
        sector: 6,
    },

    {
        name: "Sector 7",
        waveLevels: "31-50",
        sector: 7,
    },

    {
        name: "Sector 8",
        waveLevels: "51-75",
        sector: 8,
    },

    {
        name: "Sector 9",
        waveLevels: "76-100",
        sector: 9,
    },
    {
        name: "Sector 10",
        waveLevels: "101+",
        sector: 10,
    },
];

export const ROUTES_CONFIG = [
    "viewplaylist",
    "search",
    "mywave",
    "leaderboard",
    "add-new-post",
    "public",
    "auth",
    "viewtrakr",
    "group",
    "user",
    "username",
];

export const COUNTRIES = {
    "HI": "Ko Hawaii Pae Aina",
    "US": "Republic of America",
};
