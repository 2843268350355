import React, { useEffect, useRef, useState } from "react";
import library_icon from "../../assets/images/viewTrakr/library-icon.png";
import MyPages from "../../components/MyPages";
import Library from "../../components/Library";
import SubHeader from "../../components/SubHeader";
import addPlaylist from "../../assets/images/viewTrakr/add-btn.png";
import posts_icon_active from "../../assets/images/viewTrakr/wall-icon-active.svg";
import posts_icon_inactive from "../../assets/images/viewTrakr/wall-icon-inactive.svg";
import playlist_icon_active from "../../assets/images/viewTrakr/playlist-icon-active.svg";
import playlist_icon_inactive from "../../assets/images/viewTrakr/playlist-icon-inactive.svg";
import us_flag from "../../assets/images/viewTrakr/usa-flag.png";
import hawai_flag from "../../assets/images/viewTrakr/hawai-flag.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Container,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane,
} from "reactstrap";
import AllPosts from "../../components/allPosts";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../util/firebase";
import { printLog } from "../../util/helper";
import CropImage from "../../components/CropImage";

const Listing = (props) => {
    let activeUser = props.signedInUser;
    const user_id = props.pid;
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("myPages");
    const [innerWrapperHeight, setInnerWrapperHeight] = useState("auto");
    const [sequence, setSequence] = useState(0);
    const [activeTab1, setActiveTab1] = useState("2");
    const [postCount, setPostCount] = useState(0);
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState("initial");
    const [firstRender, setFirstRender] = useState(1);

    const [coverLoaded, setCoverLoaded] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const subHeaderRef = useRef(null);
    const headingRef = useRef(null);
    const tabsRef = useRef(null);

    useEffect(() => {
        updateHeight();
        if (activeUser.uid === user_id) {
            setCurrentUser(activeUser);
            setLoading("ready");
        } else {
            getUser()
                .then((_user) => {
                    setCurrentUser(_user);
                    setLoading("ready");
                })
                .catch((err) => {
                    printLog(err);
                    navigatePage(`/viewTrakr`);
                });
        }
    }, []);

    useEffect(() => {
        if (location && location.pathname.toLowerCase().includes("viewtrakr")) {
            toggle("2");
            props.setZendujaHome(false);
        } else {
            toggle("1");
            setActiveTab("myPages");
            props.setZendujaHome(true);
        }
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            updateHeight(); // Call this initially when the component mounts
        }, 1000);

        if (firstRender === 1) {
            setFirstRender(firstRender + 1);
        }

        window.addEventListener("resize", updateHeight); // Add an event listener

        return () => {
            // Cleanup: remove the event listener when the component unmounts
            window.removeEventListener("resize", updateHeight);
        };
    }, [props.mainContentHeight, firstRender]); // Only run once after component is mounted

    const getUser = async () => {
        const docRef = doc(db, "users", user_id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), uid: user_id };
        }
        throw new Error();
    };
    const followUser = async () => {
        let active_followingsArray = activeUser?.followings || [];
        let current_followersArray = currentUser?.followers || [];
        try {
            if (activeUser.uid !== user_id) {
                setLoading("following");
                const activeRef = doc(db, "users", activeUser.uid);
                const currentRef = doc(db, "users", user_id);
                if (
                    !active_followingsArray.includes(user_id) &&
                    !current_followersArray.includes(activeUser.uid)
                ) {
                    active_followingsArray.push(user_id);
                    current_followersArray.push(activeUser.uid);
                } else {
                    active_followingsArray = active_followingsArray.filter(
                        (id) => id !== user_id
                    );
                    current_followersArray = current_followersArray.filter(
                        (id) => id !== activeUser.uid
                    );
                }
                console.log(
                    "|active_followingsArray",
                    active_followingsArray,
                    current_followersArray
                );

                await Promise.all([
                    updateDoc(currentRef, {
                        followers: current_followersArray,
                    }),
                    updateDoc(activeRef, {
                        followings: active_followingsArray,
                    }),
                ]);

                setCurrentUser((user) => ({
                    ...user,
                    followers: current_followersArray,
                }));

                await props.setRefreshUser(!props.refreshUser);
                setLoading("ready");
            }
        } catch (error) {
            console.log("FollowUser", error);
            printLog(error, "Error while follow");
        }
    };
    const updateHeight = () => {
        // if (subHeaderRef.current && tabsRef.current && headingRef) {
        if (headingRef.current) {
            // const subheaderHeightVH = (subHeaderRef.current.offsetHeight / window.innerHeight) * 100;
            const headingHeightVH =
                (headingRef.current.offsetHeight / window.innerHeight) * 100;
            // const tabsHeightVH = (tabsRef.current.offsetHeight / window.innerHeight) * 100;
            // const totalVH = (subheaderHeightVH + headingHeightVH + tabsHeightVH);
            // const finalVh = props.mainContentHeight - totalVH;
            setInnerWrapperHeight(
                `calc(${props.mainContentHeight}vh - ${
                    headingHeightVH + 2.2
                }vh)`
            );
        }
    };
    const toggle = (tab1) => {
        if (activeTab1 !== tab1) setActiveTab1(tab1);
    };
    const navigatePage = (route) => {
        navigate(`${route}`);
    };

    console.log("activeUser", currentUser);
    const isCurrentUser = activeUser.uid === user_id;
    const isFollowing = activeUser?.followings?.includes(user_id);

    const EditProfileButton = () => (
        <Button
            className="w-100 edit-profile-button"
            onClick={() =>
                navigatePage(`/user/profile?userId=${currentUser.uid}`)
            }
        >
            EDIT PROFILE
        </Button>
    );
    const Tabs = () => (
        <div className="tabs">
            <TabButton
                isActive={activeTab === "myPages"}
                label="My Pages"
                onClick={() => setActiveTab("myPages")}
            />
            <TabButton
                isActive={activeTab === "library"}
                label="Library"
                icon={library_icon}
                onClick={() => setActiveTab("library")}
            />
        </div>
    );
    const FollowButton = () => (
        <Button
            onClick={followUser}
            disabled={loading === "following"}
            className={`w-100  ${
                isFollowing ? "following-button" : "follow-button"
            }`}
        >
            {loading === "following" ? (
                <Spinner size="sm" type="grow" color="light">
                    Loading...
                </Spinner>
            ) : isFollowing ? (
                "FOLLOWING"
            ) : (
                "FOLLOW"
            )}
        </Button>
    );
    const TabButton = ({ isActive, label, icon, onClick }) => (
        <button
            className={`${isActive ? "active " : ""}${
                label === "My Pages" ? "left-tab" : "right-tab"
            }`}
            onClick={onClick}
        >
            {icon && (
                <div className="d-flex justify-content-center">
                    <img className="me-2 mb-1" src={icon} alt="" width={17} />
                    <span>{label}</span>
                </div>
            )}
            {!icon && <span>{label}</span>}
        </button>
    );

    const handleCallBackImage = (crpImg, type) => {
        console.log("crpImage", crpImg);
        switch (type) {
            case "postImage":
                props.setPostFile(crpImg);
                navigate("/add-new-post");
                break;
        }
    };

    const setHeadingRef = (node) => {
        headingRef.current = node;
    };
    return loading === "initial" ? (
        <div className="text-center mt-5">
            <Spinner color={"success"}>Loading...</Spinner>
        </div>
    ) : (
        <div className="">
            {/*<div ref={subHeaderRef}>*/}
            {/*    /!*<SubHeader/>*!/*/}
            {/*</div>*/}
            <div className="text-center text-secondary" ref={setHeadingRef}>
                <Container>
                    <div
                        className="profile-cover"
                        style={{
                            backgroundImage: `url('${currentUser.cover_photo_url}')`,
                            filter: coverLoaded ? "none" : "blur(15px)",
                            transition: "filter 0.3s",
                        }}
                    >
                        <img
                            src={currentUser.cover_photo_url}
                            onLoad={() => setCoverLoaded(true)}
                            alt="Cover Preloading"
                            style={{
                                position: "absolute",
                                top: "-9999px",
                                height: "1px",
                                width: "1px",
                                opacity: "0",
                            }}
                        />
                        <div className="profile-tag-container d-flex align-items-center">
                            <div className="profile-tag rounded-circle">
                                <img
                                    src={currentUser.profile_photo_url}
                                    onLoad={() => setProfileLoaded(true)}
                                    style={{
                                        filter: profileLoaded
                                            ? "none"
                                            : "blur(5px)", // 5px is the blur amount, adjust as needed
                                        transition: "filter 0.3s", // Smooth transition when removing blur
                                    }}
                                    width="100%"
                                    height="100%"
                                    className="rounded-circle"
                                />
                                <div className="flag">
                                    <img
                                        src={
                                            currentUser.location === "US"
                                                ? us_flag
                                                : currentUser.location ===
                                                      "HI" && hawai_flag
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-start ms-3 text-white">
                                <div className="fw-bold w-100">
                                    {currentUser.name}
                                </div>
                                <div className="fs-14">
                                    @{currentUser.userName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end px-3">
                        <SubHeader user={currentUser} />
                    </div>
                    <div className="px-4 my-3">
                        {isCurrentUser ? (
                            activeTab1 === "1" ? (
                                <EditProfileButton />
                            ) : (
                                <Tabs />
                            )
                        ) : (
                            <FollowButton />
                        )}
                    </div>
                </Container>
                <div className="">
                    <Nav
                        tabs
                        className="d-flex justify-content-center border-0"
                    >
                        <NavItem className="w-50">
                            <NavLink
                                className={` profile-tabs ${
                                    activeTab1 === "1"
                                        ? "active-profile-tab"
                                        : ""
                                }`}
                                onClick={() => {
                                    navigate(
                                        `/${currentUser.userNameInsensitive}`
                                    );
                                }}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        <img
                                            src={
                                                activeTab1 === "1"
                                                    ? posts_icon_active
                                                    : posts_icon_inactive
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <span className="ms-2">{postCount}</span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="w-50">
                            <NavLink
                                className={`profile-tabs ${
                                    activeTab1 === "2"
                                        ? "active-profile-tab"
                                        : ""
                                }`}
                                onClick={() => {
                                    navigate(
                                        `/${currentUser.userNameInsensitive}/viewTrakr`
                                    );
                                }}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        <img
                                            src={
                                                activeTab1 === "2"
                                                    ? playlist_icon_active
                                                    : playlist_icon_inactive
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <span className="ms-2">
                                        {(sequence && sequence - 1) || 0}
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            {/*<div className="tabs" ref={tabsRef}>*/}
            {/*</div>*/}
            <div
                className="inner-wrapper mt-4"
                style={{ height: innerWrapperHeight, overflowY: "auto" }}
            >
                <TabContent activeTab={activeTab1} className="h-100">
                    <TabPane tabId="1">
                        <AllPosts
                            userId={currentUser.uid}
                            setPostCount={setPostCount}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        {activeTab === "myPages" ? (
                            <MyPages
                                setSequence={setSequence}
                                currentUser={currentUser}
                                activeUser={activeUser}
                                refreshUser={props.refreshUser}
                                setRefreshUser={props.setRefreshUser}
                            />
                        ) : (
                            <Library
                                signedInUser={currentUser}
                                refreshUser={props.refreshUser}
                                setRefreshUser={props.setRefreshUser}
                            />
                        )}
                    </TabPane>
                </TabContent>
            </div>
            {activeUser.uid === currentUser.uid && activeTab === "myPages" && (
                <>
                    {
                        activeTab1 === "2" ? (
                            <Link
                                to={`/viewTrakr/create/playlist?sequence=${
                                    Number(activeUser?.playlist_count || 0) + 1
                                }`}
                            >
                                <div
                                    className="add-playlist-button"
                                    role="button"
                                >
                                    <img src={addPlaylist} alt="" width={60} />
                                </div>
                            </Link>
                        ) : (
                            // <Link to="/user/add-new-post">
                            <label
                                className="add-playlist-button"
                                role="button"
                            >
                                <img src={addPlaylist} alt="" width={60} />
                                <input
                                    type="file"
                                    className="d-none"
                                    accept="image/*,video/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        console.log("file", file);
                                        if (!file) {
                                            // No file selected
                                            props.callAlert(
                                                "fail",
                                                "Please select a valid file."
                                            );
                                            return;
                                        }
                                        if (
                                            file &&
                                            file.size > 20 * 1024 * 1024
                                        ) {
                                            // File size is in bytes, so 10 * 1024 * 1024 is 10MB
                                            props.callAlert(
                                                "fail",
                                                "Please select file less than 20mb"
                                            );
                                            e.target.value = ""; // Clear the input
                                            return;
                                        }
                                        console.log("file", file);
                                        if (
                                            file.type.includes("video") ||
                                            file.type.includes("gif")
                                        ) {
                                            props.setPostFile(
                                                e.target.files[0]
                                            );
                                            navigate("/add-new-post");
                                        } else if (
                                            file.type.includes("image")
                                        ) {
                                            setCropImage({
                                                img: URL.createObjectURL(file),
                                                type: "postImage",
                                                aspectRatio: 1,
                                            });
                                        } else {
                                            props.callAlert(
                                                "fail",
                                                "Please select valid file"
                                            );
                                        }
                                        e.target.value = "";
                                    }}
                                />
                            </label>
                        )
                        // </Link>
                    }
                </>
            )}
            {cropImage && (
                <CropImage
                    cropImage={cropImage}
                    handleClose={() => setCropImage(null)}
                    aspectRatio={cropImage.aspectRatio}
                    handleCallBackImage={handleCallBackImage}
                />
            )}
        </div>
    );
};

export default Listing;
