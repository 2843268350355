import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Spinner } from "reactstrap";
import cloneIcon from "../assets/images/viewTrakr/clone-playlist.svg";
import clonedPlaylist from "../assets/images/viewTrakr/cloned-playlist.svg";
import playIcon from "../assets/images/viewTrakr/video-icon.png";
import editIcon from "../assets/images/viewTrakr/edit-playlist.png";
import shareIcon from "../assets/images/viewTrakr/link-icon.png";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert";
import { printLog } from "../util/helper";
import {
    updateDoc,
    doc,
    collection,
    writeBatch,
    increment,
    addDoc,
    query,
    where,
    orderBy,
    getDocs,
} from "firebase/firestore";
import { db } from "../util/firebase";

const PlaylistCard = ({
    playlist,
    activeUser,
    library,
    setRefreshUser,
    refreshUser,
}) => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [coverLoaded, setCoverLoaded] = useState(false);
    const [loading, setLoading] = useState("initial");
    const [playlistCloned, setPlaylistCloned] = useState(false);
    const [videos, setVideos] = useState([]);
    const [alertSettings, setAlertSettings] = useState({
        message: "",
        case: "",
        action: false,
    });

    useEffect(() => {
        if (library && playlist) {
            getVideos(playlist.id).then((_videos) => {
                setVideos(_videos);
            });
            setPlaylistCloned(
                playlist?.clonedBy?.includes(activeUser.uid) || false
            );
        }
    }, [library, playlist]);

    console.log("getVideos", videos);

    const getVideos = async (pid) => {
        try {
            if (pid) {
                const collectionRef = collection(db, "videos");
                const _query = query(
                    collectionRef,
                    where("playlist_id", "==", pid),
                    orderBy("sequence")
                );
                let data = [];
                const querySnapshot = await getDocs(_query);
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                return data;
            }
        } catch (error) {
            printLog(error, "Error in getting playlist videos");
        }
    };

    const navigateToPage = (route) => {
        navigate(route);
    };
    const copyLink = (_id) => {
        try {
            const playlistLink = `${window.origin}/viewTrakr/${_id}`;
            navigator.clipboard.writeText(playlistLink);
            setAlertSettings({
                case: "success",
                message: "Link Copied!",
                action: true,
            });
            setShowAlert(true);
            hideAlert();
        } catch (e) {
            printLog(e, "Error in copying the link");
            setAlertSettings({
                case: "fail",
                message: "Something went wrong",
                action: true,
            });
            setShowAlert(true);
            hideAlert();
        }
    };
    const hideAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const clonePlaylist = async (pid) => {
        try {
            if (!playlistCloned) {
                setLoading("cloning");
                const playlistData = {
                    ...playlist,
                    user_id: activeUser.uid,
                    user_name: activeUser.name,
                    user_profile_photo: activeUser.profile_photo_url,
                    sequence: activeUser?.playlist_count + 1,
                    clone_playlist_id: playlist.id,
                };
                delete playlistData.id;

                addDoc(collection(db, "playlists"), playlistData).then(
                    async (playlistResponse) => {
                        const batch = writeBatch(db);
                        videos.forEach((_video) => {
                            delete _video.id;
                            let docRef;
                            docRef = doc(collection(db, "videos"));
                            batch.set(docRef, {
                                ..._video,
                                views: 0,
                                playlist_id: playlistResponse.id,
                                user_id: activeUser.uid,
                            });
                        });
                        await batch.commit();
                        const playlistDocRef = doc(db, "playlists", pid);
                        const userList = [...playlist.clonedBy];
                        userList.push(activeUser.uid);
                        await updateDoc(playlistDocRef, { clonedBy: userList });
                        const userDocRef = doc(db, "users", activeUser.uid);
                        await updateDoc(userDocRef, {
                            playlist_count: increment(1),
                        });
                        setRefreshUser(!refreshUser);
                        setLoading("ready");
                        // props.callAlert('success', 'Playlist added to My Pages');
                        setPlaylistCloned(true);
                    }
                );
            }
        } catch (error) {
            printLog(error, "Error in cloning playlist");
        }
    };

    return (
        <div
            className="playlist-card"
            // style={{backgroundImage: `url('${playlist.cover_photo_url}')`}}
            style={{
                backgroundImage: `url('${playlist.cover_photo_url}')`,
                filter: coverLoaded ? "none" : "blur(15px)",
                transition: "filter 0.3s",
            }}
        >
            <img
                src={playlist.cover_photo_url}
                onLoad={() => setCoverLoaded(true)}
                alt="Cover Preloading"
                style={{
                    position: "absolute",
                    top: "-9999px",
                    height: "1px",
                    width: "1px",
                    opacity: "0",
                }}
            />
            {showAlert && <Alert settings={alertSettings} />}
            <div className="d-flex justify-content-between align-items-end h-100">
                <div className="text-white mb-2 ps-3" style={{ width: "81%" }}>
                    <div className="fw-light text-uppercase">
                        Playlist {playlist.sequence}
                    </div>
                    <h6 className="ms-0 text-uppercase">{playlist.title}</h6>
                </div>
                <ButtonGroup vertical={true} className="h-100">
                    <Button
                        onClick={() => {
                            navigateToPage(`/viewTrakr/${playlist.id}`);
                        }}
                        className="playlist-card-button-1 playlist-card-button"
                    >
                        <img src={playIcon} alt="" width={20} />
                    </Button>
                    {playlist.user_id === activeUser.uid && (
                        <Button
                            className="playlist-card-button"
                            onClick={() =>
                                navigateToPage(
                                    `/viewTrakr/editPlaylist/${playlist.id}`
                                )
                            }
                        >
                            <img src={editIcon} alt="" width={20} />
                        </Button>
                    )}
                    {playlist.user_id === "library" ? (
                        <Button
                            className=" playlist-card-button-3 playlist-card-button clone-icon-div"
                            onClick={() => clonePlaylist(playlist.id)}
                        >
                            {loading === "cloning" ? (
                                <Spinner size="sm" type="grow" color="light">
                                    Loading...
                                </Spinner>
                            ) : (
                                <img
                                    src={
                                        playlistCloned
                                            ? clonedPlaylist
                                            : cloneIcon
                                    }
                                    alt=""
                                />
                            )}
                        </Button>
                    ) : (
                        <Button
                            className="playlist-card-button-3 playlist-card-button"
                            onClick={() => copyLink(playlist.id)}
                        >
                            <img src={shareIcon} alt="" width={20} />
                        </Button>
                    )}
                </ButtonGroup>
            </div>
        </div>
    );
};

export default PlaylistCard;
