import PlaylistView from "../views/public/playlistView";

const routes = [
    {
        path: "/viewPlaylist/:pid",
        name: "viewPlaylist",
        component: PlaylistView,
        layout: "/public",
    },
];

export default routes;
