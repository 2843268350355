import React, { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import "../../assets/styles/leaderboard.css";
import { useNavigate } from "react-router-dom";
import PostCard from "../../components/postCard";
import { distributeEP, getUniqueArray, printLog } from "../../util/helper";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    where,
} from "firebase/firestore";
import { db } from "../../util/firebase";
import PointsCard from "../../components/PointsCard";
import coca_cola_banner from "../../assets/images/news-feed/coca-cola-banner.png";
import US5 from "../../assets/images/news-feed/us-5.png";

export default function NewsFeed(props) {
    const user = props.signedInUser;
    const navigate = useNavigate();
    // const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [redirectLink, setRedirectLink] = useState("");
    // const [pointsFeeds, setPointsFeeds] = useState([]);
    const [allFeeds, setAllFeeds] = useState([]);
    const [refreshFeeds, setRefreshFeeds] = useState(false);

    useEffect(() => {
        setLoading(true);
        let tempFeeds = [];
        getConfig().then((config) => {
            setRedirectLink(config.sponsored_ads_redirect_link);
        });
        getUser().then((_user) => {
            if (_user?.newsFeed && _user?.newsFeed.length > 0)
                tempFeeds.push(
                    ..._user?.newsFeed?.map((item) => ({
                        ...item,
                        feedtype: "points",
                    }))
                );
            // setPointsFeeds(_user.newsFeed.map((item) => ({...item, feedtype: 'points'})));
        });
        getPosts().then((result) => {
            tempFeeds.push(...result);
            tempFeeds.sort((a, b) => b.createdAt - a.createdAt);
            setAllFeeds([...tempFeeds]);
            setLoading(false);
        });
    }, [refreshFeeds]);

    const getPosts = async () => {
        let fetchedPosts = [];
        // Query for logged-in user's posts
        const postsQuery = query(
            collection(db, "posts"),
            where("user_id", "in", [
                user.uid,
                ...(Object.values(user?.followings || {}) || []),
            ]),

            orderBy("createdAt", "desc")
        );
        const postsSnapshot = await getDocs(postsQuery);
        postsSnapshot.forEach((doc) =>
            fetchedPosts.push({ id: doc.id, ...doc.data(), feedtype: "post" })
        );

        //  Query for each followed user's posts
        // if (user?.followings && user.followings.length > 0) {
        //     const followingPostsQuery = query(
        //         collection(db, "posts"),
        //         where("user_id", "in", Object.values(user?.followings)),
        //         orderBy("createdAt", "desc")
        //     );
        //     const followingPostsSnapshot = await getDocs(followingPostsQuery);
        //     followingPostsSnapshot.forEach((doc) =>
        //         fetchedPosts.push({
        //             id: doc.id,
        //             ...doc.data(),
        //             feedtype: "post",
        //         })
        //     );
        // }

        // Query for each joined group's posts
        if (user?.joinedGroups && Object.keys(user.joinedGroups).length > 0) {
            const groupPostsQuery = query(
                collection(db, "posts"),
                where("groupId", "in", Object.keys(user.joinedGroups)),
                orderBy("createdAt", "desc")
            );
            const groupPostsSnapshot = await getDocs(groupPostsQuery);
            groupPostsSnapshot.forEach((doc) =>
                fetchedPosts.push({
                    id: doc.id,
                    ...doc.data(),
                    feedtype: "post",
                })
            );
        }
        fetchedPosts.sort((a, b) => b.createdAt - a.createdAt);
        return getUniqueArray(fetchedPosts, "id");
    };
    const getConfig = async () => {
        const docRef = doc(db, "config", "meta");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
    };
    const getUser = async () => {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
    };

    const adClicked = async () => {
        window.open(redirectLink, "_blank");
        await distributeEP(user);
        props.setRefreshUser(!props.refreshUser);
    };

    return (
        <div style={{ height: "81vh", overflowY: "scroll" }}>
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : (
                <Container className="px-4">
                    {allFeeds &&
                        allFeeds.length > 0 &&
                        allFeeds.map((feedItem, index) => (
                            <div key={index}>
                                {index === 2 && (
                                    <div className="position-relative text-center">
                                        <img
                                            src={coca_cola_banner}
                                            alt=""
                                            width="100%"
                                            onClick={adClicked}
                                        />
                                        <div className="text-white position-absolute cocacola-points">
                                            <img src={US5} alt="" width={35} />
                                            <div className="fw-bold">
                                                +100pts
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {feedItem.feedtype === "post" ? (
                                    <>
                                        <PostCard
                                            post={feedItem}
                                            signedInUser={user}
                                            refreshFeeds={refreshFeeds}
                                            setRefreshFeeds={setRefreshFeeds}
                                        />
                                    </>
                                ) : (
                                    feedItem.feedtype === "points" && (
                                        <PointsCard
                                            feedItem={feedItem}
                                            redirectLink={redirectLink}
                                        />
                                    )
                                )}
                            </div>
                        ))}
                    {/*Iframe card*/}
                    <iframe
                        width="100%"
                        height="315"
                        className="rounded-4"
                        src="https://www.youtube.com/embed/NAQth7oOnOI?si=tXWwSh2TL_1aBNgB"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </Container>
            )}
        </div>
    );
}
