import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/LoginHeader/logo.png";
import email from "../../assets/images/auth/email-icon.png";

export default function RegisterSuccessful() {


  return (
    <div>
      <div className="text-center pt-5 pb-5">
        <img src={Logo} height={50} alt="" />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center"  style={{ height: '60vh' }}>
        <div>
          <img src={email} height={100} alt="" />
        </div>

        <div>
          <h2 className="text-center reg-success mt-3">
            Registration Successful!
          </h2>
        </div>
        <div>
          <p className="text-center text-mute my-4">
            Verification Email Sent to:
            <h4 className="second-color">andy@gmail.com</h4>
          </p>
        </div>
        <div>
          <p className="text-center text-mute mt-3">
            Check this email to complete registration!
          </p>
        </div>
      </div>
    </div>
  );
}
