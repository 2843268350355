import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Spinner,
} from "reactstrap";
import {
    addUrls,
    distributeRP,
    printLog,
    removeSpecialCharacters,
    validateUserName,
} from "../../util/helper";
import Lock from "../../assets/images/user/lock.png";
import EditCover from "../../assets/images/user/editCoversm.png";
import userprofile from "../../assets/images/user/userprofile (2).png";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "../../util/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import CropImage from "../../components/CropImage";
import { ROUTES_CONFIG } from "../../util/constants";
import { COUNTRIES } from "../../util/constants";

const Profile = ({
    signedInUser,
    setSignedInUser,
    callAlert,
    refreshUser,
    setRefreshUser,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("userId");
    const [userProfile, setUserProfile] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        resetPassword: "",
        gender: "",
        phone: "",
        country: "",
        profileImage: "",
        coverImage: "",
        email: "",
    });
    const [profileFile, setProfileFile] = useState("");
    const [cropImage, setCropImage] = useState(null);
    const [coverFile, setCoverFile] = useState("");
    const [loader, setLoader] = useState(true);
    const [btnLoad, setBtnLoad] = useState(false);
    const [coverLoaded, setCoverLoaded] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);
    const [newUser, setNewUser] = useState(false);

    useEffect(() => {
        console.log(signedInUser);
        geUserData().then(() => {});
    }, []);

    const geUserData = async () => {
        const docRef = doc(db, "users", signedInUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            const fullName = userData.name?.split(" ");
            setUserProfile({
                firstName: fullName && fullName[0],
                lastName: fullName && fullName[1],
                gender: userData.gender,
                phone: userData.phone_number,
                country: userData.location,
                email: signedInUser.email,
                profileImage: userData.profile_photo_url,
                coverImage: userData.cover_photo_url,
                userName: userData.userName,
            });
            setNewUser(userData.newUser);
            setLoader(false);
        } else {
            console.log("No such document!");
        }
    };

    const handleResetPassword = async () => {
        try {
            setResetLoader(true);
            await sendPasswordResetEmail(auth, signedInUser.email);
            callAlert("success", "Reset Password link sent your email");
            setResetLoader(false);
        } catch (error) {
            console.error("Error sending password reset email:", error.message);
            callAlert("fail", error.message);
            setResetLoader(false);
        }
    };
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let values = value;
        if (name === "userName") {
            values = removeSpecialCharacters(value);
        }
        if (type === "file") {
            console.log("Uploading file");
            const file = e.target.files[0];

            if (!file || !file.type.includes("image")) {
                callAlert("fail", "Please select image");
                return;
            }
            if (file && file.size > 15 * 1024 * 1024) {
                // File size is in bytes, so 10 * 1024 * 1024 is 10MB
                callAlert("fail", "Please select file less than 15mb");
                setLoader(false);
                e.target.value = ""; // Clear the input
                return;
            }

            if (file) {
                setCropImage({
                    img: URL.createObjectURL(file),
                    type: name,
                    aspectRatio: name === "coverImage" ? 3 : 1,
                });
                e.target.value = "";
            }
        } else {
            setUserProfile((prevProfile) => ({
                ...prevProfile,
                [name]: values,
            }));
        }
    };

    const handleCallBackImage = (cropImg, type) => {
        let imageSrc = URL.createObjectURL(cropImg);
        switch (type) {
            case "profileImage":
                setUserProfile((prevState) => ({
                    ...prevState,
                    [type]: imageSrc,
                }));
                setProfileFile(cropImg);
                break;
            case "coverImage":
                setUserProfile((prevState) => ({
                    ...prevState,
                    [type]: imageSrc,
                }));
                setCoverFile(cropImg);
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(userProfile);
            // return;
            setBtnLoad(true);
            let cover = "";
            let profile = "";
            if (newUser && !coverFile) {
                console.log("1st");
                callAlert("fail", "Please Select Cover Photo");
                setBtnLoad(false);
                return;
            }
            if (newUser && !profileFile) {
                callAlert("fail", "Please Select Profile Photo");
                setBtnLoad(false);
                return;
            }
            // if (!newUser && !userProfile.coverImage.includes("firebase")) {
            //     callAlert("fail", "Please Select Cover Photo");
            //     setBtnLoad(false);
            //     return;
            // }

            if (!userProfile.firstName || !userProfile.firstName.trim()) {
                callAlert("fail", "Please Fill First Name");
                setBtnLoad(false);
                return;
            }
            if (!userProfile.lastName || !userProfile.lastName.trim()) {
                callAlert("fail", "Please Fill Last Name");
                setBtnLoad(false);
                return;
            }
            if (ROUTES_CONFIG.includes(userProfile.userName?.toLowerCase())) {
                callAlert("fail", "User name invalid");
                setBtnLoad(false);
                return;
            }

            const checkUserName = await validateUserName(userProfile.userName);
            if (
                checkUserName &&
                signedInUser.userName !== userProfile.userName
            ) {
                callAlert("fail", "User name already exists");
                setBtnLoad(false);
                return;
            }

            if (!userProfile.country || !COUNTRIES[userProfile.country]) {
                callAlert("fail", "Please Select Country");
                setBtnLoad(false);
                return;
            }

            if (
                !userProfile.gender ||
                !userProfile.gender.toLowerCase().includes("male")
            ) {
                callAlert("fail", "Please Select Gender");
                setBtnLoad(false);
                return;
            }

            // if (!newUser && !userProfile?.profileImage?.includes("firebase")) {
            //     callAlert("fail", "Please Select Profile Photo");
            //     setBtnLoad(false);
            //     return;
            // }
            if (coverFile) {
                cover = await addUrls(
                    coverFile,
                    "coverPhoto/",
                    signedInUser.uid + "-cover"
                );
            }
            if (profileFile) {
                profile = await addUrls(
                    profileFile,
                    "profilePhoto/",
                    signedInUser.uid + "-profile"
                );
            }

            const {
                email,
                gender,
                country,
                phone,
                firstName,
                lastName,
                userName,
            } = userProfile;
            const userBO = {
                cover_photo_url: cover === "" ? userProfile.coverImage : cover,
                email: email === "" ? signedInUser.email : email,
                gender: gender,
                location: country,
                profile_photo_url:
                    profile === "" ? userProfile.profileImage : profile,
                phone_number: phone,
                name: firstName + " " + lastName,
                userName: userName,
                userNameInsensitive: userName.toLowerCase(),
                followings: [],
            };
            const userRef = doc(db, "users", signedInUser.uid);
            await setDoc(userRef, userBO, { merge: true });
            if (newUser === true) {
                console.log("new user is true and gonna make it false");
                await updateDoc(userRef, { newUser: false });
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    const userObject = {
                        ...docSnap.data(),
                        uid: signedInUser.uid,
                    };
                    await distributeRP(userObject);
                    setRefreshUser(!refreshUser);
                }
            }
            setSignedInUser({ ...signedInUser, ...userBO, newUser: false });
            callAlert("success", "Profile Saved");
            setBtnLoad(false);
            navigate("/viewTrakr");
        } catch (e) {
            console.log(e);
            setBtnLoad(false);
        }
    };
    const copyLink = () => {
        try {
            const playlistLink = `${window.origin}/auth/register?referrerId=${signedInUser.uid}`;
            navigator.clipboard.writeText(playlistLink).then(() => {
                callAlert("success", "Link Copied!");
            });
            // setAlertSettings({
            //     case: "success",
            //     message: "Link Copied!",
            //     action: true,
            // });
            // setShowAlert(true);
            // hideAlert();
        } catch (e) {
            printLog(e, "Error in copying the link");
            callAlert("fail", "Something went wrong");
            // setAlertSettings({
            //     case: "fail",
            //     message: "Something went wrong",
            //     action: true,
            // });
            // setShowAlert(true);
            // hideAlert();
        }
    };

    return (
        <Container
            className="p-profile"
            style={{ height: "80vh", overflowY: "scroll" }}
        >
            {/*{showAlert && <Alert settings={alertSettings}/>}*/}
            {!loader || !userId ? (
                <Form onSubmit={handleSubmit}>
                    <div
                        className={
                            userProfile?.coverImage?.length !== 0
                                ? "height-bg"
                                : "card-bg"
                        }
                        style={{
                            filter:
                                userProfile?.coverImage && !coverLoaded
                                    ? "blur(15px)"
                                    : "none",
                            transition: "filter 0.3s",
                        }}
                        // style={{
                        //     backgroundImage: `url("${userProfile.coverImage}")`,
                        // }}
                    >
                        <label className="w-100">
                            <div
                                className={
                                    userProfile?.coverImage?.length !== 0
                                        ? "height-bg"
                                        : "card-bg"
                                }
                                style={{
                                    filter:
                                        userProfile?.coverImage && !coverLoaded
                                            ? "blur(15px)"
                                            : "none",
                                    transition: "filter 0.3s",
                                }}
                            >
                                {userProfile?.coverImage && (
                                    <img
                                        src={userProfile?.coverImage}
                                        onLoad={() => setCoverLoaded(true)}
                                        alt="Cover Preloading"
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    />
                                )}
                                {!userProfile?.coverImage && (
                                    <h4 className="mx-auto text-center fs-15 fw-bold cover_font pt-4 ">
                                        UPLOAD COVER PHOTO
                                    </h4>
                                )}

                                <input
                                    type="file"
                                    className="d-none "
                                    accept=".jpg,.jpeg,.png"
                                    name="coverImage"
                                    onChange={handleChange}
                                />

                                <div className="text-end p-2 handle_Cover">
                                    <img src={EditCover} alt="" />
                                </div>
                            </div>
                        </label>
                        <label
                            className="profile-img rounded-circle"
                            style={{
                                backgroundImage: `url(${userprofile})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        >
                            {userProfile?.profileImage &&
                                userProfile?.profileImage.length > 0 && (
                                    <img
                                        src={userProfile?.profileImage}
                                        onLoad={() => setProfileLoaded(true)}
                                        style={{
                                            filter: profileLoaded
                                                ? "none"
                                                : "blur(5px)", // 5px is the blur amount, adjust as needed
                                            transition: "filter 0.3s", // Smooth transition when removing blur
                                        }}
                                        // width="100%"
                                        // height="100%"
                                        className="rounded-circle settingsRoundedImg"
                                    />
                                    // <img
                                    //     src={userProfile?.profileImage}
                                    //     className="rounded-circle settingsRoundedImg"
                                    //     alt=""
                                    // />
                                )}
                            <input
                                type="file"
                                className="d-none"
                                accept=".jpg,.jpeg,.png"
                                name="profileImage"
                                // value={userProfile.profileImage}
                                onChange={handleChange}
                            />

                            <div className="edit-profile">
                                <img src={EditCover} alt="" />
                            </div>
                        </label>
                    </div>

                    <div className="editform">
                        <input
                            className="profile-input fs-15 w-100"
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name..."
                            required
                            value={userProfile.firstName}
                            onChange={handleChange}
                        />
                        <div className="d-flex justify-content-center">
                            <hr className="profile-hr" />
                        </div>
                        <input
                            className="profile-input fs-15 w-100"
                            type="text"
                            name="lastName"
                            id="lastName"
                            required
                            placeholder="Last Name..."
                            value={userProfile.lastName}
                            onChange={handleChange}
                        />
                        <div className="d-flex justify-content-center">
                            <hr className="profile-hr" />
                        </div>
                        <input
                            className="profile-input fs-15 w-100"
                            type="text"
                            name="userName"
                            id="userName"
                            required
                            maxLength={24}
                            minLength={4}
                            disabled={!signedInUser.newUser}
                            placeholder="Username..."
                            value={userProfile.userName}
                            onChange={handleChange}
                        />
                        <div className="d-flex justify-content-center mb-3">
                            <hr className="profile-hr" />
                        </div>
                    </div>
                    <Card
                        className="rounded-4"
                        style={{ backgroundColor: "#206FC0" }}
                    >
                        <CardBody>
                            <h6 className="fs-14 text-center create-reg">
                                CREATE YOUR REGISTRATION LINK
                            </h6>
                            <Button
                                className="w-100 bg-reg py-2 rounded-3 linked-copied"
                                onClick={copyLink}
                            >
                                www.zenduja.com/link...
                            </Button>
                        </CardBody>
                    </Card>

                    <Input
                        className="form-select-view mt-3 rounded-3"
                        type="select"
                        name="country"
                        required
                        id="country"
                        value={userProfile.country}
                        onChange={handleChange}
                    >
                        <option>Select Country</option>
                        {Object.keys(COUNTRIES).map((country) => (
                            <option value={country}>
                                {COUNTRIES[country]}
                            </option>
                        ))}
                    </Input>
                    <Input
                        className="form-select-view mt-3 rounded-3"
                        type="select"
                        name="gender"
                        id="gender"
                        required
                        value={userProfile.gender}
                        onChange={handleChange}
                    >
                        <option>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </Input>

                    <div className="py-4">
                        <input
                            className="profile-input fs-15 w-100"
                            type="email"
                            name="email"
                            id="email"
                            required
                            disabled
                            placeholder="Email..."
                            value={
                                userId ? userProfile.email : signedInUser.email
                            }
                            onChange={handleChange}
                        />
                        <div className="d-flex justify-content-center">
                            <hr className="profile-hr" />
                        </div>
                        <input
                            className="profile-input fs-15 w-100"
                            type="tel"
                            name="phone"
                            id="phone"
                            required
                            placeholder="Phone..."
                            value={userProfile.phone}
                            onChange={handleChange}
                        />
                        <div className="d-flex justify-content-center">
                            <hr className="profile-hr" />
                        </div>
                    </div>
                    <InputGroup>
                        {!resetLoader ? (
                            <Input
                                type="text"
                                className="form-select-view"
                                name="resetPassword"
                                placeholder="Reset Password"
                                value={userProfile.resetPassword}
                                disabled={resetLoader}
                                onClick={handleResetPassword}
                            />
                        ) : (
                            <div
                                className="form-select-view  text-center"
                                style={{ width: "calc(100% - 49px)" }}
                            >
                                <div
                                    className="spinner-border spinner-border-sm "
                                    role="status"
                                >
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        )}
                        <InputGroupText className="group-text pe-4">
                            <img src={Lock} alt="" />
                        </InputGroupText>
                    </InputGroup>
                    <Button
                        disabled={btnLoad}
                        className="w-100 mt-5 bg-reg py-2"
                        onSubmit={handleSubmit}
                    >
                        {!btnLoad ? (
                            "Save"
                        ) : (
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                        )}
                    </Button>
                </Form>
            ) : (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            )}
            {cropImage && (
                <CropImage
                    cropImage={cropImage}
                    handleClose={() => setCropImage(null)}
                    handleCallBackImage={handleCallBackImage}
                    aspectRatio={cropImage.aspectRatio}
                />
            )}
        </Container>
    );
};

export default Profile;
