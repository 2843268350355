import React, { useState, useEffect } from "react";
import routes from "../routes";
import { Routes, Route, Navigate } from "react-router-dom";
import "./../assets/styles/auth.css";

const Auth = ({ callAlert }) => {
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        function handleResize() {
            setIsScrollable(
                document.documentElement.scrollHeight >
                    (window.innerHeight ||
                        document.documentElement.clientHeight)
            );
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getRoutes = (_routes) => {
        console.log(_routes);
        return _routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                element={<route.component callAlert={callAlert} />}
                exact
            />
        ));
    };

    return (
        <div
            className={`contain auth-root ${isScrollable ? "auto-height" : ""}`}
        >
            <Routes>
                {getRoutes(routes.auth)}
                <Route path="*" element={<Navigate to="/auth/login" />} />
            </Routes>
        </div>
    );
};

export default Auth;
