import React, { useCallback, useEffect, useState } from "react";
import UserFooter from "../components/UserFooter";
import "../assets/styles/search.css";
import { Input } from "reactstrap";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../util/firebase";
import { useNavigate } from "react-router-dom";
import { printLog } from "../util/helper";
import searchIcon from "../assets/images/user/search-icon.png";

const Search = ({ refreshUser, setRefreshUser, signedInUser }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    // Debounce function to delay the execution
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const searchQuery = async (
        searchValue,
        endValue,
        collectionName,
        fieldName
    ) => {
        const q = query(
            collection(db, collectionName),
            where(fieldName, ">=", searchValue),
            where(fieldName, "<", endValue)
        );
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        return usersData;
    };

    const handleSearch = async (searchValue) => {
        if (!searchValue) {
            setUsers([]);
            setGroups([]);
            setLoading(false);
            return;
        }

        // setLoading(true);
        try {
            const endValue = searchValue.replace(/.$/, (c) =>
                String.fromCharCode(c.charCodeAt(0) + 1)
            );
            const userResults = await searchQuery(
                searchValue.toLowerCase(),
                endValue.toLowerCase(),
                "users",
                "userNameInsensitive"
            );
            const groupResults = await searchQuery(
                searchValue.toLowerCase(),
                endValue.toLowerCase(),
                "groups",
                "nameInsensitive"
            );

            setUsers(
                userResults.filter((user) => user.id !== signedInUser.uid)
            );
            setGroups(groupResults);
        } catch (error) {
            printLog("Error fetching users:", error);
        }
        setLoading(false);
    };

    // Wrap debounce function in useCallback
    const debouncedSearch = useCallback(debounce(handleSearch, 1000), []);

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            setUsers([]); // Clear results when search term is empty
            setGroups([]);
            setLoading(false);
        }
    }, [searchTerm, debouncedSearch]);

    const handleInputChange = (event) => {
        setLoading(true);
        setSearchTerm(event.target.value);
    };

    // This component renders the list of users
    const UserList = ({ users }) => {
        return (
            <>
                {users.map((user, index) => (
                    <div
                        key={user.id}
                        onClick={() => {
                            console.log(user);
                            navigate(`/${user.userNameInsensitive}`);
                        }}
                        className={`d-flex align-items-center pb-2 ${
                            index !== users.length - 1 &&
                            "border-bottom border-dark"
                        } mb-2`}
                    >
                        <div className="me-2">
                            <img
                                src={user?.profile_photo_url}
                                width={40}
                                height={40}
                                className="rounded-circle"
                                alt=""
                            />
                        </div>
                        <div>{user.userName}</div>
                    </div>
                ))}
                <div className="text-center text-info">See all results</div>
            </>
        );
    };

    const GroupList = ({ groups }) => {
        return (
            <>
                {groups.map((group, index) => (
                    <div
                        key={group.id}
                        onClick={() => {
                            console.log(group);
                            navigate(`/group/${group.id}`, { state: group });
                        }}
                        className={`d-flex align-items-center pb-2 ${
                            index !== groups.length - 1 &&
                            "border-bottom border-dark"
                        } mb-2`}
                    >
                        <div className="me-2">
                            <img
                                src={group?.profile_photo_url}
                                width={40}
                                height={40}
                                className="rounded-circle"
                                alt=""
                            />
                        </div>
                        <div>
                            <div>{group.name}</div>
                            <div className="fs-12 text-white-50">
                                {signedInUser.joinedGroups?.[group.id]
                                    ? "Your Group"
                                    : "Group"}
                            </div>
                        </div>
                    </div>
                ))}
                <div className="text-center text-info">See all results</div>
            </>
        );
    };

    return (
        <div className="bg-black">
            <div style={{ height: "93vh" }} className="mx-2 pt-3">
                <div className="InputContainer">
                    <Input
                        type="text"
                        name="text"
                        className="input"
                        id="input"
                        onChange={handleInputChange}
                        value={searchTerm}
                        placeholder="Search"
                    />
                    {/*<div className="border"></div>*/}
                    <label htmlFor="input" className="labelforsearch">
                        <svg viewBox="0 0 512 512" className="searchIcon">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                        </svg>
                    </label>
                </div>
                <div className="text-white mt-3">
                    {searchTerm && (
                        <div className="d-flex align-items-center mb-2">
                            <div className="border rounded-circle me-2 px-2 py-2">
                                <img
                                    src={searchIcon}
                                    alt=""
                                    width={25}
                                    height={22}
                                />
                            </div>
                            <span>{searchTerm}</span>
                        </div>
                    )}
                    {loading ? (
                        <div className="text-center">Loading...</div>
                    ) : (
                        searchTerm &&
                        (!loading && users.length > 0 ? (
                            <UserList users={users} />
                        ) : groups.length > 0 ? (
                            <>
                                <span>Groups</span>
                                <GroupList groups={groups} />
                            </>
                        ) : (
                            <h3 className="text-center">No records</h3>
                        ))
                    )}
                </div>
            </div>
            <UserFooter
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
            />
        </div>
    );
};

export default Search;
