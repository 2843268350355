import React, { useEffect } from "react";
import routes from "../routes";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "../assets/styles/public.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../util/firebase";
import PlaylistView from "../views/public/playlistView";

const Public = ({ callAlert, pid }) => {
    return (
        <div id="public-layout" className="full-screen-height bg-black">
            <PlaylistView callAlert={callAlert} pid={pid} />
        </div>
    );
};

export default Public;
