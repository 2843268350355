import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Spinner } from "reactstrap";
import { auth, db } from "./util/firebase";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Auth from "./layouts/Auth";
import User from "./layouts/User";
import ViewTrakr from "./layouts/ViewTrakr";
import Public from "./components/Public";
import Leaderboard from "./layouts/Leaderboard";
import Alert from "./components/Alert";
import AddNewPost from "./layouts/addNewPost";
import {
    getMetaData,
    updateLeaderboardDate,
    validateLeaderboard,
} from "./util/helper";
import Search from "./layouts/search";
import MyWave from "./layouts/MyWave";
import Group from "./layouts/Group";
import UserProfile from "./layouts/UserProfile";
import Playlist from "./layouts/Playlist";

function App() {
    const navigate = useNavigate();
    const [signedInUser, setSignedInUser] = useState(getAuth().currentUser);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [postFile, setPostFile] = useState(null);
    const [groupPostFile, setGroupPostFile] = useState(null);
    const [alertSettings, setAlertSettings] = useState({
        message: "",
        case: "",
        action: false,
    });
    const [restrictUser, setRestrictUser] = useState(false);
    const [appMetaData, setAppMetaData] = useState({});
    const [refreshUser, setRefreshUser] = useState(true);
    const [userPoints, setUserPoints] = useState(0);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await updateLeaderboardDate();
                let _user = user;
                const userRef = doc(db, "users", user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    _user = { ..._user, ...userSnap.data() };
                }
                const appMeta = await getMetaData();
                setAppMetaData(appMeta);
                const pointsRef = doc(db, "leaderboard", user.uid);
                const pointsSnap = await getDoc(pointsRef);
                if (pointsSnap.exists()) {
                    setUserPoints(pointsSnap.data().points || 0);
                } else setUserPoints(0);
                localStorage.setItem("user", JSON.stringify({ ..._user }));
                setRestrictUser(validateLeaderboard(_user, appMeta));
                setSignedInUser(_user);
                setIsLoggedIn(true);
            } else {
                setSignedInUser(null);
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        });
    }, [refreshUser]);

    const callAlert = (state, message) => {
        setAlertSettings({ case: state, message: message, action: true });
        setShowAlert(true);
        hideAlert();
    };
    const hideAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    return (
        <div className="App">
            {isLoading ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : (
                <>
                    {showAlert && <Alert settings={alertSettings} />}
                    <Routes>
                        <Route path="/" element={<Navigate to="/auth" />} />

                        {/* <Route
                            path="*"
                            element={<Navigate to="/auth" replace />}
                        /> */}
                        <Route
                            path="/:username/*"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <UserProfile
                                        setPostFile={setPostFile}
                                        appMetaData={appMetaData}
                                        userPoints={userPoints}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        callAlert={callAlert}
                                        signedInUser={signedInUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                        <Route
                            path="/auth/*"
                            element={
                                isLoggedIn ? (
                                    signedInUser.newUser ? (
                                        <Navigate to="/user/profile" />
                                    ) : (
                                        <Navigate
                                            to={`/${signedInUser.userNameInsensitive}`}
                                        />
                                    )
                                ) : (
                                    <Auth
                                        setIsLoggedIn={setIsLoggedIn}
                                        callAlert={callAlert}
                                    />
                                )
                            }
                        />
                        <Route
                            path="/user/*"
                            element={
                                isLoggedIn ? (
                                    <User
                                        userPoints={userPoints}
                                        callAlert={callAlert}
                                        appMetaData={appMetaData}
                                        setPostFile={setPostFile}
                                        signedInUser={signedInUser}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        setSignedInUser={setSignedInUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                        <Route
                            path="/group/*"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <Group
                                        userPoints={userPoints}
                                        callAlert={callAlert}
                                        appMetaData={appMetaData}
                                        setPostFile={setPostFile}
                                        setGroupPostFile={setGroupPostFile}
                                        signedInUser={signedInUser}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        setSignedInUser={setSignedInUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />

                        <Route
                            path="/viewTrakr/*"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <ViewTrakr
                                        setPostFile={setPostFile}
                                        appMetaData={appMetaData}
                                        userPoints={userPoints}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        callAlert={callAlert}
                                        signedInUser={signedInUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />

                        <Route
                            path="/viewTrakr/:pid"
                            element={
                                <Playlist
                                    setPostFile={setPostFile}
                                    appMetaData={appMetaData}
                                    userPoints={userPoints}
                                    refreshUser={refreshUser}
                                    setRefreshUser={setRefreshUser}
                                    callAlert={callAlert}
                                    signedInUser={signedInUser}
                                    isLoggedIn={isLoggedIn}
                                />
                            }
                        />

                        <Route
                            path="/add-new-post"
                            element={
                                isLoggedIn && (postFile || groupPostFile) ? (
                                    <AddNewPost
                                        callAlert={callAlert}
                                        signedInUser={signedInUser}
                                        postFile={
                                            postFile || groupPostFile?.file
                                        }
                                        group={groupPostFile?.group}
                                        postOrigin={
                                            postFile ? "personal" : "group"
                                        }
                                        handleClose={() => {
                                            setGroupPostFile(null);
                                            setPostFile(null);
                                        }}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                        <Route
                            path="/leaderboard/*"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <Leaderboard
                                        appMetaData={appMetaData}
                                        callAlert={callAlert}
                                        signedInUser={signedInUser}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        userPoints={userPoints}
                                        restrictUser={restrictUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                        <Route
                            path="/mywave/*"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <MyWave
                                        appMetaData={appMetaData}
                                        userPoints={userPoints}
                                        refreshUser={refreshUser}
                                        setRefreshUser={setRefreshUser}
                                        callAlert={callAlert}
                                        signedInUser={signedInUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                        <Route
                            path="/search"
                            element={
                                isLoggedIn && !signedInUser.newUser ? (
                                    <Search
                                        refreshUser={refreshUser}
                                        signedInUser={signedInUser}
                                        setRefreshUser={setRefreshUser}
                                    />
                                ) : (
                                    <Navigate to="/auth" />
                                )
                            }
                        />
                    </Routes>
                </>
            )}
        </div>
    );
}

export default App;
