import RegisterSuccessful from "../views/auth/RegisterSuccessful";
import Login from "../views/auth/login";
import Register from "../views/auth/register";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        layout: "/auth",
    },
    {
        path: "/register_success",
        name: "RegisterSuccess",
        component: RegisterSuccessful,
        layout: "/auth",
    },
];

export default routes;
