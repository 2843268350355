import React from "react";
import UserFooter from "./UserFooter";
import ViewOnly from "./viewOnly";
import "../assets/styles/ViewTrakr.css";

const PlaylistView = ({
    refreshUser,
    pid,
    setRefreshUser,
    signedInUser,
    appMetaData,
    callAlert,
}) => {
    return (
        <div className="bg-black">
            <div style={{ height: "93vh", overflowY: "auto" }}>
                <ViewOnly
                    signedInUser={signedInUser}
                    appMetaData={appMetaData}
                    callAlert={callAlert}
                    refreshUser={refreshUser}
                    setRefreshUser={setRefreshUser}
                    pid={pid}
                />
            </div>
            <UserFooter
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
            />
        </div>
    );
};

export default PlaylistView;
