import React, {useEffect} from 'react';

const Alert = (props) => {

    return props.settings.action ? (
        <div
            className={`custom-alert ${props.settings.case === 'success' ? 'custom-alert-success' : 'custom-alert-fail'}`}>
            {props.settings.message}
        </div>
    ) : null;
};

export default Alert;

