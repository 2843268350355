import React, { useEffect } from "react";
import { Card, CardBody, img, CardTitle, Col, Row } from "reactstrap";
import US4 from "../assets/images/news-feed/us-4.png";
import US6 from "../assets/images/news-feed/us-6.png";
import US3 from "../assets/images/news-feed/us-3.png";
import US5 from "../assets/images/news-feed/us-5.png";
import { Link } from "react-router-dom";

const PointsCard = ({ feedItem, redirectLink }) => {
    useEffect(() => {
        console.log(feedItem);
        console.log(redirectLink);
    }, []);
    return (
        <Card className=" mx-auto feed-card1 my-5">
            {feedItem.hasOwnProperty("activity_points") &&
                feedItem.activity_points > 0 && (
                    <CardBody>
                        <Row className=" custom-row mx-3">
                            <Col className=" g-0" xs={3}>
                                <Link to={`/${feedItem.userNameInsensitive}`}>
                                    <img
                                        className="rounded-circle"
                                        src={feedItem.user_profile_photo}
                                        width={60}
                                        height={60}
                                    />
                                </Link>
                            </Col>
                            <Col className="mx-auto py-3 px-3" xs={6}>
                                <span className=" us-title ">
                                    {/*Sector {feedItem.sector}*/}
                                    NEW ARCHITECT
                                </span>
                                <CardTitle className="us-title1 mb-0 text-uppercase">
                                    {feedItem.user_name}
                                </CardTitle>
                                <CardTitle className="us-title2 mb-0">
                                    Is climbing the ranks!
                                    {/*Sector {feedItem.sector} Description*/}
                                </CardTitle>
                            </Col>
                            <Col xs={3}>
                                <div>
                                    <img className=" mx-auto" src={US4} />
                                    <CardTitle className="us-title text-center mb-0">
                                        +{feedItem.activity_points}pts
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                )}
            {feedItem.hasOwnProperty("reset_points") &&
                feedItem.reset_points > 0 && (
                    <CardBody>
                        <Row className=" custom-row mx-3 ">
                            <Col className="g-0" xs={3}>
                                <Link to={`/${feedItem.userNameInsensitive}`}>
                                    <img
                                        className="rounded-circle"
                                        src={feedItem.user_profile_photo}
                                        width={60}
                                        height={60}
                                    />
                                </Link>
                            </Col>
                            <Col className="mx-auto px-3" xs={6}>
                                <CardTitle className="us-title us-title11 mb-0 fs-8">
                                    AUDIENCE POINTS
                                </CardTitle>
                                <CardTitle className="us-title1 mb-0 text-uppercase fw-semibold">
                                    {feedItem.user_name}
                                </CardTitle>
                                <CardTitle className=" us-title2 mb-0 fs-8">
                                    Signed in on SECTOR {feedItem.sector}
                                </CardTitle>
                            </Col>
                            <Col className="g-0" xs={3}>
                                <div>
                                    <img className=" mx-auto " src={US6} />
                                    <CardTitle className=" us-title text-center us-title11 us-points ">
                                        +{feedItem.reset_points}pts
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                )}
            <CardBody>
                <a
                    href={redirectLink}
                    target="_blank"
                    className="text-decoration-none"
                >
                    <Row className="mx-3">
                        <Col className=" g-0" xs={3}>
                            <img
                                className=" "
                                src={US3}
                                height={60}
                                width={60}
                            />
                        </Col>
                        <Col className="mx-auto px-3" xs={6}>
                            <CardTitle className="us-title  us-title12 mb-0 fs-8">
                                OFFICIAL SPONSOR
                            </CardTitle>
                            <CardTitle className="us-title1 mb-0 fw-semibold">
                                EYEBOX
                            </CardTitle>
                            <CardTitle
                                className="us-title2 mb-0 fs-8"
                                style={{ lineHeight: "1.5" }}
                            >
                                Click as many as possible! 100pts each.
                            </CardTitle>
                        </Col>
                        <Col xs={3}>
                            <div>
                                <img className=" mx-auto" src={US5} />
                            </div>
                        </Col>
                    </Row>
                </a>
            </CardBody>
        </Card>
    );
};

export default PointsCard;
