import React, { useEffect, useMemo, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row,
    Spinner,
} from "reactstrap";
import "../assets/styles/leaderboard.css";
import Like from "../assets/images/news-feed/like.png";
import Comment from "../assets/images/news-feed/comment.png";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/user/logo.png";
import x_icon from "../assets/images/add-user/x-mark.svg";
import check_mark from "../assets/images/add-user/check.svg";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../util/firebase";
import { addUrls, printLog } from "../util/helper";

const AddNewPost = ({
    signedInUser,
    postFile,
    callAlert,
    postOrigin,
    group,
    handleClose,
}) => {
    const navigate = useNavigate();
    const [post, setPost] = useState({
        description: "",
        location: "",
    });
    const [loading, setLoading] = useState(false);
    const [mediaPreviewUrl, setMediaPreviewUrl] = useState(null);

    const memoizedMediaPreview = useMemo(() => {
        if (!mediaPreviewUrl) {
            return null;
        }

        if (postFile?.type.startsWith("video")) {
            return (
                <video
                    className="mx-auto d-block"
                    height={224}
                    width="100%"
                    controls
                    src={mediaPreviewUrl}
                >
                    Your browser does not support the video tag.
                </video>
            );
        } else if (postFile?.type.startsWith("image")) {
            return (
                <img
                    className="post-img mx-auto"
                    style={{
                        filter: "none", // 5px is the blur amount, adjust as needed
                        transition: "filter 0.3s", // Smooth transition when removing blur
                        height: "100%",
                        width: "100%",
                    }}
                    src={mediaPreviewUrl}
                    alt="Post media"
                />
            );
        }
        // Return null or placeholder for unsupported file types
    }, [mediaPreviewUrl, postFile?.type]);

    useEffect(() => {
        // Create an object URL for the file
        if (postFile) {
            const url = URL.createObjectURL(postFile);
            setMediaPreviewUrl(url);

            // Cleanup function to revoke the object URL
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [postFile]); // This effect should run whenever postFile changes

    const postImage = async (event) => {
        event.preventDefault();
        console.log(post);
        try {
            setLoading(true);
            let postType;
            if (postFile.type.includes("video")) postType = "video";
            else if (postFile.type.includes("image")) postType = "image";
            console.log(postType);
            console.log(Date.now(), "Current date");
            let url = "";
            if (postOrigin === "group") {
                url = await addUrls(
                    postFile,
                    "groups/",
                    `${group.id}-${signedInUser.uid}-${Date.now()}`
                );
            } else {
                url = await addUrls(
                    postFile,
                    "posts/",
                    `${signedInUser.uid}-${Date.now()}`
                );
            }

            const data = {
                user_id: signedInUser.uid,
                user_name: signedInUser.userNameInsensitive,
                user_profile_photo: signedInUser.profile_photo_url,
                type: postType,
                description: post.description,
                location: post.location,
                createdAt: serverTimestamp(),
                sourceUrl: url,
            };

            if (postOrigin === "group" && group) {
                Object.assign(data, {
                    groupId: group.id,
                    groupName: group.name,
                    group_profile_photo: group.profile_photo_url,
                });
            }

            addDoc(collection(db, "posts"), data).then((docRef) => {
                callAlert("success", "Added to your wall");
                setLoading(false);
                handleClose();
                if (postOrigin === "group" && group) {
                    navigate(`/group/${group.id}`);
                } else {
                    navigate("/viewTrakr");
                }
            });
        } catch (error) {
            printLog(error, "Error in uploading post");
            setLoading(false);
        }
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setPost((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div
            style={{ height: "100vh", overflowY: "auto" }}
            className="bg-black"
        >
            <div className="d-flex justify-content-between align-items-center text-white p-4">
                <div>
                    <img
                        src={x_icon}
                        alt=""
                        role="button"
                        onClick={() => {
                            handleClose();
                            navigate(-1);
                        }}
                    />
                </div>
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <div>
                    <img src={check_mark} alt="" role="button" />
                </div>
            </div>
            <Container className="px-5">
                <Card className=" mx-auto feed-card my-5">
                    <CardHeader className="pb-0 pt-2 px-2">
                        <Col xs={4}>
                            <div className="px-2 d-flex">
                                <div>
                                    <img
                                        className="select-user-img rounded-circle"
                                        height={30}
                                        width={30}
                                        alt=""
                                        src={signedInUser?.profile_photo_url}
                                    />
                                </div>
                                <div>
                                    <p className="select-user-name ms-2">
                                        {" "}
                                        @{signedInUser?.userName}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </CardHeader>
                    <CardBody className="pt-0 text-start">
                        <div className="text-center">
                            {memoizedMediaPreview}
                        </div>
                        {/*<MediaPreview mediaPreviewUrl={mediaPreviewUrl}/>*/}
                        {/*<CardImg className=" mx-auto" style={{height: "224px"}} src={URL.createObjectURL(postFile)}/>*/}
                        <Row className="my-3 px-2">
                            <Col className="px-0 d-flex" xs={6}>
                                <div className=" px-2">
                                    <img
                                        className="like-img mx-2"
                                        src={Like}
                                        alt=""
                                    />
                                </div>

                                <div className=" px-2">
                                    <img
                                        className="like-img mx-2"
                                        src={Comment}
                                        alt=""
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Form onSubmit={postImage}>
                    <FormGroup>
                        <Input
                            className="new-post-input rounded-0"
                            placeholder="Write a caption..."
                            id="exampleText"
                            maxLength={140}
                            onChange={handleChange}
                            disabled={loading}
                            // required
                            name="description"
                        />
                    </FormGroup>
                    <FormGroup className="mt-3">
                        <Input
                            className="new-post-input rounded-0"
                            id="exampleSearch"
                            name="location"
                            // required
                            disabled={loading}
                            onChange={handleChange}
                            placeholder="Enter a location..."
                            type="search"
                        />
                    </FormGroup>
                    <div className="mt-5 mb-4 mx-auto text-center">
                        {/* <div className="Post-btn py-3">
                            Schedule Post <br />
                            <DateTimePicker disabled={true} className="bg-transparent"/>
                        </div> */}
                    </div>
                    <div className="mx-auto text-center mb-5">
                        <Button
                            className="main-post-btn"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? (
                                <Spinner size="sm">Loading...</Spinner>
                            ) : (
                                "Post!"
                            )}
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default AddNewPost;
