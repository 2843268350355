import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { defaultViewsJump, logEnabled, pointsConfig } from "./constants";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    serverTimestamp,
    setDoc,
    Timestamp,
    updateDoc,
    where,
} from "firebase/firestore";
import moment from "moment";

export const addUrls = async (file, path, name) => {
    const storageRef = ref(storage, path + name);
    try {
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    } catch (error) {
        printLog(error, "Error uploading image");
        throw error;
    }
};
export const printLog = (item, message) => {
    if (logEnabled) console.log(item, message);
};

// 14-11-2023 updated

// Function to determine the sector based on the level
const determineSector = (level) => {
    return Object.keys(pointsConfig.sectors).find((sector) => {
        const levels = Object.keys(pointsConfig.sectors[sector].levels).map(
            Number
        );
        return levels.includes(level);
    });
};

// Function to calculate AP based on level and views
const calculateAP = (level, views) => {
    const sector = determineSector(level);
    if (!sector) return 0;
    console.log(level, "Level");
    console.log(views, "Views");
    console.log(sector, "Sector");
    const sectorConfig = pointsConfig.sectors[sector];
    if (views % sectorConfig.views === 0) {
        console.log(sectorConfig.levels[level].activity_points, "Points");
        return sectorConfig.levels[level].activity_points;
    }
    return 0;
};

// Function to distribute AP to a user
const distributeAP = async (userId, points) => {
    if (points <= 0) return;
    const userRef = doc(db, "users", userId);
    console.log("Updating user points");
    await updateDoc(userRef, {
        activityPoints: increment(points),
    });
};

// Main function to update the video views and distribute points
// export const updateViews = async (videoId) => {
//     console.log(videoId);
//     try {
//         const videoRef = doc(db, "videos", videoId);
//         await updateDoc(videoRef, {views: increment(1)});
//
//         const videoSnap = await getDoc(videoRef);
//         const views = videoSnap.data().views;
//         if (views % 10 !== 0) {
//             console.log("returning from check", views);
//             return views;
//         }
//         const uploaderId = videoSnap.data().user_id;
//         const uploaderRef = doc(db, "users", uploaderId);
//         const uploaderSnap = await getDoc(uploaderRef);
//         const uploaderData = uploaderSnap.data();
//         const uploaderSectorViews = uploaderData?.sectorViews || {};
//         const prevViews = uploaderSectorViews["0"] || 0;
//         console.log(prevViews, "prevViews ");
//         const newViews = prevViews + 10;
//         console.log(newViews, "NEWViews");
//         uploaderSectorViews["0"] = newViews;
//         console.log(uploaderSectorViews, "uploaderSectorViews");
//         await updateDoc(uploaderRef, {sectorViews: uploaderSectorViews});
//         if (newViews > 0) {
//             const uploaderPoints = calculateAP(0, newViews);
//             console.log(uploaderPoints, "uploader points");
//             await distributeAP(uploaderId, uploaderPoints);
//             await makeLeaderboard(
//                 uploaderId,
//                 uploaderPoints,
//                 uploaderData.name,
//                 uploaderData.userName,
//                 uploaderData.profile_photo_url,
//                 uploaderData.cover_photo_url,
//                 uploaderData.location
//             );
//         }
//         const waveMembers = uploaderData.wave_members || [];
//
//         console.log("Start distributing points");
//         // version  4
//         for (const waveMember of waveMembers) {
//             const memberRef = doc(db, "users", waveMember.id);
//             const memberSnap = await getDoc(memberRef);
//             const memberData = memberSnap.data();
//             const memberSectorViews = memberData.sectorViews || {};
//             const memberNewsFeeds = memberData.newsFeed || [];
//             let newsFeedIndex = memberData?.newsFeed?.findIndex(
//                 (entry) => entry.user_id === uploaderId
//             );
//             console.log(newsFeedIndex, "newsFeedIndex");
//
//             const sector = determineSector(waveMember.level);
//
//             // if (parseInt(sector) >= 4) {
//             const previousSectorViews = memberSectorViews[sector] || 0;
//             const newSectorViews = previousSectorViews + 10;
//
//             // Update sector views for future reference
//             memberSectorViews[sector] = newSectorViews;
//             await updateDoc(memberRef, {sectorViews: memberSectorViews});
//
//             // Check if views meet the condition for distributing points
//             if (newSectorViews >= pointsConfig.sectors[sector].views) {
//                 const points = calculateAP(waveMember.level, newSectorViews);
//                 console.log("making points:", points, "Of user ", waveMember.id);
//                 await distributeAP(waveMember.id, points);
//                 const previousPoints =
//                     memberNewsFeeds[newsFeedIndex]?.activity_points || 0;
//                 const createdAt = await getFirebaseTimestamp();
//                 const newsFeedInstance = {
//                     activity_points: previousPoints + points,
//                     user_id: uploaderId,
//                     user_name: uploaderData.name,
//                     user_profile_photo: uploaderData.profile_photo_url,
//                     sector: parseInt(sector),
//                     createdAt: createdAt,
//                 };
//                 if (newsFeedIndex >= 0) {
//                     memberNewsFeeds[newsFeedIndex].activity_points =
//                         newsFeedInstance.activity_points;
//                     memberNewsFeeds[newsFeedIndex].createdAt = createdAt;
//                 } else {
//                     memberNewsFeeds.push(newsFeedInstance);
//                 }
//                 console.log(memberNewsFeeds, "memberNewsFeeds");
//                 await updateDoc(memberRef, {newsFeed: memberNewsFeeds});
//                 await makeLeaderboard(
//                     waveMember.id,
//                     points,
//                     memberData.name,
//                     memberData.userName,
//                     memberData.profile_photo_url,
//                     memberData.cover_photo_url,
//                     memberData.location
//                 );
//             }
//         }
//         return views;
//     } catch (error) {
//         console.log(error, "error in updating views");
//     }
// };

// Version 2
export const updateViews = async (videoId) => {
    console.log(videoId);
    try {
        const videoRef = doc(db, "videos", videoId);
        const videoSnap = await getDoc(videoRef);
        if (videoSnap.data().user_id === "library") {
            console.log("This is library video no algo will run");
            return 0;
        }
        await updateDoc(videoRef, { views: increment(1) });

        const views = videoSnap.data().views + 1;
        if (views % defaultViewsJump !== 0) {
            console.log("returning from check", views);
            return views;
        }
        const uploaderId = videoSnap.data().user_id;
        const uploaderRef = doc(db, "users", uploaderId);
        const uploaderSnap = await getDoc(uploaderRef);
        const uploaderData = uploaderSnap.data();
        const uploaderSectorViews = uploaderData?.sectorViews || {};
        const uploaderSectorPoints = uploaderData?.sectorPoints || {};
        const prevViews = uploaderSectorViews["0"] || 0;
        const prevPoints = uploaderSectorPoints["0"] || 0;
        const newViews = prevViews + defaultViewsJump;
        uploaderSectorViews["0"] = newViews;

        // Calculate activity points for uploader
        const uploaderPoints = calculateAP(0, newViews);
        // uploaderSectorPoints["0"] = prevPoints + uploaderPoints;
        const generatedPoints = prevPoints + uploaderPoints;
        // Combine the updates for uploader into a single object
        const uploaderCombinedUpdates = {
            sectorViews: uploaderSectorViews,
            [`sectorPoints.${0}`]: generatedPoints,
            activityPoints: increment(uploaderPoints),
        };

        // Update the uploader document once with the combined updates
        await updateDoc(uploaderRef, uploaderCombinedUpdates);

        if (newViews > 0) {
            await makeLeaderboard(
                uploaderId,
                uploaderPoints,
                uploaderData.name,
                uploaderData.userNameInsensitive,
                uploaderData.profile_photo_url,
                uploaderData.cover_photo_url,
                uploaderData.location
            );
        }

        const waveMembers = uploaderData.wave_members || {};

        console.log("Start distributing points");
        for (const waveMember in waveMembers) {
            const memberRef = doc(db, "users", waveMember);
            const memberSnap = await getDoc(memberRef);
            const memberData = memberSnap.data();
            const memberSectorViews = memberData.sectorViews || {};
            const memberSectorPoints = memberData.sectorPoints || {};
            const memberNewsFeeds = memberData.newsFeed || [];
            let newsFeedIndex = memberData?.newsFeed?.findIndex(
                (entry) => entry.user_id === uploaderId
            );
            const sector = determineSector(waveMembers[waveMember]);

            console.log(sector, "Wave member sector");

            // Calculate activity points for wave member
            const memberPoints = calculateAP(
                waveMembers[waveMember],
                memberSectorViews[sector] + defaultViewsJump
            );
            console.log(memberPoints, "Member Points");
            console.log(memberSectorViews, "memberSectorViews[sector]");
            console.log(memberSectorPoints, "memberSectorPoints[sector]");
            // Update sector views and calculate combined updates
            const memberCombinedUpdates = {
                sectorViews: {
                    ...memberSectorViews,
                    [sector]:
                        Number(memberSectorViews[sector] || 0) +
                        defaultViewsJump,
                },
                sectorPoints: {
                    ...memberSectorPoints,
                    [sector]:
                        Number(memberSectorPoints[sector] || 0) +
                        Number(memberPoints),
                },
                activityPoints: increment(memberPoints),
            };

            console.log(memberCombinedUpdates, "Member combined updates");

            if (memberPoints > 0) {
                // Update news feed
                const previousPoints =
                    memberNewsFeeds[newsFeedIndex]?.activity_points || 0;
                const createdAt = await getFirebaseTimestamp();
                const newsFeedInstance = {
                    activity_points: previousPoints + memberPoints,
                    user_id: uploaderId,
                    user_name: uploaderData.name,
                    userNameInsensitive: uploaderData.userNameInsensitive,
                    user_profile_photo: uploaderData.profile_photo_url,
                    sector: parseInt(sector),
                    createdAt: createdAt,
                };

                if (newsFeedIndex >= 0) {
                    memberNewsFeeds[newsFeedIndex].activity_points =
                        newsFeedInstance.activity_points;
                    memberNewsFeeds[newsFeedIndex].createdAt = createdAt;
                } else {
                    memberNewsFeeds.push(newsFeedInstance);
                }

                // Include newsFeed in combined updates
                memberCombinedUpdates.newsFeed = memberNewsFeeds;
            }
            console.log(
                "updating ",
                waveMember,
                " with : ",
                memberCombinedUpdates
            );
            // Update the wave member document once with the combined updates
            await updateDoc(memberRef, memberCombinedUpdates);

            // Update leaderboard for the wave member
            await makeLeaderboard(
                waveMember,
                memberPoints,
                memberData.name,
                memberData.userNameInsensitive,
                memberData.profile_photo_url,
                memberData.cover_photo_url,
                memberData.location
            );
        }
        return views;
    } catch (error) {
        console.log(error, "error in updating views");
    }
};

export const getFirebaseTimestamp = async () => {
    const docRef = doc(db, "config", "temp");
    const response = await setDoc(docRef, {
        timestamp: serverTimestamp(),
    });
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log(docSnap.data().timestamp);
        return docSnap.data().timestamp;
    }
};

const makeLeaderboard = async (
    user_id,
    points,
    name,
    user_name,
    profile,
    cover,
    location
) => {
    console.log("Updating doc in leaderboard : ", user_id);
    const docRef = doc(db, "leaderboard", user_id);
    try {
        await setDoc(
            docRef,
            {
                points: increment(points),
                user_id: user_id,
                name: name,
                user_name: user_name,
                profile: profile,
                cover: cover,
                location: location,
            },
            { merge: true } // Use merge option to create if it doesn't exist or update if it does
        );
    } catch (error) {
        console.log(error, "Error in making leaderboard");
    }
};

export const validateLeaderboard = (user, meta) => {
    const leaderboardData = meta?.leaderboard_reset;
    const userResetDate = user.reset_points_distributed;
    if (userResetDate) return userResetDate < leaderboardData.updatedAt;
    else return true;
};
export const distributeRP = async (user) => {
    console.log(user);
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
        reset_points_distributed: serverTimestamp(),
        resetPoints: increment(calculateRP(0)),
        [`sectorPoints.${0}`]: increment(calculateRP(0)),
    });
    const userResetPoints = calculateRP(0);
    await makeLeaderboard(
        user.uid,
        userResetPoints,
        user.name,
        user.userNameInsensitive,
        user.profile_photo_url,
        user.cover_photo_url,
        user.location
    );
    const waveMembers = user.wave_members || {};
    for (const waveMember in waveMembers) {
        const memberRef = doc(db, "users", waveMember);
        const memberSnap = await getDoc(memberRef);
        let memberData = {};
        if (memberSnap.exists()) {
            memberData = { ...memberSnap.data() };
        }
        const sector = determineSector(waveMembers[waveMember]);
        const memberNewsFeeds = memberData.newsFeed || [];
        console.log(memberNewsFeeds, "News feeds k members");
        let newsFeedIndex = memberNewsFeeds?.findIndex(
            (entry) => entry.user_id === user.uid
        );
        console.log("got index ", newsFeedIndex);
        const memberResetPoints = calculateRP(waveMembers[waveMember]);
        // code start
        const previousPoints =
            memberNewsFeeds[newsFeedIndex]?.reset_points || 0;
        const createdAt = await getFirebaseTimestamp();
        const newsFeedInstance = {
            reset_points: previousPoints + memberResetPoints,
            user_id: user.uid,
            user_name: user.name,
            userNameInsensitive: user.userNameInsensitive,
            user_profile_photo: user.profile_photo_url,
            sector: parseInt(sector),
            createdAt: createdAt,
        };
        console.log(
            newsFeedIndex,
            "news feeds index in reset points of user",
            waveMember
        );
        // if (newsFeedIndex === -1 || !newsFeedIndex) {
        //     memberNewsFeeds.push(newsFeedInstance);
        // } else {
        //     memberNewsFeeds[newsFeedIndex] = newsFeedInstance;
        // }
        if (newsFeedIndex >= 0) {
            memberNewsFeeds[newsFeedIndex].reset_points =
                newsFeedInstance.reset_points;
            memberNewsFeeds[newsFeedIndex].createdAt = createdAt;
        } else {
            memberNewsFeeds.push(newsFeedInstance);
        }
        console.log(memberNewsFeeds, "memberNewsFeeds");
        await updateDoc(memberRef, {
            newsFeed: memberNewsFeeds,
            resetPoints: increment(memberResetPoints),
            [`sectorPoints.${sector}`]: increment(memberResetPoints),
        });
        // code end
        // await updateDoc(memberRef, {resetPoints: increment(memberResetPoints)});
        await makeLeaderboard(
            waveMember,
            memberResetPoints,
            memberData.name,
            memberData.userNameInsensitive,
            memberData.profile_photo_url,
            memberData.cover_photo_url,
            memberData.location
        );
    }
};

const calculateRP = (level) => {
    const sector = determineSector(level);
    if (!sector) return 0;

    const sectorConfig = pointsConfig.sectors[sector];
    return sectorConfig.levels[level].reset_points;
};

// Temporary function, we will add this in cloud function in future
export const updateLeaderboardDate = async () => {
    const docRef = doc(db, "config", "meta");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const meta = docSnap.data();
        let today = moment();
        let previousReset = today.clone().day(meta.leaderboard_reset_test.day);
        if (previousReset.isAfter(today, "day")) {
            // If reset day is in the future, move to the previous week
            previousReset.subtract(7, "days");
        }
        let nextReset = previousReset.clone().add(7, "days");

        // Set time to 12 AM for both dates
        previousReset.hour(0).minute(0).second(0);
        nextReset.hour(0).minute(0).second(0);

        // Convert to Firebase Timestamps
        const previousResetTimestamp = Timestamp.fromDate(
            previousReset.toDate()
        );
        const nextResetTimestamp = Timestamp.fromDate(nextReset.toDate());

        // console.log(previousResetTimestamp, "Previous");
        // console.log(nextResetTimestamp, "Next");
        await updateDoc(docRef, {
            leaderboard_reset: {
                nextReset: nextResetTimestamp,
                updatedAt: previousResetTimestamp,
            },
        });
        // console.log("Meta ka next reset", meta.leaderboard_reset.nextReset);
        // console.log("My next reset", nextResetTimestamp);
        if (
            meta.leaderboard_reset.nextReset.seconds <
            nextResetTimestamp.seconds
        ) {
            console.log("mirating leaderboard");
            await migrateLeaderboard();
        }
    } else {
        console.log("Document does not exist");
    }
};

export const migrateLeaderboard = async () => {
    try {
        // Step 1: Delete all documents from the "previous-leaderboard" collection
        const previousLeaderboardCollection = collection(
            db,
            "previous-leaderboard"
        );
        const previousLeaderboardQuery = query(previousLeaderboardCollection);
        const previousLeaderboardDocs = await getDocs(previousLeaderboardQuery);

        for (const previousLeaderboardDoc of previousLeaderboardDocs.docs) {
            await deleteDoc(previousLeaderboardDoc.ref);
        }

        // Step 2: Migrate documents from "leaderboard" to "previous-leaderboard"
        const leaderboardCollection = collection(db, "leaderboard");
        const leaderboardQuery = query(leaderboardCollection);
        const leaderboardDocs = await getDocs(leaderboardQuery);

        for (const leaderboardDoc of leaderboardDocs.docs) {
            const data = leaderboardDoc.data();
            const previousLeaderboardDocRef = doc(
                previousLeaderboardCollection,
                leaderboardDoc.id
            );
            await setDoc(previousLeaderboardDocRef, data);
        }

        // Step 3: Delete all documents from the "leaderboard" collection
        for (const leaderboardDoc of leaderboardDocs.docs) {
            await deleteDoc(leaderboardDoc.ref);
        }

        // Step 4: Reseting the news feeds of all users from "users" collection
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);

        for (const userDoc of usersSnapshot.docs) {
            const userRef = doc(db, "users", userDoc.id);

            // Update the newsFeed array to be empty
            await updateDoc(userRef, {
                newsFeed: [],
            });
        }

        console.log("NewsFeed arrays cleared for all users.");

        console.log("Migration completed successfully.");
    } catch (error) {
        console.error("Error migrating leaderboard:", error);
    }
};

export const validateUserName = async (_username) => {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("userName", "==", _username));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length > 0;
};

export const getMetaData = async () => {
    const metaRef = doc(db, "config", "meta");
    const metaSnap = await getDoc(metaRef);
    if (metaSnap.exists()) {
        return metaSnap.data();
    }
};
export const distributeEP = async (user) => {
    console.log(user);
    try {
        const meta = await getMetaData();
        const points = meta?.sponsored_ads_points || 0;
        console.log(points);
        const userRef = doc(db, "users", user.uid);
        updateDoc(userRef, {
            engagement_points: increment(points),
        }).then(async () => {
            await makeLeaderboard(
                user.uid,
                points,
                user.name,
                user.userNameInsensitive,
                user.profile_photo_url,
                user.cover_photo_url,
                user.location
            );
        });
    } catch (error) {
        console.log("Error in distributing EP", error);
    }
};
export const deletePlaylist = async (
    playlistId,
    playlistCloneId,
    activeUser
) => {
    console.log(playlistId);

    try {
        // Reference to the videos and playlists collection
        const videosCollectionRef = collection(db, "videos");
        const playlistsCollectionRef = collection(db, "playlists");
        const postsCollectionRef = collection(db, "posts");

        // Query to find all videos with the given playlistId
        const videoQuery = query(
            videosCollectionRef,
            where("playlist_id", "==", playlistId)
        );

        // Fetching the videos
        const videosSnapshot = await getDocs(videoQuery);

        // Deleting each video
        for (const doc of videosSnapshot.docs) {
            await deleteDoc(doc.ref);
        }
        // Query to find all posts with the given playlistId
        const postsQuery = query(
            postsCollectionRef,
            where("playlistId", "==", playlistId)
        );

        // Fetching the videos
        const postsSnapshot = await getDocs(postsQuery);

        // Deleting each video
        for (const doc of postsSnapshot.docs) {
            await deleteDoc(doc.ref);
        }

        // Deleting the playlist
        const playlistRef = doc(playlistsCollectionRef, playlistId);
        await deleteDoc(playlistRef);
        console.log(
            `Playlist and its videos deleted successfully: ${playlistId}`
        );

        // Update library playlist remove user into clonedBy
        if (playlistCloneId && activeUser) {
            const libPlaylistRef = doc(playlistsCollectionRef, playlistCloneId);
            const playlistSnapshot = await getDoc(libPlaylistRef);
            const listData = { ...playlistSnapshot.data() };
            console.log("listData", listData);
            await updateDoc(libPlaylistRef, {
                clonedBy: listData.clonedBy.filter(
                    (id) => id !== activeUser.uid
                ),
            });
        }
        return true;
    } catch (error) {
        console.error(`Error deleting playlist: ${error}`);
        return true;
    }
};
export const deletePost = async (postId) => {
    console.log(postId);
    const docRef = doc(db, "posts", postId);
    await deleteDoc(docRef);
};

export const fetchDocumentsForSectorMember = async (
    collectionName,
    user_id,
    level
) => {
    try {
        const yourCollection = collection(db, collectionName);
        const q = query(
            yourCollection,
            where(`wave_members.${user_id}`, "!=", null)
        );
        const querySnapshot = await getDocs(q);
        const documents = [];
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
        });

        return documents;
    } catch (error) {
        console.error("Error fetching documents:", error.message);
        throw error;
    }
};

const generateNumberArray = (start, end) => {
    const parsedStart = parseInt(start, 10);
    const parsedEnd = parseInt(end, 10);

    return Array.from(
        { length: parsedEnd - parsedStart + 1 },
        (_, index) => parsedStart + index
    );
};

export const waveMembersforSector = (item, currentUser, allMembers) => {
    if (!allMembers || allMembers.length === 0) {
        return [];
    }

    let sectorLevel = [];
    let highestLevel = 0;

    if (item.sector === 10) {
        highestLevel = Math.max(
            ...allMembers.map((obj) => obj.wave_members[currentUser.uid])
        );
        console.log("highestLevel", highestLevel);
        sectorLevel = highestLevel > 101 ? ["101", highestLevel] : ["101"];
    } else {
        const { waveLevels } = item;
        const findLevel = waveLevels.split("-");
        sectorLevel =
            findLevel.length > 1
                ? generateNumberArray(findLevel[0], findLevel[1])
                : [parseInt(findLevel[0])];
    }

    return allMembers.filter((f) =>
        sectorLevel.includes(f.wave_members[currentUser.uid])
    );
};

export const formatLargeNumber = (num) => {
    if (!num) return 0;
    const value = parseInt(num);
    if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "m";
    } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "k";
    } else {
        return value.toString();
    }
};

export const getUniqueArray = (arr, property) => {
    const seen = new Set();
    return arr.filter((obj) => {
        const value = obj[property];
        if (!seen.has(value)) {
            seen.add(value);
            return true;
        }
        return false;
    });
};

export const getDefaultCrop = (
    imageWidth,
    imageHeight,
    aspectRatio = 1,
    positionCenter
) => {
    let cropWidth, cropHeight;

    if (imageWidth / aspectRatio < imageHeight) {
        // If the maximum width with the given aspect fits inside the image
        cropWidth = imageWidth;
        cropHeight = imageWidth / aspectRatio;
    } else {
        // If the maximum height with the given aspect fits inside the image
        cropHeight = imageHeight;
        cropWidth = imageHeight * aspectRatio;
    }

    // Center the crop area
    const x = positionCenter ? (imageWidth - cropWidth) / 2 : 0;
    const y = positionCenter ? (imageHeight - cropHeight) / 2 : 0;

    return {
        x: x,
        y: y,
        width: cropWidth,
        height: cropHeight,
        unit: "px",
    };
};

export const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    const imageResolution = image.naturalWidth * image.naturalHeight;

    const highResolutionThreshold = 1000000;
    const pixelRatio =
        imageResolution > highResolutionThreshold ? 1 : window.devicePixelRatio;

    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );

    console.log("canvas", canvas);
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                reject(new Error("Canvas is empty"));
                return;
            }

            // Create a File object from the Blob
            const file = new File([blob], "croppedImage.png", {
                type: "image/png",
            });
            resolve(file);
        }, "image/png");
    });
};

export const removeSpecialCharacters = (inputString) => {
    // Use a regular expression to replace any character that is not a letter, number, underscore, or space with an empty string
    if (!inputString || !inputString.trim()) {
        return "";
    }
    return inputString.replace(/[^a-zA-Z0-9_.]/g, "");
};
