import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Spinner,
} from "reactstrap";
import usFlag from "../../assets/images/viewTrakr/usa-flag.png";
import hawaiFlag from "../../assets/images/viewTrakr/hawai-flag.png";
import {distributeRP} from "../../util/helper";
import {collection, doc, getDoc, getDocs, query} from "firebase/firestore";
import {db} from "../../util/firebase";

const Home = ({restrictUser, setRefreshUser, refreshUser, signedInUser, appMetaData}) => {
    const [highlight, setHighlight] = useState(true);
    const [loading, setLoading] = useState(null);
    const [users, setUsers] = useState([]);
    const [userPrevpoints, setUserPrevPoints] = useState(0);
    const [refreshList, setRefreshList] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        getUserResults().then(result => {
            if (result !== false) {
                setUserPrevPoints(result.points);
            }
        })
    }, []);

    useEffect(() => {
        setLoading('initial');
        setUsers([]);
        getCurrentUsers().then(_users => {
            console.log(_users, 'users in leaderboard');
            setUsers(_users);
            setLoading('ready');
        })
    }, [highlight, refreshList]);

    const getCurrentUsers = async () => {
        try {
            const collectionRef = collection(db, `${highlight === true ? 'leaderboard' : 'previous-leaderboard'}`);
            const q = query(collectionRef);
            let data = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                console.log(doc.id);
                const docData = doc.data();
                if (docData.points && docData.points > 0) {
                    data.push({
                        id: doc.id,
                        ...docData
                    });
                }
            });
            data.sort((a, b) => b.points - a.points);
            return data;
        } catch (error) {
            console.log(error);
        }
    };
    const handleHighlight = () => {
        setHighlight(!highlight);
    };
    const getUserResults = async () => {
        const docRef = doc(db, "previous-leaderboard", signedInUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else
            return false;
    }

    return (
        <Container style={{height: "81vh", overflowY: "scroll"}}>
            <div className="text-center col-21 mb-4">
                <label htmlFor="outline" title="Change" className="css-1utwwx4">
                    <input
                        id="control-outline"
                        type="checkbox"
                        name="outline"
                        onChange={handleHighlight}
                    />
                    <span className={highlight ? 'highlight' : ''}>CURRENT</span>
                    <span className={highlight ? '' : 'highlight'}>PREVIOUS</span>
                </label>
                {/*<div>*/}
                {/*    <UncontrolledDropdown className="mx-2">*/}
                {/*        <DropdownToggle caret></DropdownToggle>*/}
                {/*        /!*<DropdownMenu dark>*!/*/}
                {/*        /!*  <DropdownItem header>Header</DropdownItem>*!/*/}
                {/*        /!*</DropdownMenu>*!/*/}
                {/*    </UncontrolledDropdown>*/}
                {/*</div>*/}
            </div>
            {loading === 'initial' ? <div className="text-center mt-5">
                    <Spinner color={'success'}>Loading...</Spinner>
                </div> :
                users && users.length > 0 ? users.map((e, i) => (
                        <div key={i}>
                            <Card style={{
                                backgroundImage: `url(${e.cover})`,
                                backgroundSize: "cover",
                                position: "relative",
                                backgroundColor: "rgba(0, 0, 0, 1)"
                            }} className="my-4 card1">
                                <CardHeader className=" py-1 mx-auto card1-header">
                                    #{i + 1}
                                </CardHeader>
                                <Row className="my-4 mx-4 g-0" xs={2}>
                                    <Col>
                                        <img
                                            alt="flag"
                                            width={50}
                                            className="image-icon "
                                            src={e.location === 'US' ? usFlag : e.location === 'HI' && hawaiFlag}
                                        />
                                        <img
                                            className="card1-img"
                                            src={e.profile}
                                            style={{height: 140}}
                                            width="100%"
                                        />
                                    </Col>
                                    <Col className="my-auto">
                                        <CardBody className="card1-body">
                                            <CardTitle className="class1-title" tag="h5">
                                                {e.name}
                                            </CardTitle>
                                            <CardSubtitle className="mb-2 card1-subtitle" tag="h6">
                                                @{e.user_name}
                                            </CardSubtitle>
                                            <CardText className="card1-text">{e.points}</CardText>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    )) :
                    <div className="text-center text-white mt-5">
                        <h3>No records</h3>
                    </div>
            }
            {restrictUser &&
                <div className="overlay">
                    <div className="bg-white text-center mb-2 p-4 rounded-4">
                        <h5>Welcome Back!</h5>
                        {userPrevpoints > 0 &&
                            <p>Your score for last week was {userPrevpoints}</p>
                        }
                        <p>Please press the button to play again.</p>
                    </div>
                    <Button disabled={disableButton} className="centered-button py-2" style={{width: "158px"}}
                            onClick={() => {
                                setLoading('reseting');
                                setDisableButton(true);
                                try {
                                    distributeRP(signedInUser).then(() => {
                                        // setTimeout(() => {
                                        setRefreshUser(!refreshUser);
                                        setRefreshList(!refreshList);
                                        // }, 3000);
                                    });
                                } catch (error) {
                                    setLoading('ready');
                                }
                            }}>{loading === 'reseting' ?
                        <Spinner size="sm" type="grow"
                                 color='light'>Loading...</Spinner> : "Go/Continue"}
                    </Button>
                    {/*<Button onClick={migrateLeaderboard}>Test</Button>*/}
                </div>
            }
        </Container>
    );
};

export default Home;
