import React, { useEffect, useRef, useState } from "react";
import logoutIcon from "../assets/images/user/logout-white.png";
import supportIcon from "../assets/images/Footer/support.svg";
import { auth } from "../util/firebase";
import { signOut } from "firebase/auth";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const BottomMenu = ({ isOpen, setIsOpen, iconRef }) => {
    const menuRef = useRef();
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            !iconRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside, true);
        return () => {
            window.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const logoutUser = () => {
        signOut(auth)
            .then(() => {
                console.log("user signed out");
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const toggleModal = () => setIsOpenModal(!isOpenModal);

    const openSupportMail = () => {
        const myEmail = "support@zenduja.com";
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(
            myEmail
        )}`;
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile =
            /(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini)/i.test(
                userAgent
            );
        window.open(isMobile ? `mailto:${myEmail}` : gmailUrl, "_blank");
    };

    return (
        <div>
            {isOpen && <div className="menu-overlay"></div>}

            <div
                ref={menuRef}
                className={`menu bottom-menu ${isOpen ? "open" : ""}`}
            >
                <div
                    className="d-flex align-items-center p-3 border-bottom"
                    onClick={openSupportMail}
                >
                    <img src={supportIcon} alt="" width={30} className="me-2" />
                    <span className="fw-semibold">Support</span>
                </div>
                <div
                    className="d-flex align-items-center p-3 border-bottom"
                    onClick={toggleModal}
                >
                    <img src={logoutIcon} alt="" width={30} className="me-2" />
                    <span className="fw-semibold">Logout</span>
                </div>
            </div>

            <Modal
                isOpen={isOpenModal}
                toggle={toggleModal}
                className="w-100"
                centered
            >
                <ModalHeader className="justify-content-center">
                    Logout
                </ModalHeader>
                <ModalBody className="text-center">
                    <p>Are you sure want to logout ?</p>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {/*<div className="d-flex justify-content-around">*/}
                    <Button
                        color="danger"
                        className="rounded-pill py-2 px-5"
                        onClick={logoutUser}
                    >
                        Yes, Logout
                    </Button>
                    <Button
                        color=""
                        className="save-button"
                        onClick={() => setIsOpenModal(false)}
                    >
                        Cancel
                    </Button>
                    {/*</div>*/}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default BottomMenu;
