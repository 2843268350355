import React from "react";
import { Container } from "reactstrap";
import PostCard from "./postCard";

const GroupsFeed = ({ allFeeds, setRefreshFeeds, refreshFeeds, user }) => {
    return (
        <Container className="px-0">
            {allFeeds &&
                allFeeds.length > 0 &&
                allFeeds.map((feedItem, index) => (
                    <div key={index}>
                        <PostCard
                            post={feedItem}
                            signedInUser={user}
                            refreshFeeds={refreshFeeds}
                            setRefreshFeeds={setRefreshFeeds}
                        />
                    </div>
                ))}
        </Container>
    );
};

export default GroupsFeed;
