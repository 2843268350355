import React from 'react';

const YouTubeThumbnail = ({url, sequence, edit}) => {
    function getThumbnailUrl(youtubeUrl) {
        const videoIdMatch = youtubeUrl.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)/) ||
            youtubeUrl.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([\w-]+)/) ||
            youtubeUrl.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w-]+)/); // Added pattern for YouTube Shorts
        const videoId = videoIdMatch && videoIdMatch[1];

        if (!videoId) {
            return null;
        }

        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }

    const thumbnailUrl = getThumbnailUrl(url);

    return (
        thumbnailUrl &&
        <div className="thumbnail-container">
            <img src={thumbnailUrl} alt="YouTube Thumbnail" className={`${!edit ? 'youtube-thumbnail-image' : 'edit-youtube-thumbnail-image'}`}/>
            {sequence !== null &&
                <h3 className={`${edit ? 'edit-thumbnail-overlay' : 'thumbnail-overlay'}`}>{sequence}</h3>
            }
        </div>
    );
}

export default YouTubeThumbnail;
