import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Form,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Location from "../assets/images/news-feed/location.png";
import UserIcon from "../assets/images/news-feed/menu-dots1.png";
import Like from "../assets/images/news-feed/like.png";
import Liked from "../assets/images/news-feed/liked.png";
import Comment from "../assets/images/news-feed/comment.png";
import Share from "../assets/images/news-feed/share.png";
import { Link, useNavigate } from "react-router-dom";
import { deletePost, printLog } from "../util/helper";
import {
    addDoc,
    collection,
    deleteField,
    doc,
    getDoc,
    getDocs,
    increment,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where,
} from "firebase/firestore";
import { db } from "../util/firebase";
import moment from "moment";

const PostCard = ({ post, signedInUser, refreshFeeds, setRefreshFeeds }) => {
    const navigate = useNavigate();
    const [postLoaded, setPostLoaded] = useState(false);
    const [postLiked, setPostLiked] = useState(false);
    const [currentPost, setCurrentPost] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [loading, setLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        setCurrentPost(post);
        getComments().then((_comments) => {
            setComments(_comments);
        });
        if (post.hasOwnProperty("likes")) {
            const _postliked = checkLikes();
            setPostLiked(_postliked);
        }
    }, []);

    const getComments = async () => {
        const collectionRef = collection(db, "comments");
        const q = query(
            collectionRef,
            where("post_id", "==", post.id),
            orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        let commentsArray = [];
        querySnapshot.forEach((doc) => {
            commentsArray.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        return commentsArray;
    };
    const checkLikes = () => {
        const likers = Object.keys(post?.likes);
        return likers.includes(signedInUser.uid);
    };
    const likePost = async () => {
        try {
            const docRef = doc(db, "posts", post.id);
            let updatedLikes = { ...(currentPost.likes || {}) };

            if (updatedLikes[signedInUser.uid]) {
                await updateDoc(docRef, {
                    likesCount: increment(-1),
                    [`likes.${signedInUser.uid}`]: deleteField(),
                });

                delete updatedLikes[signedInUser.uid];
                setPostLiked(false);
            } else {
                await updateDoc(docRef, {
                    likesCount: increment(1),
                    [`likes.${signedInUser.uid}`]: signedInUser.uid,
                });

                updatedLikes[signedInUser.uid] = signedInUser.uid;
                setPostLiked(true);
            }

            // Update local state
            setCurrentPost({
                ...currentPost,
                likes: updatedLikes,
                likesCount: postLiked
                    ? currentPost.likesCount - 1
                    : currentPost.likesCount + 1,
            });
        } catch (error) {
            printLog(error, "Error on liking the post");
        }
    };
    const navigateToPost = (post) => {
        if (post.type === "playlist") {
            navigate(`/viewTrakr/${post.playlistId}`);
        }
    };
    const toggle = () => setIsOpen(!isOpen);
    const addComment = async (e) => {
        e.preventDefault();
        if (comment) {
            const collectionRef = collection(db, "comments");
            const commentData = {
                post_id: post.id,
                user_id: signedInUser.uid,
                user_name: signedInUser.userNameInsensitive,
                comment: comment,
                createdAt: serverTimestamp(),
            };
            try {
                const docRef = await addDoc(collectionRef, commentData);
                // Fetch the newly added comment from Firebase to get the actual timestamp
                const newCommentSnapshot = await getDoc(docRef);
                const newCommentData = {
                    ...newCommentSnapshot.data(),
                    id: newCommentSnapshot.id,
                };
                setComments([newCommentData, ...comments]);
                setIsOpen(true);
                setComment("");
            } catch (error) {
                printLog(error, "Error in commenting on post");
            }
        }
    };
    const formatTime = (timestamp) => {
        // Check if the timestamp is valid
        if (timestamp && timestamp.seconds) {
            const millisecondsFromSeconds = timestamp.seconds * 1000;
            const millisecondsFromNanoseconds = timestamp.nanoseconds
                ? timestamp.nanoseconds / 1000000
                : 0;
            const totalMilliseconds =
                millisecondsFromSeconds + millisecondsFromNanoseconds;
            return moment(totalMilliseconds).format("YYYY-MM-DD HH:mm");
        }
        return "Just now"; // Placeholder text for comments that are still waiting for a real timestamp
    };
    const toggleDropdown = (postId) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [postId]: !prevState[postId],
        }));
    };
    const deleteMyPost = async (postId) => {
        setIsOpenModal(false);
        setLoading(true);
        await deletePost(postId);
        setLoading(false);
        setRefreshFeeds(!refreshFeeds);
    };
    const toggleModal = () => setIsOpenModal(!isOpenModal);

    return (
        <>
            <Card className=" mx-auto feed-card my-5">
                <CardHeader
                    style={{
                        borderBottom: "none",
                    }}
                >
                    <Row>
                        <Col className="text-center " xs={2}>
                            <Link
                                to={
                                    post.groupName
                                        ? `/group/${post.groupId}`
                                        : `/${post.user_name.toLowerCase()}`
                                }
                            >
                                <img
                                    className="rounded-circle"
                                    src={
                                        post.group_profile_photo ||
                                        post.user_profile_photo
                                    }
                                    width={50}
                                    height={50}
                                    alt=""
                                />
                            </Link>
                        </Col>
                        <Col className="text-start" xs={7}>
                            {post.groupName && (
                                <p
                                    className="user-name mb-0"
                                    onClick={() =>
                                        navigate(`/group/${post.groupId}`)
                                    }
                                >
                                    {post.groupName}
                                </p>
                            )}
                            <p
                                className={`user-name mb-2 ${
                                    post.groupName && "text-secondary fs-12"
                                }`}
                                onClick={() =>
                                    post.groupName &&
                                    navigate(
                                        `/${post.user_name?.toLowerCase()}`
                                    )
                                }
                            >
                                @{post.user_name}
                            </p>
                            <div className="location-div">
                                <img
                                    className="location-img"
                                    src={Location}
                                    alt=""
                                />
                                <p className="user-locaton mb-0">
                                    {post.location}
                                </p>
                            </div>
                        </Col>
                        <Col className="text-end pt-1 pe-3" xs={3}>
                            {post.user_id === signedInUser.uid && (
                                <Dropdown
                                    isOpen={dropdownOpen[post.id]}
                                    toggle={() => toggleDropdown(post.id)}
                                >
                                    <DropdownToggle className="bg-transparent border-0 p-0">
                                        {loading ? (
                                            <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                        ) : (
                                            <img src={UserIcon} alt="" />
                                        )}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={toggleModal}>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="pt-0 text-start">
                    <div className="text-center">
                        {post.type === "image" || post.type === "playlist" ? (
                            <img
                                className="post-img mx-auto"
                                src={post.sourceUrl}
                                alt=""
                                style={{
                                    filter: postLoaded ? "none" : "blur(10px)", // 5px is the blur amount, adjust as needed
                                    transition: "filter 0.3s", // Smooth transition when removing blur
                                    height: "100%",
                                    width: "100%",
                                }}
                                onClick={() => navigateToPost(post)}
                                onLoad={() => setPostLoaded(true)}
                            />
                        ) : (
                            post.type === "video" && (
                                <video
                                    className="mx-auto d-block"
                                    height={224}
                                    width="100%"
                                    controls
                                    src={post.sourceUrl}
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )
                        )}
                    </div>
                    <Row className="my-3 px-2">
                        <Col className="px-0 d-flex" xs={6}>
                            <div className="action-col px-2">
                                <img
                                    className="like-img mx-2"
                                    src={postLiked ? Liked : Like}
                                    alt=""
                                    onClick={likePost}
                                />
                                <p className="mb-0">
                                    {currentPost.likesCount &&
                                    currentPost.likesCount > 0
                                        ? currentPost.likesCount
                                        : ""}
                                </p>
                            </div>
                            <div className="action-col px-2">
                                <img
                                    className="like-img mx-2"
                                    src={Comment}
                                    alt=""
                                />
                                <p className="mb-0">
                                    {currentPost.commentsCount}
                                </p>
                            </div>
                        </Col>
                        <Col style={{ textAlign: "end" }} xs={6}>
                            <img src={Share} alt="" />
                        </Col>
                    </Row>
                    <CardTitle className="post-sub mb-0">
                        <span className=" mx-1 post-comments">
                            @{post.user_name}
                        </span>
                        {post.description}
                    </CardTitle>

                    {comments.length > 0 && (
                        <>
                            <Button
                                color="link"
                                onClick={toggle}
                                className="fs-14 ps-0 "
                            >
                                View Comments
                            </Button>
                            <Collapse isOpen={isOpen}>
                                <div>
                                    {comments.map((_comment, index) => (
                                        <div key={index}>
                                            <CardTitle className="mb-1 post-sub comment-container">
                                                <span className="post-comments">
                                                    @{_comment.user_name}:{" "}
                                                    {_comment.comment}
                                                </span>
                                                {/*        <span className="comment-time">*/}
                                                {/*    {formatTime(_comment.createdAt)}*/}
                                                {/*</span>*/}
                                            </CardTitle>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </>
                    )}

                    {/*<CardTitle className="mx-2 px-1 mb-2  post-sub">*/}
                    {/*    <img className="post-bar" src={Bar} alt=""/>*/}
                    {/*    <span className=" post-comments mx-1">@TheOne</span>*/}
                    {/*    It has a great color!*/}
                    {/*</CardTitle>*/}
                    {/*<CardTitle className="mx-4 px-1 mb-2  post-sub">*/}
                    {/*    <img className="post-bar" src={Bar} alt=""/>*/}
                    {/*    <span className=" post-comments mx-1">@LucianoSweat</span>*/}
                    {/*    Thank you*/}
                    {/*</CardTitle>*/}
                    <div>
                        <Form onSubmit={addComment}>
                            <Row className="Message mx-1 align-items-center mt-2">
                                <Col xs={10}>
                                    <input
                                        title="Add Comment"
                                        required
                                        value={comment}
                                        onChange={(e) => {
                                            setComment(e.target.value);
                                        }}
                                        placeholder="Add Comment"
                                        className="MsgInput"
                                        type="text"
                                    />
                                </Col>
                                <Col xs={2}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.0"
                                        width="30.000000pt"
                                        height="30.000000pt"
                                        viewBox="0 0 30.000000 30.000000"
                                        type="submit"
                                        preserveAspectRatio="xMidYMid meet"
                                        className="SendSVG"
                                        onClick={addComment}
                                    >
                                        <g
                                            transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                                            fill="#ffffff70"
                                            stroke="none"
                                        >
                                            <path d="M44 256 c-3 -8 -4 -29 -2 -48 3 -31 5 -33 56 -42 28 -5 52 -13 52 -16 0 -3 -24 -11 -52 -16 -52 -9 -53 -9 -56 -48 -2 -21 1 -43 6 -48 10 -10 232 97 232 112 0 7 -211 120 -224 120 -4 0 -9 -6 -12 -14z"></path>
                                        </g>
                                    </svg>
                                </Col>
                                {/*<span class="l"></span>*/}
                            </Row>
                        </Form>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={isOpenModal} toggle={toggleModal} className="w-100">
                <ModalHeader className="justify-content-center">
                    Delete Post!
                </ModalHeader>
                <ModalBody className="text-center">
                    <p>
                        Are you want to delete the post ? <br /> You wont be
                        able to revert this
                    </p>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {/*<div className="d-flex justify-content-around">*/}
                    <Button
                        color="danger"
                        className="rounded-pill py-2 px-5"
                        onClick={() => deleteMyPost(post.id)}
                    >
                        Yes, Delete
                    </Button>
                    <Button
                        color=""
                        className="save-button"
                        onClick={() => setIsOpenModal(false)}
                    >
                        Cancel
                    </Button>
                    {/*</div>*/}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default PostCard;
