import React, { useEffect, useState } from "react";
import "../assets/styles/ViewTrakr.css";
import { useNavigate, useParams } from "react-router-dom";
import PlaylistView from "../components/playlistView";
import Public from "../components/Public";
import ViewTrakrPlaylist from "../components/ViewTrakrPlaylist";
import { db } from "../util/firebase";
import { doc, getDoc } from "firebase/firestore";
import { printLog } from "../util/helper";

const Playlist = ({
    signedInUser,
    callAlert,
    setPostFile,
    refreshUser,
    setRefreshUser,
    userPoints,
    appMetaData,
    isLoggedIn,
}) => {
    const [playlist, setPlaylist] = useState();
    const { pid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (pid) {
            getPlaylist(pid)
                .then((data) => {
                    if (data) {
                        setPlaylist(data);
                    } else {
                        navigate(`/auth`);
                    }
                })
                .catch((err) => {
                    printLog(err);
                    navigate(`/auth`);
                });
        }
    }, [pid]);

    const getPlaylist = async (pid) => {
        try {
            const docRef = doc(db, "playlists", pid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return {
                    ...docSnap.data(),
                    clone_playlist_id: docSnap.id,
                    id: pid,
                };
            }
        } catch (error) {
            printLog(error, "Error in getting playlist");
        }
    };
    console.log("playlist", playlist);
    return (
        <>
            {!isLoggedIn && playlist ? (
                <Public
                    isLoggedIn={isLoggedIn}
                    signedInUser={signedInUser}
                    callAlert={callAlert}
                    pid={playlist?.id}
                />
            ) : isLoggedIn &&
              playlist &&
              playlist.user_id !== signedInUser.uid &&
              !signedInUser.newUser ? (
                <PlaylistView
                    callAlert={callAlert}
                    refreshUser={refreshUser}
                    signedInUser={signedInUser}
                    appMetaData={appMetaData}
                    setRefreshUser={setRefreshUser}
                    pid={playlist?.id}
                />
            ) : (
                <>
                    {isLoggedIn &&
                        !signedInUser.newUser &&
                        playlist &&
                        playlist.user_id === signedInUser.uid && (
                            <ViewTrakrPlaylist
                                setPostFile={setPostFile}
                                appMetaData={appMetaData}
                                userPoints={userPoints}
                                refreshUser={refreshUser}
                                setRefreshUser={setRefreshUser}
                                callAlert={callAlert}
                                signedInUser={signedInUser}
                                pid={playlist?.id}
                            />
                        )}
                </>
            )}
        </>
    );
};

export default Playlist;
