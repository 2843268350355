import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UserFooter from "../components/UserFooter";
import Header from "../components/Header";
import { Container } from "reactstrap";
import routes from "../routes";
import "./../assets/styles/user.css";

const User = ({
    signedInUser,
    setSignedInUser,
    callAlert,
    postFile,
    setPostFile,
    userPoints,
    appMetaData,
    refreshUser,
    setRefreshUser,
}) => {
    const getRoutes = (_routes) => {
        console.log(_routes);
        return _routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                element={
                    <route.component
                        callAlert={callAlert}
                        signedInUser={signedInUser}
                        postFile={postFile}
                        refreshUser={refreshUser}
                        setRefreshUser={setRefreshUser}
                        setPostFile={setPostFile}
                        setSignedInUser={setSignedInUser}
                    />
                }
                // exact
            />
        ));
    };
    // <Route
    // key={index}
    // path={route.path}
    // element={<route.component mainContentHeight={mainContentValue} hfHeight={hfHeight}
    //                           signedInUser={signedInUser}/>}

    return (
        <div className={`contains auto-height}`}>
            <Container>
                <Header
                    signedInUser={signedInUser}
                    userPoints={userPoints}
                    headerLogo=""
                    appMetaData={appMetaData}
                />
            </Container>
            <Routes>
                {getRoutes(routes.user)}
                <Route path="*" element={<Navigate to="/user/news-feed" />} />
            </Routes>
            <UserFooter
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
                signedInUser={signedInUser}
                callAlert={callAlert}
            />
        </div>
    );
};

export default User;
