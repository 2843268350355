import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import library_icon from "../../assets/images/viewTrakr/library-icon.png";
import MyPages from "../../components/MyPages";
import Library from "../../components/Library";
import SubHeader from "../../components/SubHeader";
import addPlaylist from "../../assets/images/viewTrakr/add-btn.png";
import posts_icon_active from "../../assets/images/viewTrakr/wall-icon-active.svg";
import posts_icon_inactive from "../../assets/images/viewTrakr/wall-icon-inactive.svg";
import playlist_icon_active from "../../assets/images/viewTrakr/playlist-icon-active.svg";
import playlist_icon_inactive from "../../assets/images/viewTrakr/playlist-icon-inactive.svg";
import full_circle from "../../assets/images/viewTrakr/stats-circle2.svg";
import us_flag from "../../assets/images/viewTrakr/usa-flag.png";
import hawai_flag from "../../assets/images/viewTrakr/hawai-flag.png";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane,
} from "reactstrap";
import AllPosts from "../../components/allPosts";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    increment,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { db } from "../../util/firebase";
import { formatLargeNumber, printLog } from "../../util/helper";
import GroupsFeed from "../../components/GroupsFeed";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import CropImage from "../../components/CropImage";

const HomePage = (props) => {
    const activeUser = props.signedInUser;
    const navigate = useNavigate();
    const { group_id } = useParams();
    const [currentGroup, setCurrentGroup] = useState({});
    const [cropImage, setCropImage] = useState(null);
    const [loading, setLoading] = useState("initial");
    const [activeTab1, setActiveTab1] = useState("2");
    const [refreshFeeds, setRefreshFeeds] = useState(false);
    const [allFeeds, setAllFeeds] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState({ isOpen: false });
    const [coverLoaded, setCoverLoaded] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const location = useLocation();
    console.log({ allFeeds });

    useEffect(() => {
        getGroup(group_id)
            .then((_group) => {
                setCurrentGroup(_group);
                setLoading("ready");
            })
            .catch((err) => {
                printLog(err);
                navigatePage("/");
            });
    }, [group_id]);

    const isJoinedGroup = activeUser.joinedGroups?.[currentGroup.id];

    useEffect(() => {
        let tempFeeds = [];

        getGroupPosts(group_id).then((result) => {
            tempFeeds.push(...(result ? result : []));
            tempFeeds.sort((a, b) => b.createdAt - a.createdAt);
            setAllFeeds([...tempFeeds]);
            setLoading("ready");
        });
    }, [refreshFeeds, group_id]);

    const getGroupPosts = async (group_id) => {
        let fetchedPosts = [];
        // Query for logged-in user's posts
        const postsQuery = query(
            collection(db, "posts"),
            where("groupId", "==", group_id),
            orderBy("createdAt", "desc")
        );
        const postsSnapshot = await getDocs(postsQuery);
        postsSnapshot.forEach((doc) =>
            fetchedPosts.push({ id: doc.id, ...doc.data() })
        );
        return fetchedPosts;
    };

    const getGroup = async (group_id) => {
        if (!group_id) return {};
        const docRef = doc(db, "groups", group_id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), id: group_id };
        }
        throw new Error();
    };

    const navigatePage = (route) => {
        navigate(`${route}`);
    };

    const handleCallBackImage = (crpImg, type) => {
        console.log("crpImage", crpImg);
        switch (type) {
            case "postImage":
                props.setGroupPostFile({
                    file: crpImg,
                    group: currentGroup,
                });
                navigate("/add-new-post");
                break;
        }
    };

    const joinUser = async () => {
        let joinGroups = { ...(activeUser.joinedGroups || {}) };
        if (!isJoinedGroup) {
            Object.assign(joinGroups, { [`${group_id}`]: group_id });

            try {
                setLoading("following");
                const activeRef = doc(db, "users", activeUser.uid);
                const groupRef = doc(db, "groups", group_id);

                updateDoc(activeRef, {
                    joinedGroups: joinGroups,
                }).then(() => {
                    props.setRefreshUser(!props.refreshUser);
                    setLoading("ready");
                });
                updateDoc(groupRef, { memberCounts: increment(1) }).then(
                    (res) => {
                        console.log("Group", res);
                    }
                );
                setCurrentGroup({
                    ...currentGroup,
                    memberCounts: currentGroup.memberCounts + 1,
                });
            } catch (error) {
                printLog(error, "Error while follow");
            }
        } else {
            setOpenConfirmation({
                isOpen: true,
                title: "Leave Group",
                description: `Are you sure ? 
               \n
                 You want to leave this group`,
            });
        }
    };

    const FollowButton = () => (
        <Button
            onClick={joinUser}
            disabled={loading === "following"}
            className={`w-100 ${
                isJoinedGroup ? "following-button" : "follow-button"
            }`}
        >
            {loading === "following" ? (
                <Spinner size="sm" type="grow" color="light">
                    Loading...
                </Spinner>
            ) : !isJoinedGroup ? (
                "Join Group"
            ) : (
                "Leave Group"
            )}
        </Button>
    );

    const handleConfirm = async () => {
        setOpenConfirmation({ isOpen: false });
        try {
            let joinGroups = { ...(activeUser.joinedGroups || {}) };
            setLoading("following");
            const activeRef = doc(db, "users", activeUser.uid);
            const groupRef = doc(db, "groups", group_id);
            delete joinGroups[`${group_id}`];
            updateDoc(activeRef, {
                joinedGroups: joinGroups,
            }).then(() => {
                props.setRefreshUser(!props.refreshUser);
                setLoading("ready");
            });
            updateDoc(groupRef, { memberCounts: increment(-1) }).then((res) => {
                console.log("Group", res);
            });
            setCurrentGroup({
                ...currentGroup,
                memberCounts: currentGroup.memberCounts - 1,
            });
        } catch (error) {
            printLog(error, "Error while follow");
        }
    };

    return loading === "initial" ? (
        <div className="text-center mt-5">
            <Spinner color={"success"}>Loading...</Spinner>
        </div>
    ) : (
        <div className="main">
            <div className="text-center text-secondary">
                <Container>
                    <div
                        className="profile-cover"
                        style={{
                            backgroundImage: `url('${currentGroup.cover_photo_url}')`,
                            filter: coverLoaded ? "none" : "blur(15px)",
                            transition: "filter 0.3s",
                        }}
                    >
                        <img
                            src={currentGroup.cover_photo_url}
                            onLoad={() => setCoverLoaded(true)}
                            alt="Cover Preloading"
                            style={{
                                position: "absolute",
                                top: "-9999px",
                                height: "1px",
                                width: "1px",
                                opacity: "0",
                            }}
                        />
                        <div className="profile-tag-container d-flex align-items-center">
                            <div className="profile-tag rounded-circle">
                                <img
                                    src={currentGroup.profile_photo_url}
                                    onLoad={() => setProfileLoaded(true)}
                                    style={{
                                        filter: profileLoaded
                                            ? "none"
                                            : "blur(5px)", // 5px is the blur amount, adjust as needed
                                        transition: "filter 0.3s", // Smooth transition when removing blur
                                    }}
                                    width="100%"
                                    height="100%"
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="text-start ms-3 text-white">
                                <div className="fw-bold w-100">
                                    {currentGroup.name}
                                </div>
                                <div className="fs-14">
                                    {" "}
                                    {isJoinedGroup ? "Your Group" : "Group"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end px-3">
                        <div className="d-flex justify-content-center my-2 fs-12 align-items-center text-white ">
                            <img
                                src={full_circle}
                                alt=""
                                width={20}
                                className="me-1"
                            />
                            <span>
                                {formatLargeNumber(
                                    currentGroup?.memberCounts || 0
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="px-4 my-3">
                        <FollowButton />
                    </div>

                    {isJoinedGroup && (
                        <GroupsFeed
                            allFeeds={allFeeds}
                            user={activeUser}
                            setRefreshFeeds={setRefreshFeeds}
                            refreshFeeds={refreshFeeds}
                        />
                    )}
                </Container>
                {isJoinedGroup && (
                    <label className="add-post-button" role="button">
                        <img src={addPlaylist} alt="" width={60} />
                        <input
                            type="file"
                            className="d-none"
                            accept="image/*,video/*"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file && file.size > 20 * 1024 * 1024) {
                                    // File size is in bytes, so 10 * 1024 * 1024 is 10MB
                                    props.callAlert(
                                        "fail",
                                        "Please select file less than 20mb"
                                    );
                                    e.target.value = ""; // Clear the input
                                    return;
                                }
                                if (
                                    file.type.includes("video") ||
                                    file.type.includes("gif")
                                ) {
                                    props.setGroupPostFile({
                                        file: e.target.files[0],
                                        group: currentGroup,
                                    });
                                    navigate("/add-new-post");
                                } else if (file.type.includes("image")) {
                                    setCropImage({
                                        img: URL.createObjectURL(file),
                                        type: "postImage",
                                        aspectRatio: 1,
                                    });
                                } else {
                                    props.callAlert(
                                        "fail",
                                        "Please select valid file"
                                    );
                                }
                            }}
                        />
                    </label>
                )}
                <ConfirmationPopup
                    isOpenModal={openConfirmation.isOpen}
                    title={openConfirmation.title}
                    description={openConfirmation.description}
                    handleClose={() => setOpenConfirmation({ isOpen: false })}
                    handleConfirm={handleConfirm}
                />
                {cropImage && (
                    <CropImage
                        cropImage={cropImage}
                        handleClose={() => setCropImage(null)}
                        aspectRatio={cropImage.aspectRatio}
                        handleCallBackImage={handleCallBackImage}
                    />
                )}
            </div>
        </div>
    );
};

export default HomePage;
