import HomePage from "../views/groups/Home";

const routes = [
    {
        path: "/:group_id",
        name: "Home",
        component: HomePage,
        layout: "/group",
    },
];

export default routes;
