import SectorView from "../views/myWave/SectorView";

const routes = [
  {
    path: "/:user_id",
    name: "Sector",
    component: SectorView,
    layout: "/mywave",
  },
];

export default routes;
