import React, { useEffect, useState, useRef } from "react";
import { Container } from "reactstrap";
import leaderboard_icon from "../assets/images/Footer/leaderboard.svg";
import homeIcon from "../assets/images/Footer/home-icon.svg";
import searchIcon from "../assets/images/Footer/search-icon.svg";
import menuIcon from "../assets/images/Footer/menu.svg";
import viewTrakrIcon from "../assets/images/Footer/viewTrakr.svg";
import { useNavigate } from "react-router-dom";
import BottomMenu from "./bottomMenu";

const Footer = ({
    reference,
    refreshUser,
    setRefreshUser,
    signedInUser,
    callAlert,
}) => {
    const navigate = useNavigate();
    const iconRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const navigatePage = (route) => {
        console.log(signedInUser, "User in footer");
        setRefreshUser(!refreshUser);
        if (route) {
            if (signedInUser?.newUser) {
                callAlert("fail", "Please complete your profile");
            } else {
                navigate(`/${route}`);
            }
        }
    };
    const toggleMenu = (event) => {
        setIsOpen(!isOpen);
    };
    return (
        <div
            className="d-flex align-items-center fixed-bottom bg-black"
            style={{ height: "7vh" }}
            ref={reference}
        >
            <Container>
                <div className="d-flex justify-content-around text-secondary align-items-center py-2">
                    <img
                        src={homeIcon}
                        alt=""
                        role="button"
                        onClick={() => navigatePage("user/news-feed")}
                    />
                    <img
                        src={searchIcon}
                        alt=""
                        role="button"
                        onClick={() => navigatePage("search")}
                    />
                    <img
                        src={leaderboard_icon}
                        alt=""
                        role="button"
                        onClick={() => navigatePage("leaderboard/home")}
                    />
                    <img
                        src={menuIcon}
                        alt=""
                        role="button"
                        onClick={toggleMenu}
                        ref={iconRef}
                    />
                    <img
                        src={viewTrakrIcon}
                        alt=""
                        role="button"
                        onClick={() => navigatePage("viewTrakr")}
                    />
                </div>
                <BottomMenu
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    iconRef={iconRef}
                />
            </Container>
        </div>
    );
};

export default Footer;
