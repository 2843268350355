import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, Container, Form, FormGroup, Input, Spinner} from "reactstrap";
import topIcon from "../../assets/images/viewTrakr/icon.png";
import {printLog} from "../../util/helper";
import {addDoc, collection, doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../util/firebase";
import YouTubeThumbnail from "../../components/YouTubeThumbnail";
import Alert from "../../components/Alert";
import {VIMEO_URL_VERIFY_REGEX, YOUTUBE_URL_VERIFY_REGEX} from "../../util/constants";
import VimeoThumbnail from "../../components/VimeoThumbnail";

const EditVideo = (props) => {
    const navigate = useNavigate();
    const {vid} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sequence = queryParams.get('sequence');
    const link = queryParams.get('link');
    const playlistId = queryParams.get('playlistId');

    const [containerHeight, setContainerHeight] = useState('auto');
    const [videoData, setVideoData] = useState({});
    const [editvideoData, setEditVideoData] = useState({});
    const [loading, setLoading] = useState("initial");

    useEffect(() => {
        if (vid && vid !== "new") {
            getVideo().then((_video) => {
                // printLog(_video, 'video data');
                setLoading("disable");
                setVideoData(_video);
                setEditVideoData(_video);
            })
        } else {
            setEditVideoData({video_link: link});
            setLoading("disable");
        }
    }, []);
    useEffect(() => {
        let h1 = (props.mainContentHeight - props.hfHeight) - 2.8;
        printLog(h1, 'height');
        setContainerHeight(`${h1}vh`);
    }, [props.mainContentHeight]);

    const getVideo = async () => {
        const docRef = doc(db, "videos", vid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
    }
    const handleChange = async (event) => {
        const {value, name} = event.target;
        setEditVideoData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const goBack = () => {
        navigate(-1);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading("saving");
        printLog(editvideoData, "Edited data");
        if (!YOUTUBE_URL_VERIFY_REGEX.test(editvideoData.video_link)  && !VIMEO_URL_VERIFY_REGEX.test(editvideoData.video_link)) {
            props.callAlert('fail', 'Invalid video url');
            setLoading("disable");
            return;
        }
        try {
            if (vid === "new") {
                const collectionRef = collection(db, "videos");
                await addDoc(collectionRef, {
                    ...editvideoData,
                    sequence: Number(sequence),
                    playlist_id: playlistId,
                    views: 0,
                    user_id: props.signedInUser.uid,
                });
            } else {
                const docRef = doc(db, "videos", vid);
                await setDoc(docRef, editvideoData, {merge: true});
            }
        } catch (error) {
            printLog(error, "Error in firebase queries");
        }
        props.callAlert('success', 'Video Saved');
        setEditVideoData({});
        navigate(-1);
    }

    return (
        <div>
            <Container>
                <Card className="viewTrakr-card">
                    <Form className="mt-4" onSubmit={handleSubmit}>
                        <CardBody style={{height: containerHeight}}>
                            <div className="d-flex justify-content-center">
                                <div style={{marginTop: '-69px'}}>
                                    <img src={topIcon} alt="" width={70}/>
                                </div>
                            </div>
                            <div className="fs-12 text-center muted-text-bold">Edit Playlist</div>
                            <Container className="mt-4 full-height-container">
                                {loading === "initial" ?
                                    <div className="text-center mt-5">
                                        <Spinner color={'success'}>Loading...</Spinner>
                                    </div> :
                                    <div>
                                        <div className="d-flex justify-content-center mb-3">
                                            {vid !== "new" &&
                                               ( videoData.video_link.includes('youtu') ?
                                                        <YouTubeThumbnail url={videoData.video_link} sequence={videoData.sequence} edit={true}/>
                                                        :
                                                        <VimeoThumbnail    url={videoData.video_link} sequence={videoData.sequence} edit={true}/>)

                                                // <YouTubeThumbnail edit={true} sequence={videoData.sequence}
                                                //                   url={videoData.video_link}/>
                                            }
                                        </div>
                                        <FormGroup>
                                            <h6 className="muted-text ">Video Link</h6>
                                            <Input type="text" name="video_link" id="playlistTitle"
                                                   disabled={loading !== "disable"}
                                                   value={editvideoData.video_link}
                                                   onChange={handleChange} required className="viewTrakr-input"/>
                                            <p className="muted-text fs-12">Paste a video link to get started</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <h6 className="muted-text ">Video Title</h6>
                                            <Input type="text" name="title" id="playlistTitle"
                                                   disabled={loading !== "disable"}
                                                   value={editvideoData.title}
                                                   onChange={handleChange} required className="viewTrakr-input"/>
                                            <p className="muted-text fs-12">Enter a title for the video</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <h6 className="muted-text ">Video Description</h6>
                                            <Input type="text" name="description" id="playlistTitle"
                                                   disabled={loading !== "disable"}
                                                   value={editvideoData.description}
                                                   onChange={handleChange} required className="viewTrakr-input"/>
                                            <p className="muted-text fs-12">Enter a description for the video</p>
                                        </FormGroup>
                                        {/*<div className="d-flex justify-content-around mt-5">*/}
                                        {/*    <Button color="" disabled={loading !== "disable"} type="button"*/}
                                        {/*            className="go-back-button" onClick={goBack}>GO BACK</Button>*/}
                                        {/*    <Button color="" disabled={loading !== "disable"} type="submit"*/}
                                        {/*            className="save-button">*/}
                                        {/*        {loading === "saving" ?*/}
                                        {/*            <Spinner size='sm'>Loading...</Spinner>*/}
                                        {/*            : 'Save'}*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}

                                    </div>
                                }
                            </Container>
                        </CardBody>
                        <CardFooter className="viewTrakr-card-footer">
                            <div className="d-flex justify-content-around my-2">
                                <Button color="" disabled={loading !== "disable"} className="go-back-button"
                                        onClick={goBack}>GO BACK</Button>
                                <Button color="" disabled={loading !== "disable"} className="save-button"
                                        type="submit">
                                    {loading === "saving" ?
                                        <Spinner size='sm'>Loading...</Spinner>
                                        : 'Save'}
                                </Button>
                            </div>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        </div>
    );
};

export default EditVideo;
