import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../util/firebase";
import { Spinner } from "reactstrap";
import PlaylistCard from "./PlaylistCard";

const Library = ({ signedInUser, refreshUser, setRefreshUser }) => {
    const [playlists, setPlaylists] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPlaylist().then((results) => {
            console.log(results);
            setPlaylists(results);
            // setSequence(results.length + 1);
            setLoading(false);
        });
    }, []);

    const getPlaylist = async () => {
        const collectionRef = collection(db, "playlists");
        const _query = query(collectionRef, where("user_id", "==", "library"));
        let data = [];
        const querySnapshot = await getDocs(_query);
        querySnapshot.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        return data;
    };

    return (
        <div className="h-100">
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : (
                <div className="h-100">
                    {playlists.length > 0 ? (
                        playlists.map((_playlist, index) => (
                            <div
                                key={index}
                                className="d-flex justify-content-center"
                            >
                                <PlaylistCard
                                    playlist={_playlist}
                                    currentUser={"abc"}
                                    activeUser={signedInUser}
                                    library
                                    refreshUser={refreshUser}
                                    setRefreshUser={setRefreshUser}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="text-white h-100 d-flex justify-content-center align-items-center">
                            <h4 className="text-center">
                                No playlists added
                                <br /> Add one now
                            </h4>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Library;
