import React, { useState, useEffect } from 'react';
import blur from "../assets/images/viewTrakr/blur.jpeg";
import axios from 'axios';

const VimeoThumbnail = ({ url, sequence, edit }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    useEffect(() => {
        if (url.includes("vimeo.com")) {
            const vimeoId = url.split('vimeo.com/')[1];

            axios.get(`https://api.vimeo.com/videos/${vimeoId}`, {
                headers: {
                    "Authorization": `Bearer d0bf0ca4a757dffa2486f1b983c4adbd`
                }
            }).then(response => {
                const thumbnail = response.data.pictures.sizes[3].link; // This gets a medium-sized thumbnail. Adjust as needed.
                setThumbnailUrl(thumbnail);
            }).catch(error => {
                console.error("Error fetching Vimeo thumbnail:", error);
            });
        }
    }, [url]);
    return (
        thumbnailUrl ?
        <div className="thumbnail-container">
            <img src={thumbnailUrl} alt="Vimeo Thumbnail" className={`${!edit ? 'vimeo-thumbnail-image' : 'edit-vimeo-thumbnail-image'}`}/>
            {sequence !== null &&
                <h3 className={`${edit ? 'edit-thumbnail-overlay' : 'thumbnail-overlay'}`}>{sequence}</h3>
            }
        </div>
            :
            <div className="thumbnail-container">
                <img src={blur} width={150} alt="Vimeo Thumbnail" className={`${!edit ? 'vimeo-thumbnail-image' : 'edit-vimeo-thumbnail-image'}`}/>
                {sequence !== null &&
                    <h3 className={`${edit ? 'edit-thumbnail-overlay' : 'thumbnail-overlay'}`}>{sequence}</h3>
                }
            </div>
    );
};

export default VimeoThumbnail;