import React, {useEffect, useState} from "react";
import {Button, Form, Modal, ModalBody} from "reactstrap";
import {useLocation} from "react-router-dom";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../../util/firebase";
import Logo from "../../assets/images/LoginHeader/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {printLog} from "../../util/helper";

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

const Register = ({}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referrerId = queryParams.get("referrerId");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [age, setAge] = useState(false);
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    }

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 3000)
        }
    }, [error]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        if (!age) {
            setError('Please agree with terms and conditions');
            setLoader(false);
            return;
        }
        const referrerCheck = await handleReferrer()
        console.log(referrerCheck);
        // setLoader(false);
        // return;
        if (referrerCheck && referrerCheck?.state === true) {
            if (password === confirmPassword) {
                createUserWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        const userData = userCredential.user;
                        try {
                            await setDoc(doc(db, "users", userData.uid), {
                                email: email,
                                newUser: true,
                                playlist_count: 0,
                                wave_members: referrerCheck.referrers,
                            });
                        } catch (e) {
                            setError(e.message.trim().replace(/firebase:/i, ""));
                        }
                        setLoader(false);
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message.trim().replace(/firebase:/i, "");
                        setError(errorMessage);
                        setLoader(false);
                    });
            } else {
                setError("Password and Confirm Password do not match");
                setLoader(false);
            }
        } else {
            // setError(referrerCheck);
            toggleModal()
            setLoader(false);
        }
    };

    const handleReferrer = async () => {
        console.log(referrerId);
        try {
            if (referrerId) {
                const docRef = doc(db, "users", referrerId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log(docSnap.data());
                    // let tempArray = [];
                    let tempMembers = {};
                    if (docSnap.data()?.wave_members)
                        tempMembers = {...docSnap.data()?.wave_members};
                    if (Object.keys(tempMembers).length > 0) {
                        Object.keys(tempMembers).forEach(key => tempMembers[key] += 1);
                    }
                    tempMembers[`${referrerId}`] = 1;
                    // return tempMembers;
                    return {state: true, referrers: tempMembers};
                    // tempArray = [...docSnap.data()?.wave_members] || [];
                    // console.log(tempArray);
                    // tempArray = tempArray.map(obj => {
                    //     return {...obj, level: obj.level + 1};
                    // });
                    // tempArray.push({id: referrerId, level: 1});
                    // return {state: true, referrers: tempArray};
                } else {
                    console.log("Invalid Referral");
                    return false;
                }
            } else {
                console.log("No Referral");
                return false;
            }
        } catch (e) {
            printLog(e, "Error in getting user");
            setLoader(false);
        }

    };

    const onChange = () => {
        console.log("1");
    };

    return (
        <>
            <div>
                <div className="text-center pt-5 pb-5">
                    <img src={Logo} height={50} alt=""/>
                </div>
                {error !== "" && (
                    <div className="text-danger small-error-message fs-12">{error}</div>
                )}
                <div className="form-box">
                    <Form onSubmit={handleSubmit}>
                        <div className="form-group auth-inputs">
                            <input
                                className="form-auth w-100"
                                type="email"
                                name="email"
                                id="email"
                                required
                                placeholder="Email Address"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <hr/>
                            </div>
                            <input
                                className="form-auth w-100"
                                type="password"
                                name="password"
                                id="password"
                                required
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                                placeholder="Password"
                            />
                            <div className="d-flex justify-content-center">
                                <hr/>
                            </div>
                            <input
                                className="form-auth w-100"
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                required
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setError("");
                                }}
                                placeholder="Confirm Password"
                            />
                        </div>
                        <div className="container p-2  mb-5">
                            <div className="form-check ">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={age}
                                    checked={age}
                                    required
                                    id="defaultCheck1"
                                    onChange={(e) => {
                                        setAge(e.target.checked)
                                    }}
                                />
                                <label className="small-text text-mute" htmlFor="defaultCheck1">
                                    I am at least 13 years old and I agree to the{" "}
                                    <span className="text-white text-decoration-underline">
                                        Terms and Conditions
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div
                            style={{
                                borderRadius: "10px",
                                height: "70px",
                                overflow: "hidden",
                            }}
                        >
                            <ReCAPTCHA sitekey={TEST_SITE_KEY} onChange={onChange}/>
                        </div>
                        <Button
                            disabled={loader}
                            type="submit"
                            className=" button btn btn-primary btn-block  w-100"
                        >
                            {!loader ? (
                                "Register"
                            ) : (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            )}
                        </Button>
                    </Form>
                </div>
                <div className="p-5">
                    <p className="text-center text-mute small-text ">
                        @2023 WaveScore Games all right reserved
                    </p>
                </div>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalBody>
                        <h3 className="text-center">Registration</h3>
                        <p className="fw-semibold text-center mt-4">
                            You must be invited by an
                            existing member. If you are
                            having trouble finding one,
                            contact <span className="active-text"> info@zenduja.com </span>
                        </p>
                        <div className="text-center">
                            <Button className="button w-50 py-3" onClick={toggleModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default Register;
