import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import topIcon from "../../assets/images/viewTrakr/icon.png";
import editIcon from "../../assets/images/viewTrakr/edit.png";
import closeButton from "../../assets/images/viewTrakr/cross.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import { db } from "../../util/firebase";
import {
    addDoc,
    collection,
    increment,
    serverTimestamp,
    updateDoc,
} from "firebase/firestore";
import { addUrls, printLog } from "../../util/helper";
import { YOUTUBE_URL_VERIFY_REGEX } from "../../util/constants";
import CropImage from "../../components/CropImage";

const CreatePlaylist = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sequence = queryParams.get("sequence");
    // states
    const [loading, setLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState("auto");
    const [addToWall, setAddToWall] = useState(false);
    const user = props?.signedInUser;
    const [playlist, setPlaylist] = useState({
        title: "",
        coverPhoto: null,
        pageUrl: null,
        videoUrl: "",
        videoName: "",
        videoDescription: "",
        coverPhotoUrl: "",
    });
    const [previews, setPreviews] = useState({
        cover: "",
    });
    const [cropImage, setCropImage] = useState(null);

    useEffect(() => {
        let h1 = props.mainContentHeight - props.hfHeight;
        setContainerHeight(`${h1}vh`);
    }, [props.mainContentHeight]);

    const handleChange = (event) => {
        const { name, type } = event.target;
        if (type === "file") {
            // Assuming only one file is selected
            const file = event.target.files[0];
            if (!file || !file.type.includes("image")) {
                props.callAlert("fail", "Please select image");
                return;
            }
            if (file && file.size > 15 * 1024 * 1024) {
                // File size is in bytes, so 10 * 1024 * 1024 is 10MB
                props.callAlert("fail", "Please select file less than 15mb");
                setLoading(false);
                event.target.value = ""; // Clear the input
                return;
            }
            if (file) {
                setCropImage({
                    img: URL.createObjectURL(file),
                    type: name,
                    aspectRatio: 1.75,
                });
                event.target.value = "";
            }
        } else {
            const { value } = event.target;
            setPlaylist((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleCallBackImage = (crpImg, type) => {
        console.log("crpImage", crpImg);
        switch (type) {
            case "coverPhoto":
                setPlaylist((prevState) => ({
                    ...prevState,
                    [type]: crpImg,
                }));
                setPreviews((prevState) => ({
                    ...prevState,
                    cover: URL.createObjectURL(crpImg),
                }));
                break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(addToWall, "Add to wall field");
        // return;
        setLoading(true);
        if (!playlist.coverPhoto) {
            props.callAlert("fail", "Please Select Cover Photo");
            setLoading(false);
            return;
        }
        if (!YOUTUBE_URL_VERIFY_REGEX.test(playlist.videoUrl)) {
            props.callAlert("fail", "Invalid video url");
            setLoading(false);
            return;
        }
        try {
            const coverUrl = await addUrls(
                playlist.coverPhoto,
                "playlists/",
                `${user.uid}-playlist-${playlist.title}-${sequence}-cover`
            );
            // const docRef = doc(db,'playlists');
            const playlistData = {
                user_id: user.uid,
                user_name: user.name,
                // thumbnail: user.profile_photo_url,
                user_profile_photo: user.profile_photo_url,
                cover_photo_url: coverUrl,
                cover_redirect_link: playlist.coverPhotoUrl.includes("https://")
                    ? playlist.coverPhotoUrl
                    : `https://${playlist.coverPhotoUrl}`,
                // use_cover_photo: user.cover_photo_url,
                pageUrl: playlist.pageUrl,
                title: playlist.title,
                sequence: Number(sequence),
                clonedBy: [],
            };
            addDoc(collection(db, "playlists"), playlistData).then((doc) => {
                const videoData = {
                    description: playlist.videoDescription,
                    playlist_id: doc.id,
                    title: playlist.videoName,
                    video_link: playlist.videoUrl,
                    sequence: 1,
                    views: 0,
                    user_id: user.uid,
                };
                addDoc(collection(db, "videos"), videoData).then(
                    async (res) => {
                        if (addToWall) {
                            const postData = {
                                user_id: user.uid,
                                user_name: user.userNameInsensitive,
                                user_profile_photo: user.profile_photo_url,
                                type: "playlist",
                                description: playlist.title,
                                location: user.location,
                                createdAt: serverTimestamp(),
                                sourceUrl: coverUrl,
                                playlistId: doc.id,
                            };
                            await addDoc(collection(db, "posts"), postData);
                        }
                        const userDocRef = doc(db, "users", user.uid);
                        await updateDoc(userDocRef, {
                            playlist_count: increment(1),
                        });

                        props.callAlert("success", "Added to your playlist");
                        props.setRefreshUser(!props.refreshUser);
                        setPlaylist({
                            title: "",
                            coverPhoto: null,
                            pageUrl: "",
                            videoUrl: "",
                            videoName: "",
                            videoDescription: "",
                            coverPhotoUrl: "",
                        });
                        setPreviews({ cover: "" });
                        setLoading(false);
                        navigate("/viewTrakr");
                    }
                );
            });
        } catch (error) {
            printLog(error, "Error in creating playlist");
            props.callAlert("fail", "Something went wrong");
            setLoading(false);
        }
    };

    return (
        <div>
            <Container>
                <Card className="viewTrakr-card">
                    <CardBody>
                        <div className="d-flex justify-content-center">
                            <div style={{ marginTop: "-45px" }}>
                                <img src={topIcon} alt="" width={70} />
                            </div>
                        </div>
                        <div className="fs-12 text-center muted-text-bold">
                            Create Playlist
                        </div>
                        <Container
                            className="mt-4 full-height-container"
                            style={{ height: containerHeight }}
                        >
                            <Form onSubmit={handleSubmit}>
                                <FormGroup check className="mb-2">
                                    <Input
                                        type="checkbox"
                                        value={addToWall}
                                        id="add_to_wall"
                                        onChange={(e) =>
                                            setAddToWall(e.target.checked)
                                        }
                                    />
                                    <Label
                                        for="add_to_wall"
                                        check
                                        className="fs-6 muted-text fw-semibold"
                                    >
                                        Add playlist to your wall
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className="muted-text ">
                                        Playlist Title
                                    </h6>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="playlistTitle"
                                        value={playlist.title}
                                        onChange={handleChange}
                                        className="viewTrakr-input"
                                        required
                                    />
                                    <p className="muted-text fs-12">
                                        Playlist titles will display above video
                                        thumbnails
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className="muted-text ">Page URL</h6>
                                    <Input
                                        type="text"
                                        name="pageUrl"
                                        id="playlistTitle"
                                        value={playlist.pageUrl}
                                        onChange={handleChange}
                                        required
                                        className="viewTrakr-input"
                                    />
                                    <p className="muted-text fs-12">
                                        Web Address for your Video Page
                                    </p>
                                </FormGroup>
                                <Row className="mt-5">
                                    <Col sm={4} xs={4}>
                                        <label
                                            className="image-wrapper rounded-circle"
                                            style={{
                                                backgroundImage: `url('${previews.cover}')`,
                                                backgroundSize: "cover",
                                            }}
                                        >
                                            <img
                                                src={editIcon}
                                                width={30}
                                                alt=""
                                            />
                                            <input
                                                type="file"
                                                className="d-none"
                                                accept=".jpg,.jpeg,.png"
                                                name="coverPhoto"
                                                onChange={handleChange}
                                            />
                                        </label>
                                    </Col>
                                    <Col sm={8} xs={8}>
                                        <label className="muted-text fw-semibold">
                                            Cover Photo
                                        </label>
                                        <p className="muted-text fs-12">
                                            Upload a new photo or choose one
                                            from your Picture Library
                                        </p>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <h6 className="muted-text ">
                                        Add New Video
                                    </h6>
                                    <Input
                                        type="text"
                                        name="videoUrl"
                                        className="viewTrakr-input"
                                        id="video_url"
                                        onChange={handleChange}
                                        value={playlist.videoUrl}
                                        required
                                    />
                                    <p className="muted-text fs-12">
                                        Add a video URL from Youtube or Viemo
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className="muted-text ">
                                        Name for this Video
                                    </h6>
                                    <Input
                                        type="text"
                                        className="viewTrakr-input"
                                        name="videoName"
                                        id="video_name"
                                        onChange={handleChange}
                                        value={playlist.videoName}
                                        required
                                    />
                                    <p className="muted-text fs-12">
                                        Enter a name for your new Video
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className="muted-text ">
                                        Video Description
                                    </h6>
                                    <Input
                                        className="viewTrakr-input"
                                        type="text"
                                        name="videoDescription"
                                        id="video_description"
                                        required
                                        onChange={handleChange}
                                        value={playlist.videoDescription}
                                    />
                                    <p className="muted-text fs-12">
                                        Enter a description for your Video, 200
                                        character max
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className="muted-text ">
                                        Cover Photo URL
                                    </h6>
                                    <Input
                                        className="viewTrakr-input"
                                        type="text"
                                        name="coverPhotoUrl"
                                        id="cover_photo_url"
                                        onChange={handleChange}
                                        required
                                        value={playlist.coverPhotoUrl}
                                    />
                                    <p className="muted-text fs-12">
                                        Redirect your viewers to any page on the
                                        web
                                    </p>
                                </FormGroup>
                                <div className="d-flex">
                                    <Link to="/viewTrakr">
                                        <div className="me-3">
                                            <img
                                                src={closeButton}
                                                alt=""
                                                width={50}
                                            />
                                        </div>
                                    </Link>
                                    <Button
                                        className="w-100 me-2 success-button"
                                        type="submit"
                                        color=""
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                        ) : (
                                            "Save"
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
            {cropImage && (
                <CropImage
                    cropImage={cropImage}
                    handleClose={() => setCropImage(null)}
                    aspectRatio={cropImage.aspectRatio}
                    handleCallBackImage={handleCallBackImage}
                />
            )}
        </div>
    );
};

export default CreatePlaylist;
