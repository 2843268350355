import React, { useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./Header";
import routes from "../routes";
import "../assets/styles/ViewTrakr.css";
import { Container } from "reactstrap";
import UserFooter from "./UserFooter";
import PlaylistView from "../views/viewTrakr/playlistView";

const ViewTrakrPlaylist = ({
    signedInUser,
    callAlert,
    setPostFile,
    refreshUser,
    setRefreshUser,
    userPoints,
    appMetaData,
    pid,
}) => {
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const mainContentRef = useRef(null);
    const [mainContentHeight, setMainContentHeight] = useState("auto");
    const [mainContentValue, setMainContentValue] = useState(0);
    const [hfHeight, setHfHeight] = useState(0);
    const [zendujaHome, setZendujaHome] = useState(false);

    useEffect(() => {
        updateHeight(); // Call this initially when the component mounts

        window.addEventListener("resize", updateHeight); // Add an event listener

        return () => {
            // Cleanup: remove the event listener when the component unmounts
            window.removeEventListener("resize", updateHeight);
        };
    }, []); // Only run once after component is mounted

    const updateHeight = () => {
        if (headerRef.current && footerRef.current) {
            const headerHeightVH =
                (headerRef.current.offsetHeight / window.innerHeight) * 100;
            const footerHeightVH =
                (footerRef.current.offsetHeight / window.innerHeight) * 100;
            const totalVH = headerHeightVH + footerHeightVH;
            setHfHeight(totalVH);
            setMainContentValue(100 - totalVH);
            setMainContentHeight(`calc(100vh - ${totalVH}vh)`);
        }
    };

    return (
        <div className="viewTrakr-bg">
            {/*{showAlert && <Alert settings={alertSettings}/>}*/}
            <Container fluid>
                <Header
                    userPoints={userPoints}
                    reference={headerRef}
                    appMetaData={appMetaData}
                    signedInUser={signedInUser}
                    headerLogo={zendujaHome ? "" : "viewtrakr"}
                />
            </Container>
            <div
                ref={mainContentRef}
                style={{ height: mainContentHeight }}
                id="view-trakr-layout"
            >
                <PlaylistView
                    mainContentHeight={mainContentValue}
                    setPostFilea={setPostFile}
                    hfHeight={hfHeight}
                    signedInUser={signedInUser}
                    callAlert={callAlert}
                    setZendujaHome={setZendujaHome}
                    refreshUser={refreshUser}
                    setRefreshUser={setRefreshUser}
                    pid={pid}
                />
            </div>
            <UserFooter
                reference={footerRef}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
            />
        </div>
    );
};

export default ViewTrakrPlaylist;
