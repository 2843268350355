import CreatePlaylist from "../views/viewTrakr/createPlaylist";
import EditPlaylist from "../views/viewTrakr/editPlaylist";
import EditVideo from "../views/viewTrakr/editVideo";
import Listing from "../views/viewTrakr/listing";
import PlaylistView from "../views/viewTrakr/playlistView";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Listing,
        layout: "/:username",
    },
    {
        path: "/viewTrakr",
        name: "Home",
        component: Listing,
        layout: "/:username",
    },
];

export default routes;
