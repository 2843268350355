import React, { useEffect, useState } from "react";
import { Form, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/LoginHeader/logo.png";
import meta from "../../assets/images/auth/meta.png";
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth } from "../../util/firebase";
import { appVersion } from "../../util/constants";

const Login = ({ callAlert }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [forgetPassLoader, setForgetPassLoader] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setLoader(false);
            })
            .catch((error) => {
                const errorMessage = error.message
                    .trim()
                    .replace(/firebase:/i, "");
                setLoader(false);
                setError(errorMessage);
            });
    };

    const handleResetPassword = async () => {
        if (!email || !email.trim()) {
            callAlert("fail", "Please fill out email");
            return;
        }
        try {
            setForgetPassLoader(true);
            await sendPasswordResetEmail(auth, email);
            callAlert("success", "Reset Password link sent your email");
            setForgetPassLoader(false);
        } catch (error) {
            console.error("Error sending password reset email:", error.message);
            callAlert("fail", error.message);
            setForgetPassLoader(false);
        }
    };

    return (
        <>
            <div>
                <div className="text-center pt-5 pb-5">
                    <img src={Logo} height={50} alt="" />
                </div>
                {error !== "" && (
                    <div className="text-danger small-error-message fs-12">
                        {error}
                    </div>
                )}
                <div className="form-box">
                    <Form onSubmit={handleSubmit}>
                        <div className="form-group auth-inputs">
                            <input
                                className="form-auth w-100"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email..."
                                value={email}
                                required
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <hr />
                            </div>
                            <input
                                className="form-auth w-100"
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                                required
                                placeholder="Password..."
                            />
                        </div>
                        <div className="text-end mt-1 mb-5">
                            <a
                                className="small-text  text-mute "
                                onClick={
                                    !forgetPassLoader && handleResetPassword
                                }
                            >
                                Forget your password?
                            </a>
                        </div>
                        <Button
                            type="submit"
                            disabled={loader}
                            className=" button btn btn-primary btn-block  w-100"
                        >
                            {!loader ? (
                                "Sign in"
                            ) : (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only"></span>
                                </div>
                            )}
                        </Button>

                        <Button
                            type="button"
                            className=" btn btn-primary w-100 custom-button"
                        >
                            <span className="icon">
                                <img src={meta} alt="" width={60} />
                            </span>

                            <span className="text">
                                Sign in with <strong>Meta</strong>
                            </span>
                        </Button>
                        <Link to={"/auth/register"}>
                            <Button
                                type="button"
                                className=" button buttonColor btn btn-primary btn-block  w-100"
                            >
                                <span className="text-mute">
                                    {" "}
                                    Trying to register?
                                </span>
                            </Button>
                        </Link>
                    </Form>
                </div>
                <div className="p-5 position-relative">
                    <p className="text-center text-mute small-text mb-0">
                        @2023 WaveScore Games all right reserved
                    </p>
                    <p
                        className="text-end text-mute small-text"
                        style={{ position: "absolute", left: "64%" }}
                    >
                        Version {appVersion}
                    </p>
                </div>
            </div>
        </>
    );
};

export default Login;
