import viewTrakr from "./viewTrakr";
import user from "./user";
import auth from "./auth";
import public_routes from "./public";
import leaderboard from "./leaderboard";
import myWave from "./myWave";
import group from "./group";
import userProfile from "./userProfile";

export default {
    viewTrakr: viewTrakr,
    user: user,
    auth: auth,
    public: public_routes,
    leaderboard: leaderboard,
    myWave: myWave,
    group: group,
    userProfile: userProfile,
};
