import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    increment,
    orderBy,
    query,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { db } from "../util/firebase";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Spinner,
} from "reactstrap";
import cloneIcon from "../assets/images/viewTrakr/clone-playlist.svg";
import unLikedHeart from "../assets/images/viewTrakr/empty-heart.png";
import likedHeart from "../assets/images/viewTrakr/heart-new.svg";
import share_icon from "../assets/images/viewTrakr/share-new.svg";
import commentIcon from "../assets/images/viewTrakr/comment-new.svg";
import comments_collapse from "../assets/images/viewTrakr/comments-collapse.svg";
import logo from "../assets/images/Header/viewtrakr_logo.svg";
import { printLog, updateViews } from "../util/helper";
import dotIcon from "../assets/images/viewTrakr/dot-icon.png";
import YouTubeThumbnail from "../components/YouTubeThumbnail";
import heartIcon from "../assets/images/viewTrakr/black-heart.svg";
import VimeoThumbnail from "../components/VimeoThumbnail";
import us_flag from "../assets/images/viewTrakr/usa-flag.png";
import hawai_flag from "../assets/images/viewTrakr/hawai-flag.png";
import clonedPlaylist from "../assets/images/viewTrakr/cloned-playlist.svg";
import moment from "moment/moment";

const ViewOnly = ({
    signedInUser,
    appMetaData,
    callAlert,
    pid,
    refreshUser,
    setRefreshUser,
}) => {
    const navigate = useNavigate();
    const [playlingIndex, setPlayingIndex] = useState(0);
    const [playlist, setPlaylist] = useState({});
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState("initial");
    const [embeddedLink, setEmbeddedLink] = useState("");
    const [videosLoaded, setVideosLoaded] = useState(false);
    const [updatingViews, setUpdatingViews] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [coverLoaded, setCoverLoaded] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [playlistCloned, setPlaylistCloned] = useState(false);
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        getPlaylist().then((_playlist) => {
            setPlaylist(_playlist);
            if (_playlist?.clonedBy.includes(signedInUser.uid))
                setPlaylistCloned(true);
            getUser(_playlist.user_id).then((user) => {
                setCurrentUser(user);
            });
        });
        getVideos().then((_videos) => {
            setVideos(_videos);
            setVideosLoaded(true);
            setLoading("ready");
        });
        let resetTime = appMetaData?.leaderboard_reset?.nextReset;
        const timer = setInterval(() => {
            // Convert Firebase timestamp to JavaScript Date
            const targetDate = new Date(
                resetTime.seconds * 1000 + resetTime.nanoseconds / 1000000
            );

            // Calculate time difference using moment
            const now = moment();
            const targetMoment = moment(targetDate);
            const duration = moment.duration(targetMoment.diff(now));

            // Check if countdown is over
            if (duration.asSeconds() <= 0) {
                clearInterval(timer);
                setTimeLeft("Time's up!");
                return;
            }

            // Extract days, hours, minutes, and seconds
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Update state
            setTimeLeft({
                days,
                hours,
                minutes,
                seconds,
            });
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(timer);
    }, []);
    useEffect(() => {
        setUpdatingViews(true);
        if (videos.length > 0) {
            const videoLink = videos[playlingIndex]?.video_link;
            let link;

            if (
                videoLink.includes("youtube.com") ||
                videoLink.includes("youtu.be")
            ) {
                const videoId = extractVideoId(videoLink, "youtube");
                link = `https://www.youtube.com/embed/${videoId}`;
            } else if (videoLink.includes("vimeo.com")) {
                const videoId = extractVideoId(videoLink, "vimeo");
                link = `https://player.vimeo.com/video/${videoId}`;
            }
            setEmbeddedLink(link);
            updateViews(videos[playlingIndex].id).then((res) => {
                const localVideos = [...videos];
                localVideos[playlingIndex].views = res;
                setVideos([...localVideos]);
                setLoading("ready");
                setUpdatingViews(false);
            });
        }
    }, [playlingIndex, videosLoaded]);

    const getPlaylist = async () => {
        try {
            const docRef = doc(db, "playlists", pid);
            const docSnap = await getDoc(docRef);
            const postRef = collection(db, "posts");
            const q = query(postRef, where("playlistId", "==", pid));
            const postSnap = await getDocs(q);
            let data = {};
            if (postSnap.docs.length > 0) {
                data = { ...postSnap.docs[0].data() };
            }
            if (docSnap.exists()) {
                return {
                    ...docSnap.data(),
                    ...data,
                    clone_playlist_id: docSnap.id,
                };
            }
        } catch (error) {
            printLog(error, "Error in getting playlist");
        }
    };
    const getVideos = async () => {
        try {
            if (pid) {
                const collectionRef = collection(db, "videos");
                const _query = query(
                    collectionRef,
                    where("playlist_id", "==", pid),
                    orderBy("sequence")
                );
                let data = [];
                const querySnapshot = await getDocs(_query);
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                return data;
            }
        } catch (error) {
            printLog(error, "Error in getting playlist videos");
        }
    };
    const extractVideoId = (url, platform) => {
        let videoId;

        if (platform === "youtube") {
            if (url.includes("youtube.com")) {
                if (url.includes("youtube.com/shorts/")) {
                    videoId = url.split("youtube.com/shorts/")[1];
                } else {
                    const urlObj = new URL(url);
                    videoId = urlObj.searchParams.get("v");
                }
            } else if (url.includes("youtu.be")) {
                videoId = url.split("youtu.be/")[1];
            }
        } else if (platform === "vimeo" && url.includes("vimeo.com")) {
            videoId = url.split("vimeo.com/")[1];
        }

        // Removing any trailing slash or parameters
        if (videoId) {
            videoId = videoId.split("/")[0];
            videoId = videoId.split("?")[0];
        }

        return videoId;
    };
    const navigatePage = () => {
        navigate(`/auth/register?referrerId=${playlist.user_id}`);
    };
    const getUser = async (userId) => {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
    };
    const followUser = async () => {
        const active_followingsArray = signedInUser?.followings || [];
        const current_followersArray = currentUser?.followers || [];
        try {
            if (
                signedInUser.uid !== playlist?.user_id &&
                !active_followingsArray.includes(playlist?.user_id) &&
                !current_followersArray.includes(signedInUser.uid)
            ) {
                setLoading("following");
                const activeRef = doc(db, "users", signedInUser.uid);
                const currentRef = doc(db, "users", playlist?.user_id);
                active_followingsArray.push(playlist?.user_id);
                current_followersArray.push(signedInUser.uid);
                await updateDoc(currentRef, {
                    followers: current_followersArray,
                });
                updateDoc(activeRef, {
                    followings: active_followingsArray,
                }).then(() => {
                    // props.setRefreshUser(!props.refreshUser);
                    setLoading("ready");
                });
            }
        } catch (error) {
            printLog(error, "Error while follow");
        }
    };

    const copyLink = () => {
        try {
            const playlistLink = `${window.origin}/viewTrakr/${pid}`;
            navigator.clipboard.writeText(playlistLink);
            callAlert("success", "Link Copied!");
        } catch (e) {
            printLog(e, "Error in copying the link");
            callAlert("fail", "Something went wrong");
        }
    };

    const clonePlaylist = async () => {
        try {
            if (!playlistCloned) {
                setLoading("cloning");
                const playlistData = {
                    ...playlist,
                    user_id: signedInUser.uid,
                    user_name: signedInUser.name,
                    user_profile_photo: signedInUser.profile_photo_url,
                    sequence: signedInUser?.playlist_count + 1,
                };
                addDoc(collection(db, "playlists"), playlistData).then(
                    async (playlistResponse) => {
                        const batch = writeBatch(db);
                        videos.forEach((_video) => {
                            delete _video.id;
                            let docRef;
                            docRef = doc(collection(db, "videos"));
                            batch.set(docRef, {
                                ..._video,
                                views: 0,
                                playlist_id: playlistResponse.id,
                                user_id: signedInUser.uid,
                            });
                        });
                        await batch.commit();
                        const playlistDocRef = doc(db, "playlists", pid);
                        const userList = [...playlist.clonedBy];
                        userList.push(signedInUser.uid);
                        await updateDoc(playlistDocRef, { clonedBy: userList });
                        const userDocRef = doc(db, "users", signedInUser.uid);
                        await updateDoc(userDocRef, {
                            playlist_count: increment(1),
                        });
                        setRefreshUser(!refreshUser);
                        setLoading("ready");
                        // props.callAlert('success', 'Playlist added to My Pages');
                        setPlaylistCloned(true);
                    }
                );
            }
        } catch (error) {
            printLog(error, "Error in cloning playlist");
        }
    };
    return (
        <div className="h-100">
            {loading === "initial" ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : (
                <div className="h-100 text-white">
                    {/*Pop up header starts*/}
                    <div className="pop-up-container">
                        <div className="pop-up">
                            <div className="fs-12 fw-bold viewOnly-timer">
                                <span>
                                    {timeLeft
                                        ? `${timeLeft.days}:${
                                              timeLeft.hours < 10 ? "0" : ""
                                          }${timeLeft.hours}:${
                                              timeLeft.minutes < 10 ? "0" : ""
                                          }${timeLeft.minutes}:`
                                        : "00:00:00:"}
                                    <span style={{ color: "#20EF9F" }}>
                                        {timeLeft
                                            ? `${
                                                  timeLeft.seconds < 10
                                                      ? "0"
                                                      : ""
                                              }${timeLeft.seconds}`
                                            : "00"}
                                    </span>
                                </span>
                            </div>
                            <div className="mb-1">
                                <img src={logo} alt="" />
                            </div>
                            <div>
                                <Button
                                    className="follow-playlist-button"
                                    disabled={
                                        loading === "following" ||
                                        playlist.user_id === "library"
                                    }
                                    onClick={followUser}
                                >
                                    {loading === "following" ? (
                                        <Spinner
                                            size="sm"
                                            type="grow"
                                            color="light"
                                        >
                                            Loading...
                                        </Spinner>
                                    ) : signedInUser?.followings?.includes(
                                          playlist?.user_id
                                      ) ||
                                      currentUser?.followers?.includes(
                                          signedInUser.uid
                                      ) ? (
                                        "Following"
                                    ) : (
                                        "Follow"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/*New user profile*/}
                    <Container className="pt-3">
                        <a>
                            <div
                                className="profile-cover"
                                style={{
                                    height: "160px",
                                    backgroundImage: `url('${playlist?.cover_photo_url}')`,
                                    filter: coverLoaded ? "none" : "blur(15px)",
                                    transition: "filter 0.3s",
                                }}
                                onClick={() => {
                                    window.open(
                                        playlist?.cover_redirect_link,
                                        "_blank"
                                    );
                                }}
                            >
                                <img
                                    src={currentUser?.cover_photo_url}
                                    onLoad={() => setCoverLoaded(true)}
                                    alt="Cover Preloading"
                                    style={{
                                        position: "absolute",
                                        top: "-9999px",
                                        height: "1px",
                                        width: "1px",
                                        opacity: "0",
                                    }}
                                />
                                <div className="profile-tag-container d-flex align-items-center">
                                    <div
                                        className="profile-tag rounded-circle"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                                `/${currentUser.userNameInsensitive}`
                                            );
                                        }}
                                    >
                                        <img
                                            src={playlist?.user_profile_photo}
                                            onLoad={() =>
                                                setProfileLoaded(true)
                                            }
                                            style={{
                                                filter: profileLoaded
                                                    ? "none"
                                                    : "blur(5px)", // 5px is the blur amount, adjust as needed
                                                transition: "filter 0.3s", // Smooth transition when removing blur
                                            }}
                                            width="100%"
                                            height="100%"
                                            className="rounded-circle"
                                        />
                                        <div className="flag">
                                            <img
                                                src={
                                                    currentUser?.location ===
                                                    "US"
                                                        ? us_flag
                                                        : currentUser?.location ===
                                                          "HI"
                                                        ? hawai_flag
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="text-start ms-3 text-white">
                                        <div className="fw-bold w-100">
                                            {currentUser?.name}
                                        </div>
                                        <div className="fs-14">
                                            @{currentUser?.userName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Container>

                    {/*Video Player*/}
                    <div style={{ minHeight: "270px" }} className="mt-5">
                        {loading === "newVideo" ? (
                            <div className="text-center">
                                <Spinner color={"success"} className="mt-5">
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <Card className="mx-2 bg-dark text-white">
                                <CardHeader className="pe-0 py-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>{playlist?.title}</span>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 fs-12 text-center muted-text-bold line-height-1">
                                                <span>Video</span>
                                                <div>
                                                    {playlingIndex + 1}/
                                                    {videos?.length}
                                                </div>
                                            </div>
                                            <div
                                                className="clone-icon-div"
                                                onClick={clonePlaylist}
                                            >
                                                {loading === "cloning" ? (
                                                    <Spinner
                                                        size="sm"
                                                        type="grow"
                                                        color="light"
                                                    >
                                                        Loading...
                                                    </Spinner>
                                                ) : (
                                                    <img
                                                        src={
                                                            playlistCloned
                                                                ? clonedPlaylist
                                                                : cloneIcon
                                                        }
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-0 bg-black">
                                    <iframe
                                        width="100%"
                                        height="270"
                                        src={embeddedLink}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </CardBody>
                            </Card>
                        )}
                    </div>
                    <Container className="py-1">
                        <div className="d-flex justify-content-between mt-2">
                            <div>
                                <div className="fs-12 muted-text-bold">
                                    <span>VIDEO {playlingIndex + 1} | </span>
                                    <span>{videos[playlingIndex]?.views} </span>
                                    <span className="fs-8">VIEWS</span>
                                </div>
                                <div className="fw-semibold">
                                    {videos[playlingIndex]?.title}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img
                                        src={
                                            playlist?.likes &&
                                            Object.keys(
                                                playlist?.likes
                                            )?.includes(signedInUser.uid)
                                                ? likedHeart
                                                : unLikedHeart
                                        }
                                        alt=""
                                        height={25}
                                    />
                                    <div className="fs-12 text-center fw-bold">
                                        {playlist?.likesCount || 0}
                                    </div>
                                </div>
                                <div onClick={copyLink}>
                                    <img src={share_icon} alt="" height={30} />
                                    <div className="fs-10 muted-text-bold text-center fw-bold">
                                        Share
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Container className="py-1">
                        <div>
                            <hr className="my-1" />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-center">
                                <img src={commentIcon} alt="" width={25} />
                                <span className="fs-14 ms-1  text-center fw-bold">
                                    Comments
                                </span>
                            </div>
                            <div>
                                <img src={comments_collapse} alt="" />
                            </div>
                        </div>
                    </Container>
                    <Container className="mt-3 position-relative">
                        {videos.length > 0 &&
                            videos.map((_video, index) => (
                                <div
                                    className="video-item"
                                    key={index}
                                    onClick={() => {
                                        if (
                                            playlingIndex !== index &&
                                            updatingViews === false
                                        ) {
                                            setPlayingIndex(index);
                                            setLoading("newVideo");
                                        }
                                    }}
                                >
                                    {index === playlingIndex && (
                                        <img
                                            src={dotIcon}
                                            alt=""
                                            width={10}
                                            className="me-2"
                                        />
                                    )}
                                    <div>
                                        {_video.video_link.includes("youtu") ? (
                                            <YouTubeThumbnail
                                                url={_video.video_link}
                                                sequence={null}
                                                edit={false}
                                            />
                                        ) : (
                                            <VimeoThumbnail
                                                url={_video.video_link}
                                                sequence={null}
                                                edit={false}
                                            />
                                        )}
                                    </div>
                                    <div className="video-item-details-container-new pe-3">
                                        <div className="video-item-description">
                                            <span className="fs-10 muted-text-bold text-uppercase">
                                                Video {_video.sequence}
                                            </span>
                                            <div className="fs-12 fw-semibold">
                                                {_video.title}
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={heartIcon}
                                                alt=""
                                                width={15}
                                            />
                                        </div>
                                    </div>
                                    {/*{_video.title}*/}
                                </div>
                            ))}
                        {updatingViews && (
                            <div className="overlay position-absolute top-0 bottom-0">
                                <Spinner>Loading...</Spinner>
                            </div>
                        )}
                    </Container>
                </div>
            )}
        </div>
    );
};

export default ViewOnly;
