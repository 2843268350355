import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../util/firebase";
import { printLog } from "../util/helper";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

const AllPosts = ({ userId, setPostCount }) => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadedImages, setLoadedImages] = useState({}); // Track loaded images

    useEffect(() => {
        getPosts().then((_posts) => {
            setPostCount(_posts?.length || 0);
            setPosts(_posts || []);
            setLoading(false);
        });
    }, []);

    const getPosts = async () => {
        setLoading(true);
        const postsRef = collection(db, "posts");
        const q = query(
            postsRef,
            where("user_id", "==", userId),
            // where("groupId", "<=", ""),
            // orderBy("groupId"),
            orderBy("createdAt", "desc")
        );
        try {
            let postsArray = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.docs.forEach((doc) => {
                if (!doc.data().hasOwnProperty("groupId")) {
                    postsArray.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                }
            });
            return postsArray;
        } catch (error) {
            printLog(error, "Error in getting the posts");
            setLoading(false);
        }
    };
    const handleClick = (post) => {
        if (post.type === "playlist") navigate(`/viewTrakr/${post.playlistId}`);
    };
    const handleImageLoaded = (postId) => {
        setLoadedImages((prevState) => ({ ...prevState, [postId]: true }));
    };

    return loading ? (
        <div className="text-center mt-5">
            <Spinner color={"success"}>Loading...</Spinner>
        </div>
    ) : posts.length > 0 ? (
        <Container>
            <Row>
                {posts.map((post, index) => (
                    <Col xs={4} key={index} className="mb-3">
                        {post.type === "image" || post.type === "playlist" ? (
                            <img
                                src={post.sourceUrl}
                                alt=""
                                height={113}
                                width="100%"
                                onLoad={() => handleImageLoaded(post.id)}
                                style={{
                                    filter: loadedImages[post.id]
                                        ? "none"
                                        : "blur(5px)",
                                    transition: "filter 0.3s",
                                }}
                                className="rounded-3"
                                onClick={() => handleClick(post)}
                            />
                        ) : (
                            post.type === "video" && (
                                <video
                                    src={post.sourceUrl}
                                    height={113}
                                    width="100%"
                                    className="rounded-3"
                                />
                            )
                        )}
                    </Col>
                ))}
            </Row>
        </Container>
    ) : (
        <div className="text-white h-100 d-flex justify-content-center align-items-center">
            <h4 className="text-center">
                No posts added
                <br /> Add one now
            </h4>
        </div>
    );
};

export default AllPosts;
