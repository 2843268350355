import React from "react";
import { Col, Container, Row } from "reactstrap";
import { formatLargeNumber, waveMembersforSector } from "../util/helper";

const SectorTab = ({ item, currentUser, allWaveMembers }) => {
    const waveMembers = waveMembersforSector(item, currentUser, allWaveMembers);

    const getViewsofSector = (sector) => {
        if (
            currentUser.sectorViews &&
            Object.keys(currentUser.sectorViews).length > 0
        ) {
            const { sectorViews } = currentUser;

            return sectorViews[sector] || 0;
        }
        return 0;
    };

    const getPointsofSector = (sector) => {
        if (
            currentUser.sectorPoints &&
            Object.keys(currentUser.sectorPoints).length > 0
        ) {
            const { sectorPoints } = currentUser;

            return sectorPoints[sector] || 0;
        }
        return 0;
    };

    const SECTORS_COLOR = {
        2: "#00D079",
        3: "#D08900",
        4: "#D0004B",
        5: "#FF1493",
        6: "#774C38",
        7: "#0048ba",
        8: "#ff7f50",
        9: "#7c1717",
        10: "#9966cc",
    };

    return (
        <Container
            fluid
            className={`sector-tab mt-2 ${
                waveMembers.length === 0 && "disable-sector-tab"
            }`}
        >
            <Row
                className={
                    waveMembers.length === 0
                        ? "disable-sector-tab-header"
                        : "sector-tab-header"
                }
                style={{ backgroundColor: SECTORS_COLOR[item.sector] }}
            >
                <Col xs={4} className="text-center p-0">
                    <span className="sector-heading">{item.name}</span>
                </Col>
                <Col xs={5} className="py-3  px-0">
                    <div
                        className="Line5"
                        style={{
                            width: "110%",
                            height: 0,
                            border:
                                waveMembers.length === 0
                                    ? "1px #626262 solid"
                                    : "1px white solid",
                        }}
                    ></div>
                </Col>
                <Col xs={3} className="text-center p-0">
                    <span className="sub-heading">lvl {item.waveLevels}</span>
                </Col>
            </Row>

            <Row className="sector-details  mt-2">
                <Col
                    xs="8"
                    sm="8"
                    className="sector-details-label"
                    style={{
                        color: waveMembers.length === 0 ? "#626262" : "#E7E7E7",
                    }}
                >
                    <span>Wave Members :</span>
                    <span>Total Views :</span>
                    <span>Pts Generated:</span>
                </Col>
                <Col
                    xs="4"
                    sm="4"
                    className="sector-details-value"
                    style={{
                        color:
                            waveMembers.length === 0
                                ? "#626262"
                                : SECTORS_COLOR[item.sector],
                    }}
                >
                    <span>{formatLargeNumber(waveMembers.length)}</span>
                    <span>
                        {formatLargeNumber(getViewsofSector(item.sector))}
                    </span>
                    <span>
                        {formatLargeNumber(getPointsofSector(item.sector))}
                    </span>
                </Col>
            </Row>
        </Container>
    );
};

export default SectorTab;
