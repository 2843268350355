import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../util/firebase";
import { Spinner } from "reactstrap";
import PlaylistCard from "./PlaylistCard";

const MyPages = ({
    currentUser,
    setSequence,
    activeUser,
    refreshUser,
    setRefreshUser,
}) => {
    const [playlists, setPlaylists] = useState([]);
    const [loading, setLoading] = useState(true);

    console.log("playlists", playlists);
    useEffect(() => {
        getPlaylist().then((results) => {
            setPlaylists(results);
            setSequence(results.length + 1);
            setLoading(false);
        });
    }, []);

    const getPlaylist = async () => {
        const collectionRef = collection(db, "playlists");
        const _query = query(
            collectionRef,
            where("user_id", "==", currentUser?.uid),
            orderBy("sequence")
        );
        let data = [];
        const querySnapshot = await getDocs(_query);
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id,
            });
        });
        return data;
    };

    return (
        <div className="h-100">
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color={"success"}>Loading...</Spinner>
                </div>
            ) : (
                <div className="h-100">
                    {/*<div className="text-center my-2">*/}
                    {/*    <span className="fs-10 muted-text">CREATE AND SHARE PLAYLISTS OF YOUR FAVORITE VIDEOS</span>*/}
                    {/*</div>*/}
                    {playlists.length > 0 ? (
                        playlists.map((_playlist, index) => (
                            <div
                                key={index}
                                className="d-flex justify-content-center"
                            >
                                <PlaylistCard
                                    playlist={_playlist}
                                    currentUser={currentUser}
                                    activeUser={activeUser}
                                    refreshUser={refreshUser}
                                    setRefreshUser={setRefreshUser}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="text-white h-100 d-flex justify-content-center align-items-center">
                            <h4 className="text-center">
                                No playlists added
                                <br /> Add one now
                            </h4>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MyPages;
