import React, {useEffect} from 'react';
import YouTubeThumbnail from "./YouTubeThumbnail";
import edit_icon from "../assets/images/viewTrakr/edit.svg";
import delete_icon from "../assets/images/viewTrakr/trash.svg";
import drag_icon from "../assets/images/viewTrakr/arrows.svg";
import {Link} from "react-router-dom";
import VimeoThumbnail from "./VimeoThumbnail";


const VideoItem = ({provided, item, deleteVideo}) => {

    return (
        <div
            className="video-item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
                ...provided.draggableProps.style,
            }}
        >
            <div>
                {item.video_link.includes('youtu') ?
                <YouTubeThumbnail url={item.video_link} sequence={item.sequence} edit={false}/>
                :
                <VimeoThumbnail    url={item.video_link} sequence={item.sequence} edit={false}/>
                }
            </div>
            <div className="video-item-details-container">
                <div className="video-item-description">
                    <span className="fs-8 fw-bold text-uppercase">Video {item.sequence}</span>
                    <div className="fs-12 fw-semibold">
                        {item.title}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end ms-2 pe-2">
                    <div>
                        <Link to={`/viewTrakr/editVideo/${item.id}`}>
                            <img src={edit_icon} alt="edit"/>
                        </Link>
                    </div>
                    <div className="mx-2">
                        <img src={delete_icon} alt="delete" onClick={()=> deleteVideo(item.id)}/>
                    </div>
                    <div>
                        <img src={drag_icon} alt="drag" {...provided.dragHandleProps}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoItem;
