import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../util/firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SECTORS } from "../../util/constants";
import SectorTab from "../../components/SectorTab";
import {
    fetchDocumentsForSectorMember,
    formatLargeNumber,
    waveMembersforSector,
} from "../../util/helper";
import leftIcon from "../../assets/images/viewTrakr/left.svg";
import rightIcon from "../../assets/images/viewTrakr/right.svg";
import waveScore from "../../assets/images/viewTrakr/ws_logo.svg";
const SectorView = ({ signedInUser, appMetaData, userPoints }) => {
    const { user_id } = useParams();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [allWaveMembers, setAllWaveMembers] = useState([]);
    const [sectors, setSectors] = useState(SECTORS);
    const [sectorView, setSectorView] = useState({
        name: "Sector 1",
        sector: 1,
        waveLevels: "1",
    });
    const [sectorMembers, setSectorMembers] = useState([]);
    const [loading, setLoading] = useState("initial");
    const [profileLoading, setProfileLoading] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef();

    // carousel settings defined
    const settings = {
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        centerMode: false, // Set to false
        variableWidth: false, // Set to false
        arrows: false,
        afterChange: (current) => setCurrentSlide(current),
    };

    useEffect(() => {
        if (user_id === signedInUser.uid) {
            setCurrentUser(signedInUser);
        } else {
            getUser()
                .then((user) => {
                    setCurrentUser(user);
                })
                .catch((err) => {
                    // if path id does'nt exist then navigate to layout page
                    navigate("/mywave");
                });
        }
    }, [user_id]);

    // get Current user from db
    const getUser = async () => {
        try {
            const docRef = doc(db, "users", user_id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return { ...docSnap.data(), uid: user_id };
            } else {
                throw new Error("User not found");
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error; // rethrow the error to be caught in the useEffect
        }
    };

    // get all view sector member
    const getWaveMembers = async (ids) => {
        try {
            const waveMembers = await fetchDocumentsForSectorMember(
                "users",
                currentUser.uid,
                sectorView.sector
            );
            return waveMembers;
        } catch (error) {
            console.error("Error fetching all Members:", error);
            throw error; // rethrow the error to be caught in the useEffect
        }
    };

    useEffect(() => {
        if (currentUser) {
            getWaveMembers()
                .then((users) => {
                    setAllWaveMembers(users);
                    setSectorMembers(
                        waveMembersforSector(sectorView, currentUser, users)
                    );
                    setLoading("ready");
                })
                .catch((err) => {
                    console.log("Error:-", err);
                    setLoading("ready");
                });
        }
    }, [currentUser]);

    const handlePrevClick = () => {
        if (currentSlide > 0) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNextClick = () => {
        if (currentSlide < sectorMembers.length - settings.slidesToShow) {
            sliderRef.current.slickNext();
        }
    };

    return loading === "initial" ? (
        <div className="text-center mt-5">
            <Spinner color={"success"}>Loading...</Spinner>
        </div>
    ) : (
        <div className="text-center text-secondary sector-main">
            <Container fluid className="sector-view-header ">
                <img src={waveScore} />
                <span className="score-points">{userPoints}</span>
            </Container>

            <div className="sectors-containers">
                <Container
                    fluid
                    className={`sector-view mt-2 ${
                        sectorMembers.length === 0 && "disable-sector-tab"
                    }`}
                >
                    <Row
                        className={
                            sectorMembers.length === 0
                                ? "disable-sector-tab-header"
                                : "sector-tab-header"
                        }
                        style={{ backgroundColor: "#00d0d0" }}
                    >
                        <Col xs={4} className="text-center p-0">
                            <span className="sector-heading">
                                {sectorView.name}
                            </span>
                        </Col>
                        <Col xs={5} className="py-3 px-0">
                            <div
                                className="Line5"
                                style={{
                                    width: "110%",
                                    height: 0,
                                    border:
                                        sectorMembers.length === 0
                                            ? "1px #626262 solid"
                                            : "1px white solid",
                                }}
                            ></div>
                        </Col>
                        <Col xs={3} className="text-center p-0">
                            <span className="sub-heading">Directs</span>
                        </Col>
                    </Row>

                    <Row className="sector-details  mt-2">
                        <Col
                            xs="8"
                            sm="8"
                            className="sector-details-label"
                            style={{
                                color:
                                    sectorMembers.length === 0
                                        ? "#626262"
                                        : "#E7E7E7",
                            }}
                        >
                            <span>Wave Members:</span>
                            <span>Total Views:</span>
                            <span>Points Generated:</span>
                        </Col>
                        <Col
                            xs="4"
                            sm="4"
                            className="sector-details-value"
                            style={{
                                color:
                                    sectorMembers.length === 0
                                        ? "#626262"
                                        : "#00d0d0",
                            }}
                        >
                            <span>
                                {" "}
                                {formatLargeNumber(sectorMembers.length)}
                            </span>
                            <span>
                                {formatLargeNumber(
                                    currentUser.sectorViews?.[
                                        sectorView.sector
                                    ] || 0
                                )}
                            </span>
                            <span>
                                {formatLargeNumber(
                                    currentUser.sectorPoints?.[
                                        sectorView.sector
                                    ] || 0
                                )}
                            </span>
                        </Col>
                    </Row>

                    {/* <Row className="sector-details mt-3">
                        <Col
                            xs="12"
                            sm="12"
                            className="d-flex justify-content-between"
                        >
                            <span className="fw-bold">Wave Members :</span>
                            <span>
                                {formatLargeNumber(sectorMembers.length)}
                            </span>
                        </Col>
                        <Col
                            xs="12"
                            sm="12"
                            className="d-flex justify-content-between"
                        >
                            <span className="fw-bold">Total Views :</span>
                            <span>
                                {formatLargeNumber(
                                    currentUser.sectorViews?.[
                                        sectorView.sector
                                    ] || 0
                                )}
                            </span>
                        </Col>

                        <Col
                            xs="12"
                            sm="12"
                            className="d-flex justify-content-between"
                        >
                            <span className="fw-bold">Points generated :</span>
                            <span>
                                {formatLargeNumber(
                                    currentUser.sectorPoints?.[
                                        sectorView.sector
                                    ] || 0
                                )}
                            </span>
                        </Col>
                        </Row>
                         */}
                    <div
                        className="Line5 mx-auto mt-3"
                        style={{
                            width: "90%",
                            height: 0,
                            border: ".5px #4C4C4C solid",
                        }}
                    ></div>
                    {sectorMembers && sectorMembers.length > 0 && (
                        <div className="carousel-container">
                            {sectorMembers.length > 5 && (
                                <>
                                    <div
                                        className={`carousel-arrows-left ${
                                            currentSlide === 0 && "hidden"
                                        }`}
                                    >
                                        <img
                                            src={leftIcon}
                                            alt="Left Arrow"
                                            onClick={handlePrevClick}
                                            className="arrow-button fade-in"
                                        />
                                    </div>
                                    <div
                                        className={`carousel-arrows-right ${
                                            currentSlide ===
                                                sectorMembers.length -
                                                    settings.slidesToShow &&
                                            "hidden"
                                        }`}
                                    >
                                        <img
                                            onClick={handleNextClick}
                                            className="arrow-button fade-in"
                                            src={rightIcon}
                                            alt="Right Arrow"
                                        />
                                    </div>
                                </>
                            )}

                            <Slider
                                className="carousel-slider"
                                ref={sliderRef}
                                {...settings}
                            >
                                {sectorMembers.map((user) => (
                                    <div
                                        key={user.id}
                                        className="carousel-profile"
                                        onClick={() =>
                                            navigate(
                                                `/${user.userNameInsensitive}`
                                            )
                                        }
                                    >
                                        <img
                                            src={
                                                user
                                                    ? user.profile_photo_url
                                                    : ""
                                            }
                                            style={{
                                                filter: profileLoading.includes(
                                                    user.id
                                                )
                                                    ? "none"
                                                    : "blur(5px)", // 5px is the blur amount, adjust as needed
                                                transition: "filter 0.3s", // Smooth transition when removing blur
                                            }}
                                            onLoad={() =>
                                                setProfileLoading((prev) => [
                                                    ...prev,
                                                    user.id,
                                                ])
                                            }
                                            alt=""
                                            className="profile-rounded"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}
                </Container>
                <Row className="mx-0 flec-column">
                    {sectors && sectors.length > 0 ? (
                        sectors.map((item) => (
                            <SectorTab
                                item={item}
                                currentUser={currentUser}
                                allWaveMembers={allWaveMembers}
                                key={item.name}
                            />
                        ))
                    ) : (
                        <Col>Not Found</Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default SectorView;
