import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import topIcon from "../../assets/images/viewTrakr/icon.png";
import editIcon from "../../assets/images/viewTrakr/edit.png";
import linkIcon from "../../assets/images/viewTrakr/link-input-icon.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    collection,
    getDocs,
    orderBy,
    query,
    where,
    updateDoc,
    doc,
    deleteDoc,
    getDoc,
    serverTimestamp,
    addDoc,
} from "firebase/firestore";
import { db } from "../../util/firebase";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import VideoItem from "../../components/VideoItem";
import {
    addUrls,
    deletePlaylist,
    getCroppedImg,
    getDefaultCrop,
    printLog,
} from "../../util/helper";
import Alert from "../../components/Alert";
import CropImage from "../../components/CropImage";

const EditPlaylist = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const playlistId = params.pid;
    const user = props?.signedInUser;

    const [containerHeight, setContainerHeight] = useState("auto");
    const [videos, setVideos] = useState([]);
    const [playlist, setPlaylist] = useState({});
    const [videosToDelete, setVideosToDelete] = useState([]);
    const [newVideoLink, setNewVideoLink] = useState("");
    const [loading, setLoading] = useState("initial");
    const [addToWall, setAddToWall] = useState(false);
    const [alreadyAdded, setAlreadyAdded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const [previews, setPreviews] = useState({
        cover: "",
    });

    useEffect(() => {
        getPlaylist().then((data) => {
            setPlaylist(data);
            setPreviews({ cover: data.cover_photo_url });
        });
        getPlaylistVideos().then((_videos) => {
            setVideos(_videos);
            setLoading("ready");
        });
        checkPlaylistInPosts().then((res) => {});
    }, []);

    console.log("Playlist", playlist);

    useEffect(() => {
        let h1 = props.mainContentHeight - props.hfHeight;
        printLog(h1, "height");
        setContainerHeight(`${h1}vh`);
    }, [props.mainContentHeight]);

    const checkPlaylistInPosts = async () => {
        const postsRef = collection(db, "posts");
        const q = query(
            postsRef,
            where("playlistId", "==", playlistId),
            where("user_id", "==", props.signedInUser.uid)
        );
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
                console.log("Document available");
                setAlreadyAdded(true);
                setAddToWall(true);
            } else {
                console.log("Document not available");
                setAlreadyAdded(false);
                setAddToWall(false);
            }
        } catch (error) {
            printLog(error, "Error in searching the playlist id");
        }
    };
    const getPlaylistVideos = async () => {
        const collectionRef = collection(db, "videos");
        const _query = query(
            collectionRef,
            where("playlist_id", "==", playlistId),
            orderBy("sequence")
        );
        let data = [];
        const querySnapshot = await getDocs(_query);
        querySnapshot.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        return data;
    };
    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.crossOrigin = "Anonymous"; // Set crossorigin attribute
            image.onload = () => resolve(image);
            image.onerror = reject;
            image.src = src;
        });
    };

    const getCroppedImageClone = async (playlistData, aspectRatio = 1) => {
        try {
            // Assuming playlistData.cover_photo_url is a Firebase Storage URL
            const imageUrl = `${playlistData.cover_photo_url}?alt=media`;

            let image = await loadImage(imageUrl);
            console.log("image", image.width);
            let crop = "";
            let postImage = "";

            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            let maxWidth = image.width > 300 ? image.width : 300;
            let maxHeight = image.height > 300 ? image.height : 300;
            let width = image.width;
            let height = image.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(image, 0, 0, width, height);

            crop = getDefaultCrop(canvas.width, canvas.height, aspectRatio);

            if (crop) {
                const croppedImage = await getCroppedImg(image, crop);
                postImage = await addUrls(
                    croppedImage,
                    "posts/",
                    `${user.uid}-${Date.now()}`
                );
            }

            console.log("postImage", postImage);
            return postImage;
        } catch (error) {
            console.error("Error loading image:", error);
        }
    };

    const getPlaylist = async () => {
        try {
            const docRef = doc(db, "playlists", playlistId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return {
                    ...docSnap.data(),
                    id: docSnap.id,
                };
            }
        } catch (error) {
            printLog(error, "Error in getting playlist");
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const itemsArr = Array.from(videos);
        const [reorderedItem] = itemsArr.splice(result.source.index, 1);
        itemsArr.splice(result.destination.index, 0, reorderedItem);
        // Update the sequence property for each video object based on its index
        itemsArr.forEach((item, index) => {
            item.sequence = index + 1;
        });
        setVideos(itemsArr);
    };
    const goBack = () => {
        navigate(-1);
    };
    const handleSubmit = async () => {
        setLoading("saving");

        if (playlist) {
            try {
                const playlistDocRef = doc(db, "playlists", playlistId);
                let coverUrl = playlist.cover_photo_url;
                if (playlist.coverPhoto) {
                    coverUrl = await addUrls(
                        playlist.coverPhoto,
                        "playlists/",
                        `${user.uid}-playlist-${playlist.title}-${playlist.sequence}-cover`
                    );
                }
                const updatePlayListData = {
                    cover_photo_url: coverUrl,
                    title: playlist.title,
                    pageUrl: playlist.pageUrl,
                    cover_redirect_link: playlist.cover_redirect_link.includes(
                        "https://"
                    )
                        ? playlist.cover_redirect_link
                        : `https://${playlist.cover_redirect_link}`,
                };

                await updateDoc(playlistDocRef, updatePlayListData);
            } catch (error) {
                printLog(error, "Error in updating document");
            }
        }

        if (!alreadyAdded && addToWall) {
            console.log("Now going to add playlist in wall");
            try {
                const playlistDocRef = doc(db, "playlists", playlistId);
                const playlistDocSnap = await getDoc(playlistDocRef);
                if (playlistDocSnap.exists()) {
                    const playlistData = playlistDocSnap.data();
                    const cropPostImage = await getCroppedImageClone(
                        playlistData,
                        1
                    );
                    const postData = {
                        user_id: user.uid,
                        user_name: user.userNameInsensitive,
                        user_profile_photo: user.profile_photo_url,
                        type: "playlist",
                        description: playlistData.title,
                        location: user.location,
                        createdAt: serverTimestamp(),
                        sourceUrl:
                            cropPostImage || playlistData.cover_photo_url,
                        playlistId: playlistId,
                    };
                    await addDoc(collection(db, "posts"), postData);
                }
            } catch (error) {
                printLog(error, "Error in posting playlist to wall");
            }
        }
        for (const video of videos) {
            try {
                const docRef = doc(db, "videos", video.id);
                await updateDoc(docRef, { sequence: video.sequence });
            } catch (error) {
                printLog(error, "Error in updating document");
            }
        }
        // Deleting videos marked for deletion
        for (const videoId of videosToDelete) {
            try {
                const docRef = doc(db, "videos", videoId);
                await deleteDoc(docRef);
            } catch (error) {
                printLog(error, "Error in deleting document");
            }
        }
        props.callAlert("success", "Playlist updated");
        setLoading("ready");
    };
    const deleteVideo = (videoId) => {
        // Remove the video from the local state and update sequences
        const updatedVideos = videos
            .filter((video) => video.id !== videoId)
            .map((video, index) => ({
                ...video,
                sequence: index + 1,
            }));
        setVideos(updatedVideos);
        // Add the videoId to the videosToDelete array
        setVideosToDelete((prev) => [...prev, videoId]);
    };
    const deleteMyPlaylist = async () => {
        setIsOpen(false);
        setLoading("deleting");
        deletePlaylist(
            playlistId,
            playlist.clone_playlist_id,
            props.signedInUser
        ).then((res) => {
            if (res) setLoading("ready");
            navigate(`/`);
        });
    };
    const toggle = () => setIsOpen(!isOpen);

    const handleChange = (event) => {
        const { name, type } = event.target;
        if (type === "file") {
            // Assuming only one file is selected
            const file = event.target.files[0];
            if (!file || !file.type.includes("image")) {
                props.callAlert("fail", "Please select image");
                return;
            }
            if (file && file.size > 15 * 1024 * 1024) {
                // File size is in bytes, so 10 * 1024 * 1024 is 10MB
                props.callAlert("fail", "Please select file less than 15mb");
                setLoading(false);
                event.target.value = ""; // Clear the input
                return;
            }
            if (file) {
                setCropImage({
                    img: URL.createObjectURL(file),
                    type: name,
                    aspectRatio: 1.75,
                });
                event.target.value = "";
            }
        } else {
            const { value } = event.target;
            setPlaylist((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleCallBackImage = (crpImg, type) => {
        console.log("crpImage", crpImg);
        switch (type) {
            case "coverPhoto":
                setPlaylist((prevState) => ({
                    ...prevState,
                    [type]: crpImg,
                }));
                setPreviews((prevState) => ({
                    ...prevState,
                    cover: URL.createObjectURL(crpImg),
                }));
                break;
        }
    };

    return (
        <div>
            <Container>
                <Card className="viewTrakr-card">
                    <CardBody style={{ height: containerHeight }}>
                        <div className="d-flex justify-content-center">
                            <div style={{ marginTop: "-45px" }}>
                                <img src={topIcon} alt="" width={70} />
                            </div>
                        </div>
                        <div className="fs-12 text-center muted-text-bold">
                            Edit Playlist
                        </div>
                        <div className="mt-4 full-height-container">
                            <FormGroup check className="mb-2">
                                <Input
                                    type="checkbox"
                                    id="add_to_wall"
                                    checked={addToWall}
                                    disabled={alreadyAdded}
                                    value={addToWall}
                                    onChange={(e) =>
                                        setAddToWall(e.target.checked)
                                    }
                                />
                                <Label
                                    for="add_to_wall"
                                    check
                                    className="fs-6 muted-text fw-semibold"
                                >
                                    Add playlist to your wall
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <h6 className="muted-text ">Playlist Title</h6>
                                <Input
                                    type="text"
                                    name="title"
                                    id="playlistTitle"
                                    value={playlist.title}
                                    onChange={handleChange}
                                    className="viewTrakr-input"
                                    required
                                />
                                <p className="muted-text fs-12">
                                    Playlist titles will display above video
                                    thumbnails
                                </p>
                            </FormGroup>
                            <FormGroup>
                                <h6 className="muted-text ">Page URL</h6>
                                <Input
                                    type="text"
                                    name="pageUrl"
                                    id="playlistTitle"
                                    value={playlist.pageUrl}
                                    onChange={handleChange}
                                    required
                                    className="viewTrakr-input"
                                />
                                <p className="muted-text fs-12">
                                    Web Address for your Video Page
                                </p>
                            </FormGroup>
                            <Row className="mt-5">
                                <Col sm={4} xs={4}>
                                    <label
                                        className="image-wrapper rounded-circle"
                                        style={{
                                            backgroundImage: `url('${previews.cover}')`,
                                            backgroundSize: "cover",
                                        }}
                                    >
                                        <img src={editIcon} width={30} alt="" />
                                        <input
                                            type="file"
                                            className="d-none"
                                            accept=".jpg,.jpeg,.png"
                                            name="coverPhoto"
                                            onChange={handleChange}
                                        />
                                    </label>
                                </Col>
                                <Col sm={8} xs={8}>
                                    <label className="muted-text fw-semibold">
                                        Cover Photo
                                    </label>
                                    <p className="muted-text fs-12">
                                        Upload a new photo or choose one from
                                        your Picture Library
                                    </p>
                                </Col>
                            </Row>
                            <FormGroup>
                                <h6 className="muted-text ">Cover Photo URL</h6>
                                <Input
                                    className="viewTrakr-input"
                                    type="text"
                                    name="cover_redirect_link"
                                    id="cover_redirect_link"
                                    onChange={handleChange}
                                    required
                                    value={playlist.cover_redirect_link}
                                />
                                <p className="muted-text fs-12">
                                    Redirect your viewers to any page on the web
                                </p>
                            </FormGroup>
                            <div>
                                <h6 className="muted-text ms-2">
                                    Add New Video
                                </h6>
                                <div className="d-flex position-relative">
                                    <InputGroup>
                                        <InputGroupText className="link-icon-input bg-white">
                                            <img
                                                src={linkIcon}
                                                alt=""
                                                width={20}
                                            />
                                        </InputGroupText>
                                        <Input
                                            type="text"
                                            className="link-input"
                                            onChange={(e) => {
                                                setNewVideoLink(e.target.value);
                                            }}
                                            placeholder="Add new video"
                                        />
                                    </InputGroup>
                                    <Link
                                        to={`/viewTrakr/editVideo/new?sequence=${
                                            videos.length + 1
                                        }&link=${newVideoLink}&playlistId=${playlistId}`}
                                    >
                                        <Button className="go-button">
                                            GO
                                        </Button>
                                    </Link>
                                </div>
                                <p className="muted-text fs-12 ms-2">
                                    Paste a video link to get started
                                </p>
                            </div>
                            {loading === "initial" ? (
                                <div className="text-center mt-5">
                                    <Spinner color={"success"}>
                                        Loading...
                                    </Spinner>
                                </div>
                            ) : videos.length > 0 ? (
                                <div>
                                    <div>
                                        <DragDropContext
                                            onDragEnd={handleOnDragEnd}
                                        >
                                            <Droppable droppableId="items">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{ padding: 0 }}
                                                    >
                                                        {videos.map(
                                                            (item, index) => (
                                                                <Draggable
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    draggableId={
                                                                        item.id
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided
                                                                    ) => (
                                                                        <VideoItem
                                                                            item={
                                                                                item
                                                                            }
                                                                            provided={
                                                                                provided
                                                                            }
                                                                            deleteVideo={
                                                                                deleteVideo
                                                                            }
                                                                        />
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center">No videos</div>
                            )}
                        </div>
                    </CardBody>
                    {/*{loading !== "initial" && videos.length > 0 &&*/}
                    <CardFooter className="viewTrakr-card-footer">
                        <Button
                            className="w-100 rounded-pill py-2"
                            color="danger"
                            disabled={loading !== "ready"}
                            onClick={toggle}
                        >
                            {loading === "deleting" ? (
                                <Spinner size="sm">Loading...</Spinner>
                            ) : (
                                "Delete Playlist"
                            )}
                        </Button>
                        <div className="d-flex justify-content-around my-2">
                            <Button
                                color=""
                                disabled={loading !== "ready"}
                                className="go-back-button"
                                onClick={goBack}
                            >
                                GO BACK
                            </Button>
                            <Button
                                color=""
                                disabled={loading !== "ready"}
                                className="save-button"
                                onClick={handleSubmit}
                            >
                                {loading === "saving" ? (
                                    <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                    "Save"
                                )}
                            </Button>
                        </div>
                    </CardFooter>
                    {/*}*/}
                </Card>
                {cropImage && (
                    <CropImage
                        cropImage={cropImage}
                        handleClose={() => setCropImage(null)}
                        aspectRatio={cropImage.aspectRatio}
                        handleCallBackImage={handleCallBackImage}
                    />
                )}
                <Modal isOpen={isOpen} toggle={toggle} className="w-100">
                    <ModalHeader className="justify-content-center">
                        Delete Playlist!
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <p>
                            Are you want to delete the playlist ? <br /> You
                            wont be able to revert this
                        </p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {/*<div className="d-flex justify-content-around">*/}
                        <Button
                            color="danger"
                            className="rounded-pill py-2 px-5"
                            onClick={deleteMyPlaylist}
                        >
                            Yes, Delete
                        </Button>
                        <Button
                            color=""
                            className="save-button"
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </Button>
                        {/*</div>*/}
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default EditPlaylist;
