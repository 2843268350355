import CreatePlaylist from "../views/viewTrakr/createPlaylist";
import EditPlaylist from "../views/viewTrakr/editPlaylist";
import EditVideo from "../views/viewTrakr/editVideo";

const routes = [
    {
        path: "/create/playlist",
        name: "Create Playlist",
        component: CreatePlaylist,
        layout: "/viewTrakr",
    },
    // {
    //     path: '/addVideo/:pid',
    //     name: 'Add Video',
    //     component: <AddVideo/>,
    //     layout: '/viewTrakr'
    // },
    {
        path: "/editPlaylist/:pid",
        name: "Edit Playlist",
        component: EditPlaylist,
        layout: "/viewTrakr",
    },
    {
        path: "/editVideo/:vid",
        name: "Edit Video",
        component: EditVideo,
        layout: "/viewTrakr",
    },
];

export default routes;
