import React from "react";
import routes from "../routes";
import {Navigate, Route, Routes} from "react-router-dom";
import "../assets/styles/leaderboard.css";
import UserFooter from "../components/UserFooter";
import {Container} from "reactstrap";
import Header from "../components/Header";

const Leaderboard = ({
  signedInUser,
  setRefreshUser,
  restrictUser,
  refreshUser,
  appMetaData,
  userPoints,
}) => {
  const getRoutes = (_routes) => {
    return _routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={
          <route.component
            setRefreshUser={setRefreshUser}
            refreshUser={refreshUser}
            signedInUser={signedInUser}
            appMetaData={appMetaData}
            restrictUser={restrictUser}
          />
        }
        exact
      />
    ));
  };
  return (
    <div className="bg-black ">
      {/* full-screen-height */}
      <Container>
        <Header
          signedInUser={signedInUser}
          userPoints={userPoints}
          appMetaData={appMetaData}
          headerLogo=""
        />
      </Container>
      <Routes>
        {getRoutes(routes.leaderboard)}
        <Route path="*" element={<Navigate to="/leaderboard/home" />} />
      </Routes>
      <UserFooter refreshUser={refreshUser} setRefreshUser={setRefreshUser} />
    </div>
  );
};

export default Leaderboard;
