import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmationPopup = ({
    handleClose,
    title,
    description,
    handleConfirm,
    isOpenModal,
}) => {
    return (
        <Modal isOpen={isOpenModal} className="w-100">
            <ModalHeader className="justify-content-center">
                {title}
            </ModalHeader>
            <ModalBody className="text-center">
                <p>{description}</p>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-start w-100">
                <Button
                    color="danger"
                    className="rounded-pill mx-3 w-50"
                    onClick={() => handleConfirm()}
                >
                    Yes
                </Button>
                <Button
                    color=""
                    className="save-button w-50"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationPopup;
