import React, { useEffect, useRef, useState } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Header from "../components/Header";
import routes from "../routes";
import "../assets/styles/ViewTrakr.css";
import { Container, Spinner } from "reactstrap";
import UserFooter from "../components/UserFooter";
import { db } from "../util/firebase";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { printLog } from "../util/helper";
import Listing from "../views/viewTrakr/listing";
const UserProfile = ({
    signedInUser,
    callAlert,
    setPostFile,
    refreshUser,
    setRefreshUser,
    userPoints,
    appMetaData,
}) => {
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const mainContentRef = useRef(null);
    const location = useLocation();
    const userName = location.pathname.split("/")[1];
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState("");
    const [loading, setLoading] = useState("initial");
    const [mainContentHeight, setMainContentHeight] = useState("auto");
    const [mainContentValue, setMainContentValue] = useState(0);
    const [hfHeight, setHfHeight] = useState(0);
    const [zendujaHome, setZendujaHome] = useState(false);

    console.log("location", location);
    useEffect(() => {
        updateHeight(); // Call this initially when the component mounts

        window.addEventListener("resize", updateHeight); // Add an event listener

        return () => {
            // Cleanup: remove the event listener when the component unmounts
            window.removeEventListener("resize", updateHeight);
        };
    }, []); // Only run once after component is mounted

    useEffect(() => {
        if (location) {
            getUser(userName)
                .then((_user) => {
                    if (_user) {
                        console.log("_user", _user);
                        setCurrentUser(_user);
                        setLoading("ready");
                    } else {
                        navigate(`/${signedInUser.userNameInsensitive}`);
                    }
                })
                .catch((err) => {
                    printLog(err);
                    navigate(`/${signedInUser.userNameInsensitive}`);
                });
        }
    }, [location]);

    const getUser = async (userName) => {
        try {
            const collectionRef = collection(db, "users");
            const querySnapshot = await getDocs(
                query(
                    collectionRef,
                    where("userNameInsensitive", "==", userName.toLowerCase()),
                    limit(1)
                )
            );

            if (querySnapshot.empty) {
                // No user found with the specified username
                return null;
            }

            // Extract user data from the document
            const userData = {
                ...querySnapshot.docs[0].data(),
                uid: querySnapshot.docs[0].id,
            };
            return userData;
        } catch (error) {
            console.error("Error retrieving user:", error);
            throw error;
        }
    };

    const updateHeight = () => {
        if (headerRef.current && footerRef.current) {
            const headerHeightVH =
                (headerRef.current.offsetHeight / window.innerHeight) * 100;
            const footerHeightVH =
                (footerRef.current.offsetHeight / window.innerHeight) * 100;
            const totalVH = headerHeightVH + footerHeightVH;
            setHfHeight(totalVH);
            setMainContentValue(100 - totalVH);
            setMainContentHeight(`calc(100vh - ${totalVH}vh)`);
        }
    };

    const getRoutes = (_routes) => {
        return _routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                element={
                    <route.component
                        mainContentHeight={mainContentValue}
                        setPostFile={setPostFile}
                        hfHeight={hfHeight}
                        signedInUser={signedInUser}
                        callAlert={callAlert}
                        setZendujaHome={setZendujaHome}
                        refreshUser={refreshUser}
                        setRefreshUser={setRefreshUser}
                        pid={currentUser?.uid || signedInUser.uid}
                    />
                }
                exact
            />
        ));
    };
    console.log("routes", routes);
    return (
        <>
            <div className="viewTrakr-bg">
                {/*{showAlert && <Alert settings={alertSettings}/>}*/}
                <Container fluid>
                    <Header
                        userPoints={userPoints}
                        reference={headerRef}
                        appMetaData={appMetaData}
                        signedInUser={signedInUser}
                        headerLogo={zendujaHome ? "" : "viewtrakr"}
                    />
                </Container>
                {loading === "initial" ? (
                    <div className="text-center mt-5">
                        <Spinner color={"success"}>Loading...</Spinner>
                    </div>
                ) : (
                    <div
                        ref={mainContentRef}
                        style={{ height: mainContentHeight }}
                        id="view-trakr-layout"
                    >
                        <Routes>
                            {getRoutes(routes.userProfile)}{" "}
                            <Route
                                path="*"
                                element={
                                    <Navigate to={`/${userName}/viewTrakr`} />
                                }
                            />
                        </Routes>
                    </div>
                )}
                <UserFooter
                    reference={footerRef}
                    refreshUser={refreshUser}
                    setRefreshUser={setRefreshUser}
                />
            </div>
        </>
    );
};

export default UserProfile;
