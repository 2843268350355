import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter} from "react-router-dom";
import "../src/assets/styles/styles.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
// const loadScript = (src) => {
//     return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = src;
//         script.onload = () => {
//             resolve(true);
//         };
//         script.onerror = () => {
//             reject(new Error(`Script load error for ${src}`));
//         };
//         document.body.appendChild(script);
//     });
// }
// loadScript('https://www.youtube.com/iframe_api')
//     .then(() => {
//         console.log('YouTube Iframe API loaded successfully');
//     })
//     .catch(error => {
//         console.error('There was an issue loading the YouTube Iframe API:', error);
//     });
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
