import React, { useRef } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import routes from "../routes";
import "../assets/styles/mywave.css";
import "../assets/styles/styles.css";
import { Container } from "reactstrap";
import UserFooter from "../components/UserFooter";

const MyWave = ({
    signedInUser,
    callAlert,
    refreshUser,
    setRefreshUser,
    userPoints,
    appMetaData,
}) => {
    const getRoutes = (_routes) => {
        return _routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                element={
                    <route.component
                        signedInUser={signedInUser}
                        callAlert={callAlert}
                        refreshUser={refreshUser}
                        setRefreshUser={setRefreshUser}
                        userPoints={userPoints}
                    />
                }
                exact
            />
        ));
    };

    return (
        <div className="mywave-bg">
            {/*{showAlert && <Alert settings={alertSettings}/>}*/}
            <Container fluid>
                <Header
                    userPoints={userPoints}
                    appMetaData={appMetaData}
                    signedInUser={signedInUser}
                    headerLogo="zendujalogotwo"
                />
            </Container>

            <Routes>
                {getRoutes(routes.myWave)}
                <Route
                    path="*"
                    element={<Navigate to={`/mywave/${signedInUser.uid}`} />}
                />
            </Routes>

            <UserFooter
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
            />
        </div>
    );
};

export default MyWave;
