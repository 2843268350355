import Profile from "../views/user/profile";
import NewsFeed from "../views/user/NewsFeed"
import SelectPost from "../views/user/selectPost";
import AddNewPost from "../layouts/addNewPost";

const routes = [
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        layout: '/user'
    },
    {
        path: '/news-feed',
        name: 'NewsFeed',
        component: NewsFeed,
        layout: '/user'
        
    },
    // {
    //     path: '/select-post',
    //     name: 'Select Post',
    //     component: SelectPost,
    //     layout: '/user'
    //
    // },
    // {
    //     path: '/add-new-post',
    //     name: 'Add New Post',
    //     component: AddNewPost,
    //     layout: '/user'
    //
    // }
];

export default routes;