import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader, Button, Spinner } from "reactstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg, getDefaultCrop, printLog } from "../util/helper";

const CropImage = ({
    aspectRatio,
    handleCallBackImage,
    handleClose,
    cropImage,
}) => {
    const [crop, setCrop] = useState();
    const [modalImage, setModalImage] = useState(cropImage?.img);
    const [btnLoader, setBtnLoader] = useState(false);
    const imageRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            let image = imageRef.current;
            let maxWidth = image.width;
            let maxHeight = image.height;
            let width = image.width;
            let height = image.height;
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            const defaultCrop = getDefaultCrop(
                width,
                height,
                aspectRatio,
                "positionCenter"
            );

            setCrop(defaultCrop);
        }, 300);
    }, []);

    const cropRightImage = async () => {
        try {
            setBtnLoader(true);
            const croppedImage = await getCroppedImg(imageRef.current, crop);

            handleCallBackImage(croppedImage, cropImage.type);
            setBtnLoader(false);
            handleClose();
        } catch (err) {
            printLog("cropRightImage Error:-", err.message);
            setBtnLoader(false);
        }
    };

    return (
        <Modal
            isOpen={true}
            toggle={handleClose}
            backdrop={false}
            centered={true}
        >
            <ModalHeader>Crop Image</ModalHeader>
            <ModalBody className="text-center modalBg">
                <p className="text-start ">
                    Please select image area you want to show in Logo
                </p>
                <ReactCrop
                    crop={crop}
                    aspect={aspectRatio}
                    onChange={(c) => setCrop(c)}
                    className="bg-dark"
                    keepSelection={true}
                    onComplete={(c) => {
                        setCrop(c);
                    }}
                    style={{
                        maxHeight: "80%",
                        maxWidth: "80%",
                        margin: "auto",
                        touchAction: "none",
                    }}
                >
                    <img ref={imageRef} src={modalImage} alt="" />
                </ReactCrop>
                <div className="d-flex justify-content-start mt-3">
                    <Button
                        color=""
                        className="save-button w-50"
                        disabled={btnLoader}
                        onClick={cropRightImage}
                    >
                        {btnLoader ? (
                            <Spinner size="sm">Loading...</Spinner>
                        ) : (
                            "Save"
                        )}
                    </Button>
                    <Button
                        color="danger"
                        className="rounded-pill mx-3 w-50"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default CropImage;
