import Home from "../views/leaderboard/home";

const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        layout: '/leaderboard'
    }
]

export default routes;