import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Dev Database configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyCyYDFcJ2bm4OmPi1AsjnobKqjqTRpxBQY",
//     authDomain: "zenduja-social.firebaseapp.com",
//     projectId: "zenduja-social",
//     storageBucket: "zenduja-social.appspot.com",
//     messagingSenderId: "498941433807",
//     appId: "1:498941433807:web:b6fa6deed99e8631afe4ba",
// };

// Staging Database configuration
const firebaseConfig = {
    apiKey: "AIzaSyCR4O4W1-OqsEvXiRkks7tGhijFSefOcZA",
    authDomain: "zenduja-staging.firebaseapp.com",
    projectId: "zenduja-staging",
    storageBucket: "zenduja-staging.appspot.com",
    messagingSenderId: "341030686986",
    appId: "1:341030686986:web:8d6fae9266103e56726af3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };
