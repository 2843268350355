import React, {useEffect, useState} from "react";
import logo from "../assets/images/Header/viewtrakr_logo.svg";
import waveLogo from "../assets/images/Header/WaveScore.svg";
import zendujaLogoTwo from "../assets/images/Header/zenduja_logo_2.svg";
import zendujaLogo from "../assets/images/user/logo.png";
import {Col, Row} from "reactstrap";
import Mark from "../assets/images/user/W mark.svg";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import "../assets/styles/header.css";

const Header = ({
    reference,
    signedInUser,
    headerLogo,
    userPoints,
    appMetaData,
}) => {
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [timeLeft, setTimeLeft] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        let resetTime = appMetaData?.leaderboard_reset?.nextReset;
        const timer = setInterval(() => {
            // Convert Firebase timestamp to JavaScript Date
            const targetDate = new Date(
                resetTime.seconds * 1000 + resetTime.nanoseconds / 1000000
            );

            // Calculate time difference using moment
            const now = moment();
            const targetMoment = moment(targetDate);
            const duration = moment.duration(targetMoment.diff(now));

            // Check if countdown is over
            if (duration.asSeconds() <= 0) {
                clearInterval(timer);
                setTimeLeft("Time's up!");
                return;
            }

            // Extract days, hours, minutes, and seconds
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Update state
            setTimeLeft(
                `${days}:${hours < 10 ? "0" : ""}${hours}:${
                    minutes < 10 ? "0" : ""
                }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
            );
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(timer);
    }, []);

    const showHeaderLogo = (type) => {
        switch (type) {
            case "viewtrakr":
                return logo;
            case "mywave":
                return waveLogo;
            case "zendujalogotwo":
                return zendujaLogoTwo;
            default:
                return zendujaLogo;
        }
    };

    return (
        <div ref={reference} style={{ height: "12vh" }}>
            <Row className="justify-content-between align-items-center p-3 px-0 text-white">
                <Col xs="3">
                    <Link to="/">
                        <div className="header-dp">
                            <img
                                // src={signedInUser ? signedInUser.profile_photo_url : ''}
                                src={
                                    signedInUser
                                        ? signedInUser.profile_photo_url
                                        : ""
                                }
                                onLoad={() => setProfileLoaded(true)}
                                style={{
                                    filter: profileLoaded
                                        ? "none"
                                        : "blur(5px)", // 5px is the blur amount, adjust as needed
                                    transition: "filter 0.3s", // Smooth transition when removing blur
                                }}
                                alt=""
                                className="rounded"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </Link>
                </Col>
                <Col xs="6" className="text-center">
                    <img src={showHeaderLogo(headerLogo)} alt="logo" />
                </Col>
                <Col xs="3" className="p-0">
                    <div className="d-flex flex-column align-items-end justify-content-around top-info px-2 py-2">
                        <div
                            role="button"
                            className="d-flex justify-content-between align-items-center w-100"
                            onClick={() =>
                                navigate(`/mywave/${signedInUser.uid}`)
                            }
                        >
                            <img
                                className="mx-1 w-mark"
                                alt="W mark"
                                src={Mark}
                                height={15}
                            />
                            <span className="mb-0 align-middle top-points">
                                {userPoints}
                            </span>
                        </div>
                        <p className="mb-0">
                            <span className="text-wrapper-10 top-timer">
                                {timeLeft || "00:00:00:00 "}
                            </span>
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Header;
