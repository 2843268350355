import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import half_circle1 from "../assets/images/viewTrakr/stats-circle2.svg";
import full_circle from "../assets/images/viewTrakr/stats-circle1.svg";
import half_circle2 from "../assets/images/viewTrakr/stats-circle3.svg";

const SubHeader = ({ user }) => {
    const [followersCount, setFollowersCount] = useState(0);
    const [followingsCount, setFollowingsCount] = useState(0);
    const [connections, setConnections] = useState(0);

    useEffect(() => {
        setFollowersCount(user?.followers?.length || 0);
        setFollowingsCount(user?.followings?.length || 0);
        const commonUsers = user?.followers?.filter((follower) =>
            user?.followings?.includes(follower)
        );
        setConnections(commonUsers?.length || 0);
    }, [user]);

    return (
        <Row className="py-2 ">
            <Col className="d-flex justify-content-center fs-12 align-items-center text-white">
                <img src={half_circle1} alt="" width={20} className="me-1" />
                <span>{followingsCount}</span>
            </Col>
            <Col className="d-flex justify-content-center fs-12 align-items-center text-white seperator">
                <img src={full_circle} alt="" width={20} className="me-1" />
                <span>{connections}</span>
            </Col>
            <Col className="d-flex justify-content-center fs-12 align-items-center text-white seperator">
                <img src={half_circle2} alt="" width={20} className="me-1" />
                <span>{followersCount}</span>
            </Col>
        </Row>
    );
};

export default SubHeader;
